import Swal from 'sweetalert2';
import { Config } from '../../../../config';
import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { CustomMediaTextureComponent } from '../NodeComponents/mediaTexture';
import { CustomText2DComponent } from '../NodeComponents/text2dcomponent';
import { CustomVolumeTriggerComponent } from '../NodeComponents/volumetriggercomponent';
import { VERTEX_NODE_NO_PARENT_GUID } from '../../../utilities/constants';
import { Utils, IDetachableRemotionLogicComponent, instanceOfIDetachableRemotionLogicComponent } from '../../../utilities/utils';
import { SpaceCloneUtils } from '../../../utilities/space-clone-utilities';
import { ResourceUtils } from '../../../utilities/resource-utilities';

export class SceneHierarchyPanelComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }
}

export class SceneHierarchyPanelComponentComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    panelBody: HTMLDivElement;
    panel: HTMLDivElement;

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let runtime = Vertex.Globals.runtime;
        let space = runtime.space;

        this.panel = document.querySelector(".container-overlay-left");
        this.panel.classList.remove("hidden");

        
        let tree = document.createElement("ul") as HTMLUListElement;
        tree.id="myUL";

        // Create Hierarchy Panel

        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id="scenes-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text","max");

        let headerIcon = document.createElement("img");
        headerIcon.src = "/img/scene-icon-active.svg";
        headerIcon.classList.add("card-header-icon");
        
        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Space Hierarchy";

        let body = document.createElement("div");
        body.classList.add("card-body", "p-0");

        let panel = document.createElement("div");
        panel.classList.add("scene-hierarchy-panel", "h-100");

        // Grid Area

        let leftSidebar = document.querySelector(".left-sidebar-grid");

        // Append

        leftSidebar.appendChild(card);
        card.appendChild(header);
        header.appendChild(headerIcon);
        header.appendChild(headerText);
        card.appendChild(body);
        body.appendChild(panel);
        panel.appendChild(tree);

        // Sidebar

        Utils.setupSidebarButton("SceneHierarchyPanel", "scenes-panel");
        
        Vertex.Globals.event.on("hierarchy:nodeAdded", (node: Vertex.NodeComponentModel.VertexNode) => {
            this.listNode(node, tree, true);
        });
        
        Vertex.Globals.event.on("space:nodeDestroyed", (node: Vertex.NodeComponentModel.VertexNode) => {
            console.log("deleted node: ", node);
            let nodeListItem = document.querySelector(`[data-node-id="${node.id}"]`) as HTMLLIElement;
            
            if(nodeListItem){
                nodeListItem.remove();
            }
            else{
                console.log("couldn't find: ", node);
            }

        });

        Vertex.Globals.event.on("editor:selectNode", (node: Vertex.NodeComponentModel.VertexNode) => {
            // va aggiunto controllo gtlf IS READY

            for (const item of tree.querySelectorAll(".selected-list-item")) {
                item.classList.remove("selected-list-item");
            }

            for (const item of tree.querySelectorAll(".list-item")) {

                if (item.getAttribute("data-node-id") === node.id){// .textContent.includes(name)) {
                    item.classList.add("selected-list-item");
                }
            }

        });

        Vertex.Globals.event.on("editor:clearSelection", (node: Vertex.NodeComponentModel.VertexNode) => {
            for (const item of tree.querySelectorAll(".selected-list-item")) {
                item.classList.remove("selected-list-item");
            }

        });

        Vertex.Globals.event.on("editor:space-loaded", () => {
            //for each node in the scene
            for(let kvp of space.nodes.entries()){

                let guid = kvp[0];
                let node = kvp[1];

                this.listNode(node, tree);
            }
        });
    }

    private updateNodeName(node: Vertex.NodeComponentModel.VertexNode, nodeNameInput: HTMLInputElement){
        nodeNameInput.value = Utils.sanitizeString(nodeNameInput.value.trim());

        if(!nodeNameInput.value){
            nodeNameInput.value = node.name;
            return;
        }

        if(nodeNameInput.value !== node.name){
            nodeNameInput.value = nodeNameInput.value;
            node.name = nodeNameInput.value;

            Vertex.Globals.event.fire("editor:node-name-changed", node);
        }
    }

    private listNode(node: Vertex.NodeComponentModel.VertexNode, tree: HTMLUListElement, isNew: boolean = false) {
        const self = this;

        const existingNode = tree.querySelector(`[data-node-id="${node.id}"]`) as HTMLLIElement;

        if(existingNode){
            return;
        }
        
        let gltf = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        let mediaTexture = node.getComponent("MediaTexture") as CustomMediaTextureComponent;
        let text2D = node.getComponent("Text2D") as CustomText2DComponent;
        let co2Visualizer = node.getComponent("SavedCO2Visualizer") as AugmentedStoreAssembly.SavedCO2VisualizerComponent;
        let isSpawnPoint = node.components.includes("SpawnPoint");
        let volumeTrigger = node.getComponent("VolumeTrigger") as CustomVolumeTriggerComponent;

        if (isSpawnPoint) {
            //create list item
            let nodeItem = document.createElement("li") as HTMLLIElement;
            let nodeText = document.createElement("div");
            //let nodeIcon = document.createElement("img");

            nodeItem.setAttribute("data-node-id", node.id);
            nodeText.innerHTML = node.name;
            nodeText.style.padding = "0 0.5rem";

            //make selectable
            nodeItem.addEventListener("click", function (event) {
                var selectedRow = event.target as HTMLDivElement;
                var selectedId = selectedRow.getAttribute("data-node-id");
                for(let kvp of Vertex.Globals.runtime.space.nodes.entries()){
                    
                    let guid = kvp[0];
                    let node = kvp[1];

                    if(guid == selectedId){
                        Vertex.Globals.event.fire("editor:selectNode", node);
                    }
                }
            });

            nodeItem.classList.add("scene-list", "list-item");
            nodeText.classList.add("scene-list-text");

            nodeItem.appendChild(nodeText);
            tree.appendChild(nodeItem);
        }
        else if (gltf || mediaTexture || text2D || co2Visualizer || volumeTrigger) {
            //create list item
            let nodeItem = document.createElement("li") as HTMLLIElement;
            nodeItem.classList.add("scene-list", "list-item");

            let nodeNameInput = document.createElement("input");
            nodeNameInput.type = "text";
            nodeNameInput.maxLength = 30;
            nodeNameInput.classList.add("scene-list-text");
            nodeNameInput.classList.add("node-name-input");
            nodeNameInput.disabled = true;

            nodeNameInput.addEventListener("focusout", function(event){
                editButton.click();
            });

            nodeNameInput.addEventListener("keydown", function(event){
                if(event.key === "Enter"){
                    self.updateNodeName(node, nodeNameInput);
                    editButton.click();
                }
            });

            nodeNameInput.addEventListener("change", function(event){
                self.updateNodeName(node, nodeNameInput);
            });

            let lockButton = document.createElement("button");
            lockButton.classList.add("hierarchy-item-button", "lock-icon-button");

            let lockIcon = document.createElement("img");
            lockIcon.src ="/img/lock-icon.svg";

            lockButton.appendChild(lockIcon);

            Utils.injectSvg(lockIcon);

            let nodeLockableComp = node.getComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent;

            const editButton = document.createElement("button");
            editButton.classList.add("hierarchy-item-button", "list-item-edit-button");
            const editIcon = document.createElement("img");
            editIcon.src = "/img/edit-thick-icon.svg";
            editButton.appendChild(editIcon);

            Utils.injectSvg(editIcon, { afterLoad: (svg, svgString) => {
                    svg.querySelectorAll('path').forEach(path => path.style.stroke = '#8c8c8c');
                } 
            });

            editButton.addEventListener("click", function(event){
                nodeItem.classList.toggle("block-pointer-events");

                if(nodeNameInput.disabled){
                    nodeNameInput.disabled = false;
                    nodeNameInput.focus();

                    const editIcon = document.createElement("img");
                    editIcon.src = "/img/check-icon.svg";

                    editButton.innerHTML = "";
                    editButton.appendChild(editIcon);
        
                    Utils.injectSvg(editIcon, { afterLoad: (svg, svgString) => {
                            svg.querySelectorAll('path').forEach(path => path.style.stroke = '#0e73e6');
                        } 
                    });
                }
                else{
                    nodeNameInput.disabled = true;

                    const checkIcon = document.createElement("img");
                    checkIcon.src = "/img/edit-thick-icon.svg";

                    editButton.innerHTML = "";
                    editButton.appendChild(checkIcon);
        
                    Utils.injectSvg(checkIcon, { afterLoad: (svg, svgString) => {
                            svg.querySelectorAll('path').forEach(path => path.style.stroke = '#8c8c8c');
                        } 
                    });
                }
            });


            const cloneButton = document.createElement("button");
            cloneButton.classList.add("hierarchy-item-button", "list-item-clone-button");
            const cloneIcon = document.createElement("img");
            cloneIcon.src = "/img/copy-thick-icon.svg";
            cloneButton.appendChild(cloneIcon);

            Utils.injectSvg(cloneIcon, { afterLoad: (svg, svgString) => {
                    svg.querySelectorAll('path').forEach(path => path.style.stroke = '#8c8c8c');
                } 
            });

            cloneButton.addEventListener("click", async function(event){
                Swal.fire({
                    icon: 'info',
                    title: `Cloning<br>${node.name}`,
                    showCancelButton: false,
                    showCloseButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    width: "auto",
                });

                Swal.showLoading();

                const clonedNode = await SpaceCloneUtils.cloneSpaceNode(node);

                if(!clonedNode){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'An error occurred while cloning the node',
                        showCloseButton: true,
                        showConfirmButton: true,
                        showCancelButton: false,
                        allowOutsideClick: true,
                        heightAuto: false
                    });

                    return;
                }

                // translate the cloned node 1 meter on the x axis to make it visible
                const transformComp = clonedNode.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
                transformComp.position = [transformComp.position[0] + 1, transformComp.position[1], transformComp.position[2]];
                transformComp.triggerOnChanged();

                Swal.close();
            });

            let deleteButton = document.createElement("button");
            deleteButton.classList.add("hierarchy-item-button", "list-item-bin-delete-button");

            let deleteIcon = document.createElement("img");
            deleteIcon.src = "/img/trash.svg";
            deleteButton.appendChild(deleteIcon);

            Utils.injectSvg(deleteIcon);
            
            deleteButton.addEventListener("click", function(event){
                
                Swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to delete this node?',
                    text: 'Deleting a node is permanent and cannot be undone',
                    showCancelButton: true,
                    showConfirmButton: true,
                    heightAuto: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (!nodeLockableComp.isLocked){
                            const space = Vertex.Globals.runtime.space as Vertex.Space;
                            const childNodes = Array.from(space.nodes.values()).filter(n => n.parent === node.id);
        
                            if(childNodes?.length > 0){
                                childNodes.forEach(childNode => {
                                    self.destroyNode(childNode);
                                });
                            }
        
                            self.destroyNode(node);
                        }
                    }
                });

            });

            if (nodeLockableComp !== null ) {
                if(!nodeLockableComp.isLocked){
                    lockButton.innerHTML = "";

                    const lockIcon = document.createElement("img");
                    lockIcon.src ="/img/unlock-icon.svg";

                    lockButton.appendChild(lockIcon);

                    Utils.injectSvg(lockIcon);
                    deleteButton.removeAttribute("disabled");
                }
                else{
                    deleteButton.setAttribute("disabled", "disabled");
                }

                nodeLockableComp.onChanged.on((comp: AugmentedStoreAssembly.NodeLockableComponent)=>{
                    if(!comp.isLocked){
                        lockButton.innerHTML = "";

                        const lockIcon = document.createElement("img");
                        lockIcon.src ="/img/unlock-icon.svg";

                        lockButton.appendChild(lockIcon);

                        Utils.injectSvg(lockIcon);
                        
                        deleteButton.removeAttribute("disabled");
                    }
                    else{    
                        lockButton.innerHTML = "";

                        const lockIcon = document.createElement("img");
                        lockIcon.src ="/img/lock-icon.svg";

                        lockButton.appendChild(lockIcon);

                        Utils.injectSvg(lockIcon, { afterLoad: (svg, svgString) => {
                                svg.querySelectorAll('path').forEach(path => path.style.stroke = '#8c8c8c');
                            } 
                        });
                        
                        deleteButton.setAttribute("disabled", "disabled");
                    }
                })

                lockButton.addEventListener("click", function(event){
                    Vertex.Globals.event.fire("hevolus:forceClearSelection"); //still needed because inspector ui is not dynamic so the transform would not be blocked still
                    nodeLockableComp.isLocked = !nodeLockableComp.isLocked;
                    nodeLockableComp.triggerOnChanged();
                });
            }

            nodeItem.setAttribute("data-node-id", node.id);

            nodeNameInput.value = node.name;

            if(isNew && (gltf || mediaTexture)){
                //set name (brutto ma ok)
                const id = gltf ? gltf.id : mediaTexture.id;
                
                ResourceUtils.getResourceData(id).then((resource) => {
                    if(nodeNameInput.value.endsWith("(Clone)")){
                        // check how many times (Clone) is present in the name and keep it once
                        nodeNameInput.value = nodeNameInput.value.replace(/\(Clone\)/g, "");
                        nodeNameInput.value = nodeNameInput.value.trim();
                        nodeNameInput.value = nodeNameInput.value + " (Clone)";
                    }
                    else{
                        nodeNameInput.value = resource.name;
                    }

                    node.name = nodeNameInput.value;
                    Vertex.Globals.event.fire("editor:node-name-changed", node);                    
                });            
            }

            //make selectable
            nodeItem.addEventListener("click", function (event) {
                var selectedRow = event.target as HTMLDivElement;
                var selectedId = selectedRow.getAttribute("data-node-id");
                for(let kvp of Vertex.Globals.runtime.space.nodes.entries()){
                    
                    let guid = kvp[0];
                    let node = kvp[1];

                    if(guid == selectedId){
                        Vertex.Globals.event.fire("editor:selectNode", node);
                    }
                }
            });

            if(gltf)
            {
                let gltfIsReady = gltf.IsReady;
                
                if(gltf.id === Config.DEFAULT_NAVMESH_ID || !gltfIsReady){
                    lockButton.hidden = true;
                    cloneButton.hidden = true;
                    deleteButton.hidden = true;
                }     
                
                gltf.Ready.on(() => {
                    if(gltf.id !== Config.DEFAULT_NAVMESH_ID){
                        lockButton.hidden = false;
                        deleteButton.hidden = false;
                        cloneButton.hidden = false;
                    }
                });

                gltf.onChanged.on(() => {
                    if (!gltf.isReady) {
                        lockButton.hidden = true;
                        deleteButton.hidden = true;
                        cloneButton.hidden = true;
                    }
                });
            }
            
            nodeItem.appendChild(lockButton);
            nodeItem.appendChild(nodeNameInput);

            if(gltf?.id !== Config.DEFAULT_NAVMESH_ID){ //avoid edit name of the default navmesh
                nodeItem.appendChild(editButton);
            }

            nodeItem.appendChild(cloneButton);
            nodeItem.appendChild(deleteButton);

            if(node.parent == VERTEX_NODE_NO_PARENT_GUID || !node.parent){
                tree.appendChild(nodeItem);
            }
            else{
                //we need to nest this item to the item related to its parent
                let parentItem = tree.querySelector(`[data-node-id="${node.parent}"]`) as HTMLLIElement;

                if(parentItem){
                    nodeItem.classList.add("nested-item");
                    parentItem.insertAdjacentElement("afterend", nodeItem);
                }
                else{
                    console.log("parent not found for node: ", node);
                }
            }
        }
    }

    destroyNode(node: Vertex.NodeComponentModel.VertexNode) {
        Vertex.Globals.event.fire("space:nodeDestroyed", (node));
        
        let removableComponents : IDetachableRemotionLogicComponent [] = [];

        node.components.forEach(componentName => {
            let component = node.getComponent(componentName);
            if(component && instanceOfIDetachableRemotionLogicComponent(component)){
                removableComponents.push(component);
                console.log(`The onRemoved lambda of ${componentName} componente has been canceled`);
            }
        });

        removableComponents.forEach(component => {
            component.detachRemotionLogic();
        });

        Vertex.Globals.runtime.space.destroyNode(node);
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }

    minimizePanel(target: HTMLElement) {

        let parent = target.closest(".card");
        let body = parent.querySelector(".card-body");
        let icon1 = target.querySelector(".mini-1");
        let icon3 = target.querySelector(".mini-3");



            if (target.classList.contains("max")) {
                target.classList.remove("max");
                target.classList.add("min");
                body.classList.remove("d-block");
                body.classList.add("d-none");
                icon1.classList.remove("squish-mini");
                parent.classList.remove("h-30vh");

                console.log(target.classList);
                console.log(body.classList);
            }

            else {
                target.classList.remove("min");
                target.classList.add("max");
                body.classList.remove("d-none");
                body.classList.add("d-block");
                icon1.classList.add("squish-mini");
                parent.classList.add("h-30vh");


                console.log(target.classList);
                console.log(body.classList);
            }
    }


}


export class SceneHierarchyPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new SceneHierarchyPanelComponent();
    }
    constructor() {
        super("SceneHierarchyPanel", new SceneHierarchyPanelComponentComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}


