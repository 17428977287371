import { H_SHARE_DOMAIN } from "../../utilities/constants";
import { ResourceUtils } from "../../utilities/resource-utilities";
import { UserProfileUtils } from "../../utilities/user-profile-utilities";
import { HIPE_XR_API_DOMAIN, HIPE_XR_API_KEY, HIPE_XR_API_VERSION, HIPE_XR_API_VERSION_PARAM } from "./common";

//export const QR_API_ENDPOINT = "api/qr";

const HVLS_API_ENDPOINT = "hevls";
const QR_API_ENDPOINT = "qr";

export interface Link {
    linkId: number;
    vanityName: string;
    targetUrl: string;
    description: string;
    created: string;
    createdBy: string;
    lastModified?: string;
    lastModifiedBy?: string;
    validFrom?: string;
    validTo?: string;
    isActive: boolean;
    shortUrl: string;
    users?: User[];
}

export interface User{
    id: string;
    displayName: string;
    mail: string;
    darkTheme: boolean;
}

interface ShortUrlRequest {
    TargetUrl: string;
    Description: string;
    ResourceName: string;
}

export class QrUrlShortenerApi {
    /**
     * Create short QR url for a resource
     * @param resourceId resource id
     * @param resourceName resource name
     * @param token user token
     * @returns short url or undefined
     */
    static async createShortUrl(resourceId?: string, resourceName?: string, token?: string): Promise<string>{
        resourceId = resourceId ?? Vertex.Globals.spaceId;
        token = token ?? Vertex.Globals.bearerToken;

        if(!token || !resourceId){
            return;
        }

        resourceName = resourceName ?? (await ResourceUtils.getResourceData(resourceId))?.name;
        const companyId = await UserProfileUtils.getTenantId(token);

        if(!companyId || !resourceName){
            return;
        }

        let uri = `${HIPE_XR_API_DOMAIN}/${HVLS_API_ENDPOINT}/${QR_API_ENDPOINT}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        const body: ShortUrlRequest = {
            ResourceName: resourceName,
            TargetUrl: `${H_SHARE_DOMAIN}${resourceId}`,
            Description: `${companyId}`
        };

        try{
            const response = await fetch(uri, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                },
                body: JSON.stringify(body)
            });

            if(response.ok){
                const link = await response.json() as Link;

                return link.shortUrl;
            }
            else{
                console.log(`Failed to create short url for resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to create short url for resource ${resourceId}`, e);
        }
    }

    /**
     * Get Link for a resource
     * @param resourceId The resource id
     * @param token The user token
     * @returns Link object or undefined
     */
    static async getLink(resourceId?: string, token?: string){
        resourceId = resourceId ?? Vertex.Globals.spaceId;
        token = token ?? Vertex.Globals.bearerToken;

        if(!token || !resourceId){
            return;
        }

        const companyId = await UserProfileUtils.getTenantId(token);

        if(!companyId){
            return;
        }

        let uri = `${HIPE_XR_API_DOMAIN}/${HVLS_API_ENDPOINT}/${QR_API_ENDPOINT}/${companyId}/${resourceId}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try{
            const response = await fetch(uri, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                }
            });

            if(response.ok){
                return await response.json() as Link;
            }
            else{
                console.log(`Failed to get short url for resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to get short url for resource ${resourceId}`, e);
        }
    }
}