import { CustomAnimationInspectorRenderer } from "../../CustomInspectorRenderers/customanimationrenderer";
import { CustomBlankInspectorRenderer } from "../../CustomInspectorRenderers/customblankrenderer";
import { CustomGltfPublishRenderer } from "../../CustomInspectorRenderers/customgltfpublishrenderer";
import { CustomLightInspector } from "../../CustomInspectorRenderers/customlightrenderer";
import { CustomNumberfieldInspector } from "../../CustomInspectorRenderers/customnumberfieldrenderer";
import { CustomRotationRenderer } from "../../CustomInspectorRenderers/customrotationrenderer";
import { CustomXYZRenderer } from "../../CustomInspectorRenderers/customxyzrenderer";
import { CustomModelAlternativeRenderer } from "../../CustomInspectorRenderers/custommodelalternativerenderer";
import { CustomItemPropertiesRenderer } from "../../CustomInspectorRenderers/customitempropertiesrenderer";
import { CustomBooleanInspector } from "../../CustomInspectorRenderers/custombooleanrenderer";
import { CustomMediaTextureRenderer } from "../../CustomInspectorRenderers/custommediatexturerenderer";
import { CustomMediaTextureComponent } from "../NodeComponents/mediaTexture";
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { CustomVideoTextureRenderer } from "../../CustomInspectorRenderers/customvideotexturerenderer";
import { CustomLightMapsInspectorRenderer } from "../../CustomInspectorRenderers/customLightMapsInspectorRenderer";
import { CustomChangeMaterialComponent } from "../NodeComponents/changematerial";
import { CustomChangeMaterialInspectorRenderer } from "../../CustomInspectorRenderers/customChangeMaterialInspectorRenderer";

import { IDetachableRemotionLogicComponent, Utils, instanceOfIDetachableRemotionLogicComponent } from "../../../utilities/utils";
import { CustomWarpRenderer } from "../../CustomInspectorRenderers/customwarprenderer";
import { CustomSpawnPointRenderer } from "../../CustomInspectorRenderers/customspawnpointrenderer";
import { CustomText2DRenderer } from "../../CustomInspectorRenderers/customtext2drenderer";
import { CustomSavedCO2Renderer } from "../../CustomInspectorRenderers/customsavedco2renderer";
import { RESOURCE_API_URI, VERTEX_NODE_NO_PARENT_GUID } from "../../../utilities/constants";
import { CustomCallToActionInspector } from "../../CustomInspectorRenderers/customcalltoactionrenderer";
import { Config } from "../../../../config";
import { CustomNodeTogglerRenderer } from "../../CustomInspectorRenderers/customnodetogglerrenderer";
import { CustomVolumeTriggerRenderer } from "../../CustomInspectorRenderers/customvolumetriggerrenderer";
import { CustomTransformRenderer } from "../../CustomInspectorRenderers/customtransformrenderer";
import { ResourceUtils } from "../../../utilities/resource-utilities";
import { Quaternion, Vector3 } from "babylonjs";
import { findGltfMeshIdsForBabylonMesh } from "../../../utilities/gltf-utilities";

export class NodeEditorPanelComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }

    selectedNode: Vertex.NodeComponentModel.VertexNode = null;
    gltfModelComp: Vertex.NodeComponentModel.GltfModelComponent = null;

    onGltfStateChanged: () => void;

    setSelectedNode(newNode: Vertex.NodeComponentModel.VertexNode) {

        this.selectedNode = newNode;

        if (this.gltfModelComp && this.onGltfStateChanged != null) {
            this.gltfModelComp.onChanged.off(this.onGltfStateChanged)
            this.gltfModelComp.Ready.off(this.onGltfStateChanged);
            this.onGltfStateChanged = null;
        }

        if (newNode) {
            this.gltfModelComp = this.selectedNode.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;

            if (this.gltfModelComp) {
                this.onGltfStateChanged = () => {
                    this.triggerOnChanged();
                }

                this.gltfModelComp.onChanged.on(this.onGltfStateChanged);
                this.gltfModelComp.Ready.on(this.onGltfStateChanged);
            }
        }

        this.triggerOnChanged();
    }
}

interface IComponentRendererBuilder {
    (componentName: string, node: Vertex.NodeComponentModel.VertexNode): Map<string, Vertex.UI.ICustomInspectorRenderer>
}

export class NodeEditorPanelComponentComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    excludedProperties: Array<string>;
    customComponentRenderers: Map<string, IComponentRendererBuilder> = new Map<string, IComponentRendererBuilder>();

    container: HTMLDivElement;
    card: HTMLDivElement;
    cardHeader: HTMLDivElement;
    cardBody: HTMLDivElement;
    tempCardBody: HTMLElement;
    cardFooter: HTMLDivElement;
    componentButtons: HTMLElement;
    comp: NodeEditorPanelComponent;

    currentRenderedNode: Vertex.NodeComponentModel.VertexNode;

    undeletableComponents = ["Transform", "GltfModel", "MediaTexture", "ItemProperties", "ChangeMaterial", "SpawnPoint", "VolumeTrigger"];
    
    allowedComponentsDefaultNavMesh = [];

    allowedComponentsModel = ["ModelAlternative", "RotationComponent", "VideoTexture", "NavMesh", "LightMaps", "Warp", "CallToAction", "NodeToggler", "VolumeTrigger"];
    allowedChildComponentsModel = ["VolumeTrigger", "Text2D"];

    allowedComponentsMedia = ["RotationComponent", "CallToAction", "NodeToggler", "VolumeTrigger"];
    allowedChildComponentsMedia = ["VolumeTrigger", "Text2D"];

    emptyInspectorComponents = ["NavMesh"];

    addedVolumeTrigger: boolean;

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.comp = component as NodeEditorPanelComponent;
        let self = this;

        let mainContainer = document.querySelector(".container-overlay-right");
        mainContainer.classList.remove("hidden");

        // Get html hooks
        this.container = document.querySelector(".scene-editor-grid");
        this.container.classList.remove("hidden");

        // Create HTML Elements
        this.card = document.createElement("div") as HTMLDivElement;
        this.card.classList.add("card");
        this.card.classList.add("pointer-enable");
        this.card.classList.add("node-editor");
        this.card.classList.add("z-position-closer");
        this.card.hidden = true;

        this.container.querySelector("#right-sidebar-parent").insertBefore(this.card, this.container.querySelector("#right-sidebar-parent").firstChild);

        this.cardHeader = document.createElement("div") as HTMLDivElement;
        this.cardHeader.classList.add("card-header", "node-component-header");

        this.card.appendChild(this.cardHeader);

        // Header Back Button
        let backButton = document.createElement("button");
        backButton.classList.add("back-button", "d-none");
        backButton.innerText = "❮";

        this.cardHeader.appendChild(backButton);

        // Header Text
        let cardHeaderText = document.createElement("div");
        cardHeaderText.classList.add("nav-link", "scene-header", "dark-text", "nav-link-with-dropdown");
        cardHeaderText.style.textOverflow = "ellipsis";
        cardHeaderText.style.whiteSpace = "nowrap";
        cardHeaderText.style.overflow = "hidden";

        this.cardHeader.appendChild(cardHeaderText);

        cardHeaderText.innerText = "Loading";

        //Body
        this.tempCardBody = document.createElement("div") as HTMLDivElement;
        this.tempCardBody.classList.add("card-body", "card-margin", "temp-card-body");
        this.tempCardBody.hidden = true;
        this.card.appendChild(this.tempCardBody);
        this.cardBody = document.createElement("div") as HTMLDivElement;
        this.cardBody.classList.add("card-body", "card-margin")
        this.card.appendChild(this.cardBody);
        

        let selectedComponent = "";
        let componentDropdownToggle = document.createElement("button");
        componentDropdownToggle.classList.add("btn", "btn-info", "btn-sm");
        componentDropdownToggle.setAttribute("data-toggle", "dropdown");
        componentDropdownToggle.setAttribute("type", "button");
        componentDropdownToggle.style.display = "flex";
        componentDropdownToggle.style.height = "1.5rem";
        componentDropdownToggle.style.padding = "0 0.3rem";
        componentDropdownToggle.style.alignItems = "center";
        componentDropdownToggle.style.alignSelf = "center";
        componentDropdownToggle.style.marginRight = "0.5rem";
        componentDropdownToggle.style.overflow = "hidden";

        let componentDropdownToggleIcon = document.createElement("img");
        componentDropdownToggleIcon.classList.add("button-icon", "action-list-add" , "mr-2");
        componentDropdownToggleIcon.src = "/img/add-icon.svg";
        componentDropdownToggle.appendChild(componentDropdownToggleIcon);

        let componentDropdownToggleText = document.createElement("span");
        componentDropdownToggleText.innerText = "Add Component";
        componentDropdownToggleText.dataset.toggle = "tooltip";
        componentDropdownToggleText.dataset.placement = "bottom";
        componentDropdownToggleText.dataset.title = "Add Component";
        componentDropdownToggleText.style.alignSelf = "baseline";
        componentDropdownToggleText.style.whiteSpace = "nowrap";
        componentDropdownToggleText.style.overflow = "hidden";
        componentDropdownToggleText.style.textOverflow = "ellipsis";
        componentDropdownToggle.appendChild(componentDropdownToggleText);

        let componentDropdownMenu = document.createElement("div");
        componentDropdownMenu.classList.add("dropdown-menu");

        let componentsMap = Vertex.Globals.runtime.componentSystem.systems as Map<string, Vertex.NodeComponentModel.ComponentSystem>;
        //The logic for pressing the add component button
        /* componentAddButton.addEventListener("click", ()=>{
            //Check the thing is a valid component
            if(componentsMap.get(selectedComponentType)){
                let selectedNode = comp.selectedNode;
                if(selectedNode.getComponent(selectedComponentType) === null){
                    selectedNode.addComponent(selectedComponentType);
                    comp.triggerOnChanged();
                }
            }
        }) */

        this.cardHeader.appendChild(componentDropdownToggle);
        this.cardHeader.appendChild(componentDropdownMenu);

        //componentDropdownGroup.appendChild(componentAddButton);

        //DEBUG FOR DROPDOWN
        // let forbiddenComponents = [
        //     "Transform",
        //     "MousePicker",
        //     "Gizmos",
        //     "NodeEditorPanel",
        //     "SceneHierarchyPanel",
        //     "ResourceExplorerPanel",
        //     "Sidebar",
        //     "MaterialsTab",
        //     "LightHierarchyPanel",
        //     "MaterialEditorTab",
        //     "GltfHierarchyPanel",
        //     "SceneSettingsPanel",
        //     "GltfHierarchyPanel",
        //     "SceneSettingsPanel",
        //     "Persistance",
        //     "GlobalSceneSettingsPanel",
        //     "SpotLight",
        //     "DirectionalLight",
        //     "PointLight",
        //     "GltfModel",
        //     "NodeLockable",
        //     "ItemProperties",
        //     "Animation",
        //     "MediaTexture",
        //     "Unselectable",
        //     "Skybox",
        //     "SkyboxSettingsPanel",
        //     "VideoTexture",
        //     "NavMesh",
        //     "CameraPropertiesPanel",
        //     "CameraProperties",
        //     "PostProcessProperties",
        //     "PostProcessPanel",
        // ];


        //let componentsMap = Vertex.Globals.runtime.componentSystem.systems as Map<string, Vertex.NodeComponentModel.ComponentSystem>;
        // componentsMap.forEach((value, key, map) => {
        //     if (forbiddenComponents.find(element => element === key) === undefined) {
        //         dropdownItems.push(key);
        //     }
        // });


        //Footer
        //this.cardFooter = document.createElement("div") as HTMLDivElement;
        //this.cardFooter.classList.add("card-footer")
        //this.card.appendChild(this.cardFooter);

        //this.componentButtons = document.createElement("div");
        //this.componentButtons.classList.add("btn-group");
        //this.componentButtons.classList.add("d-flex");
        //this.cardBody.appendChild(this.componentButtons);

        //Set Component Renderer Properties
        this.excludedProperties = new Array<string>();
        this.excludedProperties.push("doNotSerialize");
        this.excludedProperties.push("events");
        this.excludedProperties.push("isUpdatingState");
        this.excludedProperties.push("onChanged");
        this.excludedProperties.push("dirty");
        this.excludedProperties.push("name");
        this.excludedProperties.push("entity");
        this.excludedProperties.push("loadedId");

        this.customComponentRenderers.set("Transform", this.transformRendererFactory);
        this.customComponentRenderers.set("VolumeTrigger", this.volumeTriggerInspectorRendererFactory);
        this.customComponentRenderers.set("NodeToggler", this.nodeTogglerInspectorRendererFactory);
        this.customComponentRenderers.set("GltfModel", this.gltfModelInspectorRendererFactory);
        this.customComponentRenderers.set("Animation", this.animationInspectorRendererFactory);
        this.customComponentRenderers.set("ModelAlternative", this.altModelInspectorRendererFactory);
        this.customComponentRenderers.set("SpotLight", this.lightInspectorRendererFactory);
        this.customComponentRenderers.set("PointLight", this.lightInspectorRendererFactory);
        this.customComponentRenderers.set("DirectionalLight", this.lightInspectorRendererFactory);
        this.customComponentRenderers.set("ItemProperties", this.itemPropertiesRendererFactory);
        this.customComponentRenderers.set("SpawnPoint", this.spawnpointRendererFactory);
        this.customComponentRenderers.set("RotationComponent", this.rotationComponentRendererFactory);
        this.customComponentRenderers.set("NavMesh", this.navMeshComponentRendererFactory);
        this.customComponentRenderers.set("MediaTexture", this.mediaTextureComponentRendererFactory);
        this.customComponentRenderers.set("VideoTexture", this.videoTextureComponentRendererFactory);
        this.customComponentRenderers.set("LightMaps", this.lightMapsComponentRendererFactory);
        this.customComponentRenderers.set("ChangeMaterial", this.changeMaterialComponentRendererFactory);
        this.customComponentRenderers.set("Warp", this.warpComponentRendererFactory);
        this.customComponentRenderers.set("SavedCO2Visualizer", this.savedCo2RendererFactory);
        this.customComponentRenderers.set("Text2D", this.text2DComponentRendererFactory);
        this.customComponentRenderers.set("CallToAction", this.callToActionComponentRendererFactory);
        //let gltfCompPanel = node.addComponent("GltfHierarchyPanel") as GltfHierarchyPanelComponent;
       
        function getEventListeners(elem: HTMLElement): { [key: string]: Function[] } {
            const events: { [key: string]: Function[] } = {};
            for (const key in elem) {
                if (key.startsWith('on') && typeof (elem as any)[key] === 'function') {
                    events[key.slice(2)] = [(elem as any)[key]];
                }
            }
            return events;
        }
        
        function clearIds(elem: HTMLElement): void {
            const children = elem.getElementsByTagName('*');
            if(elem.children.length > 0) {
                for (let i = 0; i < elem.children.length; i++) {
                    const child = elem.children[i] as HTMLElement;
                    clearIds(child);
                }
            }
            elem.id = "";
        }
        //on change
        component.onChanged.on((_) => {
            this.card.hidden = this.comp.selectedNode == null;

            if(!this.comp.selectedNode){
                return;	
            }
            
            const isGltf = this.comp.selectedNode?.components.includes("GltfModel");
            const isMedia = this.comp.selectedNode?.components.includes("MediaTexture");
            const isVolumeTrigger = this.comp.selectedNode?.components.includes("VolumeTrigger");
            const isText2D = this.comp.selectedNode?.components.includes("Text2D");
            const isSavedCO2 = this.comp.selectedNode?.components.includes("SavedCO2Visualizer");
            const isSpawnPoint = this.comp.selectedNode?.components.includes("SpawnPoint");
            const isLight = this.comp.selectedNode?.components.find(component => component.includes("Light"));
            let isDefaultNavmesh = false;//= comp.selectedNode?.components.includes("NavMesh") && (comp.selectedNode?.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent)?.Id == Config.DEFAULT_NAVMESH_ID;

            const space = Vertex.Globals.runtime.space as Vertex.Space;
            const childNodes = Array.from(space.nodes.values()).filter(n => n.parent === this.comp.selectedNode.id);

            let isSelectedNodeChanged = this.currentRenderedNode !== this.comp.selectedNode;
            this.currentRenderedNode = this.comp.selectedNode;

            if(this.currentRenderedNode == null) {
                return;
            }

            let allowedComponents: string[] = [];
            let allowedChildComponents: string[] = [];

            if (isGltf) {
                let gltf = this.comp.selectedNode.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;

                if (gltf.Id == Config.DEFAULT_NAVMESH_ID) {
                    isDefaultNavmesh = true;
                    allowedComponents = this.allowedComponentsDefaultNavMesh;
                }
                else {
                    allowedComponents = this.allowedComponentsModel;
                    allowedChildComponents = this.allowedChildComponentsModel;
                }
            }
            else if (isMedia) {
                allowedComponents = this.allowedComponentsMedia;
                allowedChildComponents = this.allowedChildComponentsMedia;
            }
            // else if (comp.selectedNode.components.includes("Light")){
            //     allowedComponents = this.allowedComponentsLight;
            // }

            let dropdownItems = allowedComponents.filter(comp => componentsMap.has(comp));
            let dropdownChildItems = allowedChildComponents.filter(comp => componentsMap.has(comp));

            componentDropdownMenu.innerHTML = "";
            componentDropdownToggle.classList.remove("hidden");

            if (dropdownItems.length === 0) {
                componentDropdownToggle.classList.add("hidden");
            }
            else if (this.comp.gltfModelComp != null && !this.comp.gltfModelComp.isReady) {
                componentDropdownToggle.disabled = true;
                componentDropdownToggle.classList.add("disabled");
            } else {
                componentDropdownToggle.disabled = false;
                componentDropdownToggle.classList.remove("disabled");
                
                for (let index = 0; index < dropdownItems.length; index++) {
                    const element = dropdownItems[index];

                    let componentDropdownItem = this.createDropdownItem(element);

                    componentDropdownItem.addEventListener("click", () => {
                        if (this.comp.selectedNode !== null) {
                            let selectedNode = this.comp.selectedNode;
                            selectedComponent = element;

                            /**
                             * if the Volume Trigger component is selected, we need to create a new node and add the component to it
                             * then we need to child the new node to the selected node
                             */
                            if(selectedComponent === "VolumeTrigger"){
                                this.addedVolumeTrigger = true;
                                
                                if(isGltf || isMedia){
                                    const space = Vertex.Globals.runtime.space as Vertex.Space;
                                    const count = Utils.getComponentUICount(space.nodes, selectedComponent);
                                    const node = space.createNode(`${Utils.getComponentUIName(selectedComponent)} ${count}`);  

                                    node.parent = selectedNode.id;

                                    let parentRootMesh;
                                    let meshes = [];

                                    if(isGltf){
                                        parentRootMesh = this.comp.selectedNode.viewNode as BABYLON.AbstractMesh;
                                        meshes = parentRootMesh.getChildMeshes(false, (mesh: BABYLON.AbstractMesh) => { const idxs = findGltfMeshIdsForBabylonMesh(mesh); return idxs[1] != null && idxs[1] != -1; } );
                                    }
                                    else if(isMedia){
                                        parentRootMesh = (this.comp.selectedNode.getComponent("MediaTexture") as CustomMediaTextureComponent).plane as BABYLON.AbstractMesh;
                                        meshes.push(parentRootMesh);
                                    }

                                    if(meshes?.length){
                                        let min = meshes[0].getBoundingInfo().boundingBox.minimumWorld;
                                        let max = meshes[0].getBoundingInfo().boundingBox.maximumWorld;

                                        for (let i = 1; i < meshes.length; i++) {
                                            let meshMin = meshes[i].getBoundingInfo().boundingBox.minimumWorld;
                                            let meshMax = meshes[i].getBoundingInfo().boundingBox.maximumWorld;

                                            min = BABYLON.Vector3.Minimize(min, meshMin);
                                            max = BABYLON.Vector3.Maximize(max, meshMax);
                                        }

                                        const extend = new BABYLON.BoundingInfo(min, max);

                                        var distx = BABYLON.Vector3.Distance(extend.boundingBox.vectorsWorld[0], extend.boundingBox.vectorsWorld[2]);
                                        var distz = BABYLON.Vector3.Distance(extend.boundingBox.vectorsWorld[1], extend.boundingBox.vectorsWorld[5]);
                                        
                                        let roationEuler = BABYLON.Vector3.Zero();
                                        var normalfacebounding = BABYLON.Vector3.Cross(extend.boundingBox.vectorsWorld[2].subtract(extend.boundingBox.vectorsWorld[1]), extend.boundingBox.vectorsWorld[5].subtract(extend.boundingBox.vectorsWorld[1])).normalize();

                                        if(normalfacebounding.z < 0){
                                            roationEuler.y = BABYLON.Vector3.GetAngleBetweenVectors(normalfacebounding, new BABYLON.Vector3(1,0,0), BABYLON.Vector3.Cross(normalfacebounding, new BABYLON.Vector3(1,0,0)).normalize());
                                        }
                                        else{
                                            roationEuler.y = -BABYLON.Vector3.GetAngleBetweenVectors(normalfacebounding, new BABYLON.Vector3(1,0,0), BABYLON.Vector3.Cross(normalfacebounding, new BABYLON.Vector3(1,0,0)).normalize());
                                        }
                                        
                                        const rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(roationEuler.x, roationEuler.y, roationEuler.z);

                                        let transformComp = node.addComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;

                                        const pos = extend.boundingBox.center.subtract(parentRootMesh.position);

                                        if(isGltf){
                                            transformComp.position = [pos.x, pos.y, pos.z];
                                            transformComp.rotation = [rotationQuaternion.x, rotationQuaternion.y, rotationQuaternion.z, rotationQuaternion.w];
                                            transformComp.scale = [distx, extend.boundingBox.extendSizeWorld.y * 2, distz];
                                        }
                                        else if(isMedia){
                                            transformComp.position = [0, 0, 0];
                                            transformComp.rotation = [0, 0, 0, 1];
                                            transformComp.scale = [distx, extend.boundingBox.extendSizeWorld.y * 2, 0.3];
                                        }

                                        transformComp.triggerOnChanged();
                                    }
                                    else{
                                        let transformComp = node.addComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;

                                        transformComp.position = [0, 0.5, 0];
                                        transformComp.rotation = [0, 0, 0, 1];
                                        transformComp.scale = [1, 1, 1];

                                        transformComp.triggerOnChanged();
                                    }                                    

                                    node.addComponent("NodeLockable");
                                    node.addComponent("ItemProperties");

                                    const addedComp = node.addComponent(selectedComponent);

                                    addedComp.enabled = true;
            
                                    space.addNode(node);
                                    Vertex.Globals.event.fire("hierarchy:nodeAdded", node);

                                    // if(node){
                                    //     Vertex.Globals.event.fire("editor:changeGizmoTarget", node);
                                    // }
                                }
                            }
                            else{
                                //Update the bit at the top that says the selected component
                                //componentDropdownToggle.innerText = selectedComponentType;
                                const addedComp = selectedNode.addComponent(selectedComponent);

                                //select the node so the UI is updated and the transform comp UI is locked
                                if(selectedComponent == "RotationComponent")
                                {
                                    Vertex.Globals.event.fire("editor:selectNode", selectedNode);
                                }

                                if(selectedComponent == "NodeToggler"){
                                    addedComp.enabled = true;
                                }
                            }

                            this.comp.triggerOnChanged();

                        }
                    });

                    componentDropdownMenu.appendChild(componentDropdownItem);
                }

                //add separator and external label
                // let separator = document.createElement("div");
                // separator.classList.add("dropdown-divider");
                // componentDropdownMenu.appendChild(separator);

                // let externalLabel = document.createElement("div");
                // externalLabel.classList.add("dropdown-item");
                // externalLabel.innerText = "External Components";
                // externalLabel.style.fontWeight = "bold";
                // externalLabel.style.paddingLeft = "0.5rem";
                // externalLabel.style.paddingRight = "0.5rem";
                // componentDropdownMenu.appendChild(externalLabel);
                
                // //cycle over external comps and add them to the dropdown
                // for (let index = 0; index < dropdownExternalItems.length; index++) {
                //     const element = dropdownExternalItems[index];

                //     let componentDropdownItem = this.createDropdownItem(element);

                //     componentDropdownItem.addEventListener("click", () => {
                //         if (this.comp.selectedNode?.viewNode?.position) {
                //             const additionalCompView = Vertex.Globals.runtime.componentSystem.systems?.get("AdditionalComponentsPanel")?.view as AdditionalComponentsPanelComponentView;

                //             if(additionalCompView){
                //                 const addedNode = additionalCompView.createAddtionalComponentNode(element, [this.comp.selectedNode.viewNode.position.x, this.comp.selectedNode.viewNode.position.y, this.comp.selectedNode.viewNode.position.z]);
                //                 addedNode.parent = this.comp.selectedNode.id;
                //                 let transformComp = addedNode.addComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
                //                 transformComp.position = [0, 0, 0];
                //                 transformComp.triggerOnChanged();

                //                 if(addedNode){
                //                     Vertex.Globals.event.fire("editor:selectNode", addedNode);
                //                 }
                //             }
                //         }
                //     });

                //     componentDropdownMenu.appendChild(componentDropdownItem);
                // }

                ($(componentDropdownToggle) as any).dropdown();
            }
            this.tempCardBody.innerHTML = "";
            while (this.cardBody.firstChild) {
                this.tempCardBody.appendChild(this.cardBody.firstChild);
            }
            if(this.tempCardBody.innerHTML != "") {
            clearIds(this.tempCardBody);
            }
            
            //create buttons for each component on node
            this.comp.selectedNode.components.forEach((compName) => {
                if (compName == "NodeLockable") {
                    return;
                };

                if (isSpawnPoint || isGltf || isSavedCO2 || isText2D || isMedia || isVolumeTrigger) {
                    if (compName === "Transform") {
                        return;
                    }
                }

                if (isSavedCO2) {
                    if (compName === "Text2D") {
                        return;
                    }
                }

                if(isMedia){
                    if (compName === "MediaTexture") {
                        return;
                    }
                }

                if (isGltf) {
                    if (compName === "GltfModel") {
                        return;
                    }

                    if (compName === "ChangeMaterial") {
                        let changeMaterialComponent = this.comp.selectedNode.getComponent("ChangeMaterial") as CustomChangeMaterialComponent;
                        let presets = changeMaterialComponent?.skinJson?.presets;

                        if (presets == null || presets.length == 0) {
                            let activeComp = document.getElementById("ChangeMaterial");
                            
                            if(activeComp) {
                                activeComp.remove();
                            }

                            return;
                        }
                    }

                    if (compName == "GltfLoadingHandler") {
                        return;
                    }
                }

                //Check to see if the component is already in the UI
                var checkExists = this.cardBody.querySelector(`#${compName}-button`);

                //if (checkExists == null) {
                    let nodeComponent = this.comp.selectedNode.getComponent(compName);

                    let compCard = document.createElement("div");
                    let compSectionStatus = document.createElement("p");
                    let compButton = document.createElement("div");
                    let compIcon = document.createElement("img");
                    let compText = document.createElement("div");
                    let deleteButton = document.createElement("button");

                    compCard.classList.add("component-section");

                    compSectionStatus.classList.add("component-section-status");
                    compSectionStatus.innerText = "❯";
                    compSectionStatus.style.visibility = this.emptyInspectorComponents.includes(compName) ? "hidden" : "visible";

                    compButton.classList.add("btn", "btn-secondary", "component-button");
                    compButton.id = compName + "-button";
                    compIcon.classList.add("component-icon");
                    compIcon.src = Utils.getComponentUIIcon(compName);

                    compText.classList.add("component-text");
                    compText.innerText = Utils.getComponentUIName(compName);

                    this.cardBody.appendChild(compCard);
                    compCard.appendChild(compButton);
                    compButton.appendChild(compSectionStatus);
                    compButton.appendChild(compIcon);
                    compButton.appendChild(compText);

                    Utils.injectSvg(compIcon);

                    //Delete button
                    if (!this.undeletableComponents.includes(compName)) {
                        deleteButton.classList.add("btn", "cross-delete-inverted", "node-button");

                        let deleteIcon = document.createElement("img");
                        deleteIcon.src = "/img/cross.svg";
                        deleteIcon.classList.add("node-icon");
                        deleteButton.appendChild(deleteIcon);
                        
                        Utils.injectSvg(deleteIcon);

                        deleteButton.addEventListener("click", (event) => {
                            this.comp.selectedNode.removeComponent(compName);

                            compButton.remove();

                            event.preventDefault();
                            event.stopPropagation();

                            const componentPanelForDeletion = document.getElementById(compName);

                            if (componentPanelForDeletion !== null) {
                                componentPanelForDeletion.remove();
                            }

                            if(compName == "RotationComponent")
                            {
                                Vertex.Globals.event.fire("editor:selectNode", this.comp.selectedNode);
                            }
                        });

                        if (isMedia || (this.comp.gltfModelComp != null && this.comp.gltfModelComp.id !== Config.DEFAULT_NAVMESH_ID)) {
                            compButton.appendChild(deleteButton);
                        }

                        if (this.comp.gltfModelComp != null && !this.comp.gltfModelComp.isReady) {
                            deleteButton.disabled = true;
                        }
                    }

                    if (this.emptyInspectorComponents.includes(compName)) {
                        compButton.addEventListener("click", (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        });
                    }
                    else {
                        //create card for component on buttonpress
                        compButton.addEventListener("click", (event) => {
                            // Check if card exists. ID's are added to card body
                            if (document.getElementById(compName) == null) {
                                if(this.comp.selectedNode.parent != VERTEX_NODE_NO_PARENT_GUID && compName == "VolumeTrigger") {
                                    this.tempCardBody.hidden = false;
                                    this.cardBody.classList.add("hide-card");
                                    this.CreateComponentPanel(self, nodeComponent, compName, compCard);
                                    const height = this.tempCardBody.offsetHeight + "px";
                                    this.tempCardBody.style.setProperty("height", height); 
                                    this.cardBody.style.setProperty("opacity", "0"); 
                                    Utils.delay(0).then(() => {
                                        this.tempCardBody.style.setProperty("height", "0"); 
                                        this.tempCardBody.classList.add("fade-out");
                                        this.cardBody.classList.add("fade-in-from-bottom");
                                        Utils.delay(500).then(() => {
                                            this.cardBody.style.setProperty("opacity", "unset");
                                                this.cardBody.classList.remove("hide-card");   
                                                this.tempCardBody.classList.remove("fade-out");
                                                this.cardBody.classList.remove("fade-in-from-bottom");
                                                this.tempCardBody.hidden = true;
                                                this.tempCardBody.style.setProperty("height", "unset"); 
                                        });
                                    });
                                } else {
                                    this.CreateComponentPanel(self, nodeComponent, compName, compCard);
                                }
                            }
                            else {
                                let activeComp = document.getElementById(compName);
                                activeComp.remove();
                            }

                            event.preventDefault();
                            event.stopPropagation();
                        });

                        if(isVolumeTrigger && this.comp.selectedNode.parent != VERTEX_NODE_NO_PARENT_GUID){
                            compButton.click();

                            compButton.style.display = "none";
                        }
                    }
                //}
            });

            let volumeTriggerCard: HTMLDivElement; //will keep track of the volume trigger card to click it after all the components are created
           
            //create buttons for each component type to ref from this node
            childNodes?.forEach((node) => {
                node.components.forEach((compName) => {
                    if(isGltf){
                        if(this.allowedChildComponentsModel.includes(compName)){
                           
                            const compCard = this.AddComponentReference(node, compName, this.cardBody);
                            //if the volume trigger component has just been added, we need to click it after all the components card are created
                            if(this.addedVolumeTrigger && compName == "VolumeTrigger"){
                                volumeTriggerCard = compCard;
                                this.addedVolumeTrigger = false;
                            }
                        }
                    }
                    else if(isMedia){
                        if(this.allowedChildComponentsMedia.includes(compName)){
                            const compCard = this.AddComponentReference(node, compName, this.cardBody);
                            
                            //if the volume trigger component has just been added, we need to click it after all the components card are created
                            if(this.addedVolumeTrigger && compName == "VolumeTrigger"){
                                volumeTriggerCard = compCard;
                                this.addedVolumeTrigger = false;
                            }
                        }
                    }
                });
            });

            //click the volume trigger card to open it as a user feedback
            if(volumeTriggerCard){
                volumeTriggerCard.click();
            }

            cardHeaderText.innerText = this.comp.selectedNode.name;
            cardHeaderText.title = this.comp.selectedNode.name;            

            if(this.comp.selectedNode.parent && this.comp.selectedNode.parent != VERTEX_NODE_NO_PARENT_GUID){
                backButton.classList.remove("d-none");

                backButton.onclick = (event) => {
                    const parentNode = space.nodes.get(this.comp.selectedNode.parent);
                    Vertex.Globals.event.fire("editor:selectNode", parentNode);
                };
            }
            else{
                backButton.classList.add("d-none");
                backButton.onclick = () => {};
            }
        });

        Vertex.Globals.event.on("editor:node-name-changed", (node) => {
            if(this.comp.selectedNode == node){
                cardHeaderText.innerText = node.name;
            }
        });
    }

    private createDropdownItem(item) {
        let componentDropdownItem = document.createElement("div");
        componentDropdownItem.classList.add("dropdown-item");
        componentDropdownItem.style.paddingLeft = "0.5rem";
        componentDropdownItem.style.paddingRight = "0.5rem";

        let componentDropdownItemIcon = document.createElement("img");
        componentDropdownItemIcon.classList.add("component-icon");
        componentDropdownItemIcon.style.filter = "invert(1) contrast(0.2)";
        (componentDropdownItemIcon as any).style.aspectRatio = "1";
        componentDropdownItemIcon.style.width = "auto";
        componentDropdownItemIcon.style.marginRight = "0.5rem";

        componentDropdownItemIcon.src = Utils.getComponentUIIcon(item);
        componentDropdownItem.appendChild(componentDropdownItemIcon);

        Utils.injectSvg(componentDropdownItemIcon);

        let componentDropdownItemLabel = document.createElement("a");
        componentDropdownItemLabel.innerText = Utils.getComponentUIName(item);
        componentDropdownItem.appendChild(componentDropdownItemLabel);

        return componentDropdownItem;
    }

    private AddComponentReference(node: Vertex.NodeComponentModel.VertexNode, compName: string, container : HTMLDivElement) {
        let compCard = container.querySelector(`#${compName}-section`) as HTMLDivElement;
        
        if(compCard == null){
            compCard = document.createElement("div");
            compCard.id = `${compName}-section`;

            let compSectionStatus = document.createElement("p");
            let compButton = document.createElement("div");
            let compIcon = document.createElement("img");
            let compText = document.createElement("div");
            let compList = document.createElement("div");

            compCard.classList.add("component-section");

            compSectionStatus.classList.add("component-section-status");
            compSectionStatus.innerText = "❯";
            compSectionStatus.style.visibility = this.emptyInspectorComponents.includes(compName) ? "hidden" : "visible";

            compButton.classList.add("btn", "btn-secondary", "component-button");
            compButton.id = compName + "-button";
            compIcon.classList.add("component-icon");
            compIcon.src = Utils.getComponentUIIcon(compName);

            compText.classList.add("component-text");
            compText.innerText = Utils.getComponentUIName(compName);

            compList.classList.add("component-list", "d-none");

            compCard.appendChild(compButton);
            compButton.appendChild(compSectionStatus);
            compButton.appendChild(compIcon);
            compButton.appendChild(compText);


            compCard.appendChild(compList);

            Utils.injectSvg(compIcon);

            compCard.addEventListener("click", (event) => {
                if (compList.classList.contains("d-none")) {
                    compList.classList.remove("d-none");
                    compList.classList.add("scroll-card");
                }
                else {
                    if (compList.classList.contains("scroll-card")) {
                        compList.classList.remove("scroll-card");
                    }
                    compList.classList.add("d-none");
                }

                event.preventDefault();
                event.stopPropagation();
            });

            container.appendChild(compCard);
        }

        const compRefContainer = document.createElement("div");
        compRefContainer.classList.add("component-reference");

        compRefContainer.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();
        });

        const compRefName = document.createElement("div");
        compRefName.classList.add("component-reference-name");
        compRefName.innerText = node.name;

        const compRefButtonsWrapper = document.createElement("div");
        compRefButtonsWrapper.classList.add("component-reference-buttons");

        const compRefEditButton = document.createElement("button");
        compRefEditButton.classList.add("component-reference-edit-button");

        const editIcon = document.createElement("img");
        editIcon.src = "/img/edit-icon.svg";
        editIcon.classList.add("node-icon");
        compRefEditButton.appendChild(editIcon);

        Utils.injectSvg(editIcon, {afterLoad: (svg, svgString) => { svg.querySelectorAll('path').forEach(path => path.style.fill = '#9a9a9a'); } });

        compRefEditButton.addEventListener("click", (event) => {
            Vertex.Globals.event.fire("editor:selectNode", node);

            event.preventDefault();
            event.stopPropagation();
        });

        const compRefDeleteButton = document.createElement("button");
        compRefDeleteButton.classList.add("component-reference-delete-button");

        let deleteIcon = document.createElement("img");
        deleteIcon.src = "/img/bin.svg";
        deleteIcon.classList.add("node-icon");
        compRefDeleteButton.appendChild(deleteIcon);

        Utils.injectSvg(deleteIcon, {afterLoad: (svg, svgString) => { svg.querySelectorAll('path').forEach(path => path.style.fill = '#9a9a9a'); } });


        compRefDeleteButton.addEventListener("click", (event) => {
            let nodeLockableComp = node.getComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent;

            if (!nodeLockableComp?.isLocked){
                Vertex.Globals.event.fire("space:nodeDestroyed", (node));

                let removableComponents : IDetachableRemotionLogicComponent [] = [];

                node.components.forEach(componentName => {
                    let component = node.getComponent(componentName);
                    
                    if(component && instanceOfIDetachableRemotionLogicComponent(component)){
                        removableComponents.push(component);
                        console.log(`The onRemoved lambda of ${componentName} componente has been canceled`);
                    }
                });

                removableComponents.forEach(component => {
                    component.detachRemotionLogic();
                });

                Vertex.Globals.runtime.space.destroyNode(node);

                compList.removeChild(compRefContainer);

                this.comp.triggerOnChanged();
            }

            event.preventDefault();
            event.stopPropagation();
        });

        // compRefContainer.addEventListener("click", (event) => {
        //     Vertex.Globals.event.fire("editor:selectNode", node);

        //     event.preventDefault();
        //     event.stopPropagation();
        // });

        compRefContainer.appendChild(compRefName);
        compRefButtonsWrapper.appendChild(compRefEditButton);
        compRefButtonsWrapper.appendChild(compRefDeleteButton);
        compRefContainer.appendChild(compRefButtonsWrapper);

        const compList = compCard.querySelector(".component-list") as HTMLDivElement;
        compList.appendChild(compRefContainer);

        return compCard;
    }

    private CreateComponentPanel(self: this, nodeComponent: Vertex.NodeComponentModel.Component, compName: string, container : HTMLDivElement) {

        let compCard = document.createElement("div") as HTMLDivElement;
        compCard.classList.add("card");
        compCard.classList.add("pointer-enable");
        compCard.classList.add("scroll-card");
        compCard.style.backgroundColor = "inherit";
        compCard.style.border = "none";
        compCard.id = compName;
        container.appendChild(compCard);


        let inspector = new Vertex.UI.InspectorPanel();
        inspector.excludedProperties = this.excludedProperties;

        // if there are custom property renderer settings for this specific component type, use them
        let compRenderersFactory = this.customComponentRenderers.get(compName);
        if (typeof compRenderersFactory === 'function') {
            let renderers = compRenderersFactory(compName, nodeComponent.getNode());
            renderers.forEach((renderer, propName) => {
                inspector.propertyRenderer(propName, renderer);
            });

            inspector.bind(compCard, nodeComponent);
        }
        // otherwise, use the default set of renderers
        else {
            inspector.propertyRenderer("rotation", new CustomRotationRenderer(nodeComponent.getNode()));
            inspector.propertyRenderer("position", new CustomXYZRenderer(nodeComponent.getNode()));
            inspector.propertyRenderer("scale", new CustomXYZRenderer(nodeComponent.getNode()));
            inspector.bind(compCard, nodeComponent);

            const lockableComp = nodeComponent.getNode().getComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent;

            if (lockableComp && lockableComp.isLocked) {
                const inputs = compCard.getElementsByTagName("input");
                for (let i = 0; i < inputs.length; i++) {
                    inputs[i].setAttribute("readonly", "");
                }
            }

        }

        return compCard;

    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
    
    transformRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        //Create a map of property renderers for the transform component
        //Here is used a trick to render the position, rotation and scale properties in a single card. The logic for each property is in the custom renderer
        let transformPropertyRenderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        transformPropertyRenderers.set("position", new CustomTransformRenderer(node));

        transformPropertyRenderers.set("rotation", new CustomBlankInspectorRenderer(node));
        transformPropertyRenderers.set("scale", new CustomBlankInspectorRenderer(node));
        transformPropertyRenderers.set("lerpScale", new CustomBlankInspectorRenderer(node));
        transformPropertyRenderers.set("viewNode", new CustomBlankInspectorRenderer(node));
        transformPropertyRenderers.set("parentGuid", new CustomBlankInspectorRenderer(node));
        
        return transformPropertyRenderers;
    }

    private volumeTriggerInspectorRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let volumeTriggerPropertyRenderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        volumeTriggerPropertyRenderers.set("enabled", new CustomVolumeTriggerRenderer(node));
        volumeTriggerPropertyRenderers.set("actionIndexes", new CustomBlankInspectorRenderer(node));
        volumeTriggerPropertyRenderers.set("actionValues", new CustomBlankInspectorRenderer(node));
        volumeTriggerPropertyRenderers.set("triggerActionIndexes", new CustomBlankInspectorRenderer(node));
        volumeTriggerPropertyRenderers.set("triggerActionValues", new CustomBlankInspectorRenderer(node));
        volumeTriggerPropertyRenderers.set("isTriggerActionComponent", new CustomBlankInspectorRenderer(node));
        volumeTriggerPropertyRenderers.set("onActionTriggered", new CustomBlankInspectorRenderer(node));
        volumeTriggerPropertyRenderers.set("funcOnLoad", new CustomBlankInspectorRenderer(node));
        volumeTriggerPropertyRenderers.set("isAwaitableComponent", new CustomBlankInspectorRenderer(node));
        
        return volumeTriggerPropertyRenderers;
    }

    private nodeTogglerInspectorRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let nodeTogglerPropertyRenderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        nodeTogglerPropertyRenderers.set("enabled", new CustomNodeTogglerRenderer(node));
        nodeTogglerPropertyRenderers.set("actionIndexes", new CustomBlankInspectorRenderer(node));
        nodeTogglerPropertyRenderers.set("actionValues", new CustomBlankInspectorRenderer(node));
        nodeTogglerPropertyRenderers.set("triggerActionIndexes", new CustomBlankInspectorRenderer(node));
        nodeTogglerPropertyRenderers.set("triggerActionValues", new CustomBlankInspectorRenderer(node));
        nodeTogglerPropertyRenderers.set("isTriggerActionComponent", new CustomBlankInspectorRenderer(node));
        nodeTogglerPropertyRenderers.set("onActionTriggered", new CustomBlankInspectorRenderer(node));
        nodeTogglerPropertyRenderers.set("renderingMesh", new CustomBlankInspectorRenderer(node));
        nodeTogglerPropertyRenderers.set("funcOnLoad", new CustomBlankInspectorRenderer(node));
        nodeTogglerPropertyRenderers.set("isAwaitableComponent", new CustomBlankInspectorRenderer(node));
        
        return nodeTogglerPropertyRenderers;
    }

    private gltfModelInspectorRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {

        let gltfComponentPropertyRenderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        gltfComponentPropertyRenderers.set("id", new CustomGltfPublishRenderer(node));
        gltfComponentPropertyRenderers.set("normalize", new CustomBlankInspectorRenderer(node));
        gltfComponentPropertyRenderers.set("visualNode", new CustomBlankInspectorRenderer(node));
        return gltfComponentPropertyRenderers;
    }

    private animationInspectorRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let animComponentPropertyRenderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        animComponentPropertyRenderers.set("shouldPlay", new CustomAnimationInspectorRenderer(node));
        animComponentPropertyRenderers.set("shouldPlayForward", new CustomBlankInspectorRenderer(node));
        animComponentPropertyRenderers.set("animationGroups", new CustomBlankInspectorRenderer(node));
        return animComponentPropertyRenderers;
    }

    private altModelInspectorRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let altModelPropertyRenderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        altModelPropertyRenderers.set("modelList", new CustomModelAlternativeRenderer(node));
        altModelPropertyRenderers.set("isLockableComponent", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("isAwaitableComponent", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("funcOnLoad", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("onStartOfLockingOperation", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("onEndOfLockingOperation", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("isPerformingOperation", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("newModelId", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("actionIndexes", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("actionValues", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("triggerActionIndexes", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("triggerActionValues", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("isTriggerActionComponent", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("onActionTriggered", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("enqueueOperation", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("executeChangeModel", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("completeCurrentOperation", new CustomBlankInspectorRenderer(node));
        altModelPropertyRenderers.set("checkAndExecuteTriggers", new CustomBlankInspectorRenderer(node));
        return altModelPropertyRenderers;
    }

    private lightInspectorRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("diffuseR", new CustomLightInspector(node));
        renderers.set("diffuseG", new CustomBlankInspectorRenderer(node));
        renderers.set("diffuseB", new CustomBlankInspectorRenderer(node));
        renderers.set("specularR", new CustomLightInspector(node));
        renderers.set("specularG", new CustomBlankInspectorRenderer(node));
        renderers.set("specularB", new CustomBlankInspectorRenderer(node));
        renderers.set("intensity", new CustomNumberfieldInspector(node));
        renderers.set("range", new CustomNumberfieldInspector(node));
        renderers.set("radius", new CustomNumberfieldInspector(node));
        renderers.set("angle", new CustomNumberfieldInspector(node));
        return renderers;
    }

    private itemPropertiesRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();

        let showProperties = true;

        if(node.components.includes("GltfModel") && (node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent)?.id === Config.DEFAULT_NAVMESH_ID || node.components.includes("VolumeTrigger")){
            showProperties = false;
        }

        renderers.set("isSelectable", new CustomItemPropertiesRenderer(node, showProperties));

        renderers.set("isMovable", new CustomBlankInspectorRenderer(node));
        renderers.set("isWishlistable", new CustomBlankInspectorRenderer(node));
        renderers.set("requiresFocus", new CustomBlankInspectorRenderer(node));
        renderers.set("useInAR", new CustomBlankInspectorRenderer(node));

        return renderers;
    }

    private savedCo2RendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("tenantInfoResPublishedId", new CustomSavedCO2Renderer(node));

        return renderers;
    }

    private spawnpointRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("renderer", new CustomSpawnPointRenderer(node));
        renderers.set("isReplacing", new CustomBlankInspectorRenderer(node));
        renderers.set("handleReplace", new CustomBlankInspectorRenderer(node));
        renderers.set("lastPosition", new CustomBlankInspectorRenderer(node));
        renderers.set("isPlacedInWarpArea", new CustomBlankInspectorRenderer(node));

        return renderers;
    }

    private rotationComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("speed", new CustomNumberfieldInspector(node, 0));
        renderers.set("isClockwise", new CustomBooleanInspector(node));
        renderers.set("count", new CustomBlankInspectorRenderer(node));
        renderers.set("isAwaitableComponent", new CustomBlankInspectorRenderer(node));
        renderers.set("funcOnLoad", new CustomBlankInspectorRenderer(node));
        return renderers;
    }

    private navMeshComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("meshes", new CustomBlankInspectorRenderer(node));
        renderers.set("gltfModelComp", new CustomBlankInspectorRenderer(node));
        renderers.set("isAwaitableComponent", new CustomBlankInspectorRenderer(node));
        renderers.set("funcOnLoad", new CustomBlankInspectorRenderer(node));

        return renderers;
    }

    private mediaTextureComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("id", new CustomMediaTextureRenderer(node));
        renderers.set("index", new CustomBlankInspectorRenderer(node));
        renderers.set("image", new CustomBlankInspectorRenderer(node));
        renderers.set("texture", new CustomBlankInspectorRenderer(node));
        renderers.set("images", new CustomBlankInspectorRenderer(node));
        renderers.set("files", new CustomBlankInspectorRenderer(node));
        renderers.set("currentId", new CustomBlankInspectorRenderer(node));
        renderers.set("previewMediaName", new CustomBlankInspectorRenderer(node));
        renderers.set("isSlideshow", new CustomBlankInspectorRenderer(node));
        renderers.set("mediaTexture", new CustomBlankInspectorRenderer(node));
        renderers.set("videoTexture", new CustomBlankInspectorRenderer(node));
        renderers.set("files", new CustomBlankInspectorRenderer(node));
        renderers.set("dataMedias", new CustomBlankInspectorRenderer(node));
        renderers.set("imageMaterial", new CustomBlankInspectorRenderer(node));
        renderers.set("videoMaterial", new CustomBlankInspectorRenderer(node));
        renderers.set("plane", new CustomBlankInspectorRenderer(node));
        renderers.set("previewVolume", new CustomBlankInspectorRenderer(node));
        renderers.set("videoTime", new CustomBlankInspectorRenderer(node));

        return renderers;
    }

    private videoTextureComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("id", new CustomBlankInspectorRenderer(node));
        renderers.set("videos", new CustomVideoTextureRenderer(node));
        renderers.set("videoTex", new CustomBlankInspectorRenderer(node));
        renderers.set("defaultMaterials", new CustomBlankInspectorRenderer(node));
        renderers.set("isAwaitableComponent", new CustomBlankInspectorRenderer(node));
        renderers.set("funcOnLoad", new CustomBlankInspectorRenderer(node));
        renderers.set("index", new CustomBlankInspectorRenderer(node));
        renderers.set("previewMediaName", new CustomBlankInspectorRenderer(node));
        renderers.set("files", new CustomBlankInspectorRenderer(node));
        renderers.set("dataMedias", new CustomBlankInspectorRenderer(node));
        renderers.set("previewVolume", new CustomBlankInspectorRenderer(node));
        renderers.set("postComponentJsonToResource", new CustomBlankInspectorRenderer(node));
        renderers.set("isReady", new CustomBlankInspectorRenderer(node));
        renderers.set("videoTexture", new CustomBlankInspectorRenderer(node));
        renderers.set("videoTime", new CustomBlankInspectorRenderer(node));
        renderers.set("isWaitingConversion", new CustomBlankInspectorRenderer(node));
        renderers.set("image", new CustomBlankInspectorRenderer(node));
        renderers.set("imageTexture", new CustomBlankInspectorRenderer(node));
        renderers.set("imageMaterial", new CustomBlankInspectorRenderer(node));
        renderers.set("videoMaterial", new CustomBlankInspectorRenderer(node));

        return renderers;
    }

    private lightMapsComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("lightMapsData", new CustomLightMapsInspectorRenderer(node));
        renderers.set("onSkinUpdatedBinded", new CustomBlankInspectorRenderer(node));
        renderers.set("cachedClonedMaterials", new CustomBlankInspectorRenderer(node));
        renderers.set("meshes", new CustomBlankInspectorRenderer(node));
        renderers.set("gltfJson", new CustomBlankInspectorRenderer(node));
        renderers.set("isAwaitableComponent", new CustomBlankInspectorRenderer(node));
        renderers.set("funcOnLoad", new CustomBlankInspectorRenderer(node));

        return renderers;
    }

    private changeMaterialComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("updatedMatIndexes", new CustomChangeMaterialInspectorRenderer(node));
        renderers.set("assetId", new CustomBlankInspectorRenderer(node));

        renderers.set("isResettableComponent", new CustomBlankInspectorRenderer(node));
        renderers.set("isAwaitableComponent", new CustomBlankInspectorRenderer(node));
        renderers.set("funcOnLoad", new CustomBlankInspectorRenderer(node));

        renderers.set("isLockableComponent", new CustomBlankInspectorRenderer(node));
        renderers.set("modelParsed", new CustomBlankInspectorRenderer(node));
        renderers.set("onStartOfLockingOperation", new CustomBlankInspectorRenderer(node));
        renderers.set("onEndOfLockingOperation", new CustomBlankInspectorRenderer(node));
        
        renderers.set("id", new CustomBlankInspectorRenderer(node));
        renderers.set("updatedMeshIndexes", new CustomBlankInspectorRenderer(node));
        renderers.set("actionIndexes", new CustomBlankInspectorRenderer(node));
        renderers.set("actionValues", new CustomBlankInspectorRenderer(node));
        renderers.set("triggerActionIndexes", new CustomBlankInspectorRenderer(node));
        renderers.set("triggerActionValues", new CustomBlankInspectorRenderer(node));
        renderers.set("isTriggerActionComponent", new CustomBlankInspectorRenderer(node));

        renderers.set("checkAndExecuteTriggers", new CustomBlankInspectorRenderer(node));
        return renderers;
    }

    private warpComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("type", new CustomWarpRenderer(node));
        renderers.set("id", new CustomBlankInspectorRenderer(node));
        renderers.set("data", new CustomBlankInspectorRenderer(node));
        renderers.set("hide", new CustomBlankInspectorRenderer(node));
        renderers.set("privateHost", new CustomBlankInspectorRenderer(node));
        renderers.set("publicHost", new CustomBlankInspectorRenderer(node));
        renderers.set("hidePrivateHost", new CustomBlankInspectorRenderer(node));
        renderers.set("hidePublicHost", new CustomBlankInspectorRenderer(node));
        return renderers;
    }

    private text2DComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("text", new CustomText2DRenderer(node));
        renderers.set("font", new CustomBlankInspectorRenderer(node));
        renderers.set("color", new CustomBlankInspectorRenderer(node));
        renderers.set("size", new CustomBlankInspectorRenderer(node));
        renderers.set("bold", new CustomBlankInspectorRenderer(node));
        renderers.set("italic", new CustomBlankInspectorRenderer(node));
        renderers.set("underlined", new CustomBlankInspectorRenderer(node));
        renderers.set("strikethrough", new CustomBlankInspectorRenderer(node));
        renderers.set("textPxPerMeter", new CustomBlankInspectorRenderer(node));
        renderers.set("textQualityMultiplier", new CustomBlankInspectorRenderer(node));
        renderers.set("widthPaddingPerc", new CustomBlankInspectorRenderer(node));
        renderers.set("resolution", new CustomBlankInspectorRenderer(node));
        renderers.set("textAlignment", new CustomBlankInspectorRenderer(node));
        renderers.set("plane", new CustomBlankInspectorRenderer(node));
        renderers.set("enabled", new CustomBlankInspectorRenderer(node));
        return renderers;
    }   
    
    private callToActionComponentRendererFactory(componentName: string, node: Vertex.NodeComponentModel.VertexNode) {
        let renderers = new Map<string, Vertex.UI.ICustomInspectorRenderer>();
        renderers.set("actions", new CustomCallToActionInspector(node));
        renderers.set("postComponentJsonToResource", new CustomBlankInspectorRenderer(node));
        renderers.set("isReady", new CustomBlankInspectorRenderer(node));
        renderers.set("isValidableComponent", new CustomBlankInspectorRenderer(node));
        renderers.set("removeMissingFile", new CustomBlankInspectorRenderer(node));

        return renderers;
    }
}

export class NodeEditorPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new NodeEditorPanelComponent();
    }
    constructor() {
        super("NodeEditorPanel", new NodeEditorPanelComponentComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

