export const HEVLS_API_DOMAIN = "https://func-hevls-prod-weu.azurewebsites.net";
export const CODE = "okn22xGRxvvQzeueuxCrXnXV9b3osu7NinmnaavbluH0AzFu0TWEmw==";
export const HEVLS_DOMAIN = "https://hevls.net";

const HIPE_XR_APIM_DOMAIN_PROD = "https://apim-hipexr-prod-italynorth.azure-api.net/api";
const CURRENT_API_VERSION = "2024-10-01";
const DEVELOPMENT_API_VERSION = "development";

export const HIPE_XR_API_VERSION_PARAM = "api-version";
export const HIPE_XR_API_VERSION = CURRENT_API_VERSION;

export const HIPE_XR_API_DOMAIN = HIPE_XR_APIM_DOMAIN_PROD;
export const HIPE_XR_API_KEY = "a9aa49a3f2544daea172e5c279876fce";