import { UserProfileUtils } from "../../utilities/user-profile-utilities";
import { AssistantApi } from "../xr-copilot-api/assistant/assistant";
import { HIPE_XR_API_DOMAIN, HIPE_XR_API_KEY, HIPE_XR_API_VERSION, HIPE_XR_API_VERSION_PARAM, XR_COPILOT_MODEL_SCOPE } from "./common";

const LICENSES_API_ENDPOINT = 'licenses';
const COMPANIES_API_ENDPOINT = 'companies';
const USERS_API_ENDPOINT = 'users';
const ASSIGN_LICENSE_API_ENDPOINT = 'assignlicense';
const REMOVE_LICENSE_API_ENDPOINT = 'removelicense';

export interface Company {
    id: string,
    description: string,
    tenantId: string,
    tenantIdVERTX: string,
    tenantName?: string,
    products?: CompanyProduct[],
    users?: UserCompany[],
    assignedLicenses?: LicenseAssigned[]
}

export interface UserCompany {
    companyId: string
    companyName: any
    userId: string
    userName: string,
    userEmail: string
}

export interface LicenseAssigned {
    licenseId: string
    licenseDescription: string
    attributionLevel: number
    assignments: LicenseAssignment[]
}

export interface LicenseAssignment {
    id: string
    licenseId: string
    userId: string
    licenseDescription: string
}

export interface UserScope {
    UserId: string;
    LicenseId: string;
    LicenseName: string;
    AttributionLevel: LicenseAttributionLevel;
    ScopeId: string;
    ScopeName: string;
    Quantity: number;
    IsScopeActive: boolean;
    IsUserActive: boolean;
    IsLicenseActive: boolean;
}

export enum LicenseAttributionLevel
{
    Company = 0,
    Dynamic_User = 1,
    Fixed_User = 2
}

export interface CompanyProduct {
    companyId: string
    productId: string
    description: string
    quantity: number
    mpType: any
    mpSubscriptionId: any
    createdDate: string
    expirationDate: any
    isActive: boolean
}

export interface LicenseRequestData {
    asArray?: boolean;
    companyId?: string;
    userId?: string;
    scopes: string[];
    sessionId?: string;
}

export interface XRCopilotModelsSlots {
    totalSlots: number;
    activeSlots: number;
}

export class LicensingApi {
    static async checkLicense(scopes: string[], token?: string, asArray: boolean = false, companyId?: string){
        if(!scopes){
            return;
        }
        
        token = token ?? Vertex.Globals.bearerToken;
        companyId = companyId ?? await UserProfileUtils.getTenantId(token);

        if(!token || !companyId){
            return;
        }

        const HevoHubTokenProviderClient = require('@hevolusinnovation/hevohub.tokenprovider.client.js');
        
        try {
            let client = new HevoHubTokenProviderClient(HIPE_XR_API_KEY);     
            return await client.checkLicenseAsync(companyId, scopes, token, null, asArray);
        } catch (e) {
            console.log(`Failed to check license for scopes ${scopes} in company ${companyId}`, e);
        }
    }


    static async getCompany(token?: string): Promise<Company> {
        token = token ?? Vertex.Globals.bearerToken;
        const companyId = await UserProfileUtils.getTenantId(token);

        if(!token || !companyId){
            console.log('Failed to get company, token or companyId is missing');
            return;
        }

        let uri = `${HIPE_XR_API_DOMAIN}/${LICENSES_API_ENDPOINT}/${COMPANIES_API_ENDPOINT}/${companyId}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try{
            const response = await fetch(uri, {
                headers: {
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                    "vertx-token": token
                }
            });

            if(response.ok){
                return await response.json() as Company;
            }
            else{
                console.log(`Failed to fetch Company ${companyId}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch Company ${companyId}`, e);
        }        
    }

    static async assignLicense(license: string, user: string, token?: string): Promise<boolean> {
        token = token ?? Vertex.Globals.bearerToken;

        if(!token || !license || !user){
            console.log('Failed to assign license, check parameters');
            return;
        }

        let uri = `${HIPE_XR_API_DOMAIN}/${LICENSES_API_ENDPOINT}/${USERS_API_ENDPOINT}/${user}/${ASSIGN_LICENSE_API_ENDPOINT}/${license}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try{
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                    "vertx-token": token
                }
            });

            if(response.ok){
                return true;
            }
            else{
                console.log(`Failed to assign license ${license} to ${user}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to assign license ${license} to ${user}`, e);
        }
    }

    static async removeLicense(license: string, user: string, assignment: string, token?: string): Promise<boolean> {
        token = token ?? Vertex.Globals.bearerToken;

        if(!token || !license || !user || !assignment){
            console.log('Failed to remove license, check parameters');
            return;
        }

        let uri = `${HIPE_XR_API_DOMAIN}/${LICENSES_API_ENDPOINT}/${USERS_API_ENDPOINT}/${user}/${REMOVE_LICENSE_API_ENDPOINT}/${license}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try{
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                    "vertx-token": token,
                    "vertx-assignment-id": assignment
                }
            });

            if(response.ok){
                return true;
            }
            else{
                console.log(`Failed to remove license ${license} from ${user}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to remove license ${license} from ${user}`, e);
        }
    }

    static async getXRCopilotModelSlots(token?: string): Promise<XRCopilotModelsSlots> {
        token = token ?? Vertex.Globals.bearerToken;

        if(!token){
            return;
        }

        let result = { totalSlots: 0, activeSlots: 0} as XRCopilotModelsSlots;

        const licenseResponse: string = await LicensingApi.checkLicense([XR_COPILOT_MODEL_SCOPE], token);

        if(!licenseResponse){
            return result;
        }

        if(licenseResponse.includes(XR_COPILOT_MODEL_SCOPE)){
            result.totalSlots = +licenseResponse.substring(XR_COPILOT_MODEL_SCOPE.length + 1);
        }

        result.activeSlots = await AssistantApi.getActiveIndexesCount(token) ?? 0;

        return result;
    }
}