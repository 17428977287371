import { Utils } from "../../utilities/utils";
import { CustomText2DComponent, TextAlignment } from "../componentsystems/NodeComponents/text2dcomponent";
export class CustomText2DRenderer implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }
    targetNode: Vertex.NodeComponentModel.VertexNode;
    text2DComp: CustomText2DComponent;
    property: string;

    RenderProperty(property: string, target: any): HTMLDivElement {
        this.text2DComp = target;
        this.property = property;

        let outer = document.createElement('div');
        outer.style.padding = "0 1rem";
        this.renderPropertyInputField(outer, "text");
        this.setupPropertyColorPicker(outer, "color");
        this.setupPropertyNumberField(outer, "size", 0.1, 35, 0.1);
        this.createTextAlignDropdown(outer, "alignment");

        //TODO: font property
        let container = document.createElement('div');
        container.id = "container-checkboxes";
        outer.appendChild(container);

        this.setupPropertyToggle(container, "bold");
        this.setupPropertyToggle(container, "italic");
        this.setupPropertyToggle(container, "underlined");
        this.setupPropertyToggle(container, "strikethrough");

        return outer;
    }

    setupPropertyToggle(container: HTMLDivElement, property: string) {

        let row = document.createElement("div"); row.classList.add("row", "control-group", "input-row", "align-items-center", "pad-border-bottom");
        let nameCol = document.createElement("div"); nameCol.classList.add("col", "control-label"); nameCol.innerText = property; row.appendChild(nameCol);
        let inputCol = document.createElement("div"); inputCol.classList.add("col-3", "control-value");
        let inputField = document.createElement("input") as HTMLInputElement; inputField.type = "checkbox"; inputField.classList.add("form-control", "small-checkbox");
        row.id = `container-${property}`;

        let currentValue = this.text2DComp[property];
        inputField.checked = currentValue;

        inputField.addEventListener("change", (() => this.updateInputField(property, inputField)).bind(this));

        inputCol.appendChild(inputField);
        row.appendChild(inputCol);
        container.appendChild(row);
    }

    private updateInputField(property, inputField) {
        this.text2DComp[property] = inputField.checked;
        this.text2DComp.triggerOnChanged();
    }

    setupPropertyNumberField(outer: HTMLDivElement, property: string, min?: number, max?: number, step?: number) {
        let row = document.createElement("div"); row.classList.add("control-group", "input-row", "align-items-center", "pad-border-bottom");
        row.style.display = "flex";
        let nameCol = document.createElement("div"); nameCol.classList.add("control-label"); nameCol.innerText = property; row.appendChild(nameCol); nameCol.style.flexGrow = "1";
        let inputCol = document.createElement("div"); inputCol.classList.add("control-value");
        let inputField = document.createElement("input"); inputField.type = "number"; inputField.autocomplete = "off";
        inputField.spellcheck = false; inputField.classList.add("form-control");
        inputField.value = this.text2DComp[property];
        row.id = `container-${property}`;

        if (min != null) {
            inputField.min = min.toString();
        }

        if (max != null) {
            inputField.max = max.toString();
        }

        if (step != null) {
            inputField.step = step.toString();
        }

        inputField.addEventListener("change", () => {
            let value = parseFloat(inputField.value);
            if (max != null && value > max) {
                inputField.value = max.toString();
            }

            if (min != null && value < min) {
                inputField.value = min.toString();
            }

            this.text2DComp[property] = inputField.value;
            this.text2DComp.triggerOnChanged();
        });

        inputCol.appendChild(inputField);
        row.appendChild(inputCol);
        outer.appendChild(row);
    }

    setupPropertyColorPicker(outer: HTMLDivElement, property: string) {
        let colorPickerWrapper = document.createElement("div");
        colorPickerWrapper.classList.add("control-group", "input-row", "align-items-center", "pad-border-bottom");
        colorPickerWrapper.classList.add("slider-wrapper");
        colorPickerWrapper.style.display = "flex";
        colorPickerWrapper.id = `container-${property}`;

        let title = document.createElement("div");
        title.classList.add("slider-text");
        title.style.minWidth = "35%";
        // title.style.flex = "none";
        title.style.flexGrow = "1";
        title.innerText = property;
        let colorPicker = document.createElement("input");
        colorPicker.classList.add("pbr-slider");
        colorPicker.setAttribute("type", "color");
        colorPicker.setAttribute("value", this.text2DComp[property]);

        colorPicker.addEventListener('input', () => {
            this.text2DComp[property] = colorPicker.value;
            this.text2DComp.triggerOnChanged();
        });

        colorPickerWrapper.appendChild(title);
        colorPickerWrapper.appendChild(colorPicker);
        outer.appendChild(colorPickerWrapper);
    }

    renderPropertyInputField(outer: HTMLDivElement, property: string) {
        let row = document.createElement('div');
        row.classList.add('row', 'control-group', 'align-items-center', 'input-row', "pad-border-bottom");
        row.id = `container-${property}`;
        let label = document.createElement('div');
        label.classList.add('col-2', 'control-label');
        label.innerText = property;

        let valueContainer = document.createElement('div');
        valueContainer.classList.add('col', 'control-value');

        let inputField = document.createElement('input');
        inputField.classList.add('form-control');
        inputField.type = "text";
        inputField.spellcheck = false;
        inputField.autocomplete = "off";
        inputField.style.minWidth = "0";
        inputField.placeholder = "Enter text...";
        inputField.value = this.text2DComp[property];

        // Sanitize the input value by trimming whitespace and applying the sanitizeString function
        inputField.addEventListener('change', () => {
            const value = Utils.sanitizeString(inputField.value.trim());
        
            if (value) {
                inputField.value = this.text2DComp[property] = value;
                this.text2DComp.triggerOnChanged();
            } 
            else { // If the sanitized value is empty, revert the input to the previous value
                inputField.value = this.text2DComp[property];
            }
        });

        valueContainer.appendChild(inputField);

        row.appendChild(label);
        row.appendChild(valueContainer);
        outer.appendChild(row);
    }

    createTextAlignDropdown(outer: HTMLDivElement, property: string) {

        let row = document.createElement('div');
        row.classList.add('row', 'control-group', 'align-items-center', 'input-row', "pad-border-bottom");
        row.id = `container-${property}`;
        let label = document.createElement('div');
        label.classList.add('col-2', 'control-label');
        label.innerText = property;

        let valueContainer = document.createElement('div');
        valueContainer.classList.add('col', 'control-value');

        const alignSelect = document.createElement("select") as HTMLSelectElement;

        alignSelect.classList.add("form-control", "component-toggle", "dropdown-toggle");
        alignSelect.style.margin = "auto";
        alignSelect.style.display = "block";
        alignSelect.style.margin = "0px";
        alignSelect.style.padding = "0px 10px";
        alignSelect.style.border = "1px solid lightgray";
        alignSelect.style.width = "fit-content";
        alignSelect.setAttribute("aria-haspopup", "true");
        alignSelect.setAttribute("aria-expanded", "false");

        const emptyOption = document.createElement("option") as HTMLOptionElement;
        emptyOption.hidden = true;
        emptyOption.disabled = true;
        emptyOption.defaultSelected = true;
        emptyOption.innerText = "Select alignment";

        alignSelect.appendChild(emptyOption);
        row.appendChild(alignSelect);

        for (let i = 0; i < Object.keys(TextAlignment).length/2; i++) {

            const textAlignmentOption = TextAlignment[i] as string;

            const link = document.createElement("option");
            link.classList.add("dropdown-item");
            link.innerText = textAlignmentOption.replace('_', " ").toLowerCase();
            alignSelect.appendChild(link);          
        }

        if (this.text2DComp.textAlignment != null) {
            alignSelect.selectedIndex = this.text2DComp.textAlignment + 1;
        }

        alignSelect.addEventListener("change", () => {
            this.text2DComp.setTextAlignment(alignSelect.selectedIndex - 1);
        });

        row.appendChild(label);
        valueContainer.appendChild(alignSelect);
        row.appendChild(valueContainer);
        outer.appendChild(row);

    }


    // populateDropdown(ldropdownMenu: HTMLDivElement, dropdownToggle: HTMLButtonElement) {
    //     ldropdownMenu.innerHTML = "";
    //     let dropdownItem = document.createElement("a");
    //     dropdownItem.classList.add("dropdown-item");
    //     //dropdownItem.innerText = "None";
    //     dropdownItem.addEventListener("click", (e) => {
    //         //dropdownToggle.innerText = "None";
    //         // this.lightMapsComponent.lightMapsData[meshIndex] = -1;
    //         // this.lightMapsComponent.triggerOnChanged();          
    //         $(ldropdownMenu).toggle();
    //         e.stopPropagation();
    //     });
    //     //ldropdownMenu.appendChild(dropdownItem);

    //     let dropdownItems = Object.entries(TextAlignment);
    //     for (let index = 0; index < dropdownItems.length; index++) {
    //         //if(!CustomLightMapsHandlerComponent.lightmapsToDelete.includes(dropdownItems[index].fileName)){
    //         let dropdownItem = document.createElement("a");
    //         dropdownItem.classList.add("dropdown-item");
    //         dropdownItem.innerText = TextAlignment[index];
    //         dropdownItem.addEventListener("click", (e) => {
    //             //dropdownToggle.innerText = dropdownItems[index];
    //             // this.lightMapsComponent.lightMapsData[meshIndex] = index;          
    //             // this.lightMapsComponent.triggerOnChanged();          
    //             $(ldropdownMenu).toggle();
    //             e.stopPropagation();
    //         })
    //         ldropdownMenu.appendChild(dropdownItem);
    //         //}
    //     }
    // }

}