import { AvatarComponentSystem, TrackedNodeComponentSystem, AvatarGltfModelComponentSystem } from "@vertx/avatars-babylon";
import { CameraPropertiesPanelComponentSystem } from "../model-editor/componentsystems/camerapropertiespanel";
import { MousePickerComponentSystem } from "../model-editor/componentsystems/mousepicker";
import { ResourceExplorerPanelComponentSystem } from "../model-editor/componentsystems/resourceexplorerpanel";
import { SkyboxComponentSystem } from "../model-editor/componentsystems/skyboxcomponent";
import { TeleportComponentSystem } from "../scene-editor/componentsystems/EditorComponents/Avatar/teleport";
import { ActionSettingsPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/actionsettingspanel";
import { AdditionalComponentsPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/additionalcomponentspanel";
import { GizmosComponentSystem } from "../scene-editor/componentsystems/EditorComponents/gizmos";
import { IntroSplashPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/introsplashpanel";
import { LightHierarchyPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/lighthierarchypanel";
import { LightMapsHandlerComponentSystem } from "../scene-editor/componentsystems/EditorComponents/lightmapshandler";
import { NodeEditorPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/nodeeditorpanel";
import { PersistanceComponentSystem } from "../scene-editor/componentsystems/EditorComponents/persistance";
import { PointsOfInterestPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/pointsofinterestpanel";
import { PostProcessPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/postprocesspropertiespanel";
import { SaveSettingsPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/savesettingspanel";
import { SceneHierarchyPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/scenehierarchypanel";
// import { SceneSettingsPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/scenesettingspanel";
import { SceneValidatorComponentSystem } from "../scene-editor/componentsystems/EditorComponents/scenevalidator";
import { SidebarComponentSystem } from "../scene-editor/componentsystems/EditorComponents/sidebar";
import { SkyboxSettingsPanelComponentSystem } from "../scene-editor/componentsystems/EditorComponents/skyboxsettingspanel";
import { ActionSettingsComponentSystem } from "../scene-editor/componentsystems/NodeComponents/actionsettingscomponent";
import { AnimationComponentSystem } from "../scene-editor/componentsystems/NodeComponents/animationcomponent";
import { CallToActionComponentSystem } from "../scene-editor/componentsystems/NodeComponents/calltoaction";
import { CameraPropertiesComponentSystem } from "../scene-editor/componentsystems/NodeComponents/camerapropertiescomponent";
import { ChangeMaterialComponentSystem } from "../scene-editor/componentsystems/NodeComponents/changematerial";
import { DirectionalLightComponentSystem } from "../scene-editor/componentsystems/NodeComponents/directionallightcomponent";
import { CustomGltfLoadingHandlerComponent, GltfLoadingHandlerComponentSystem } from "../scene-editor/componentsystems/NodeComponents/gltfLoadingHandlerComponent";
import { HiddenMeshComponentSystem } from "../scene-editor/componentsystems/NodeComponents/hiddenmeshcomponent";
import { IntroSplashComponentSystem } from "../scene-editor/componentsystems/NodeComponents/introsplashcomponent";
import { ItemPropertiesComponentSystem } from "../scene-editor/componentsystems/NodeComponents/itemproperties";
import { LightMapsComponentSystem } from "../scene-editor/componentsystems/NodeComponents/lightmapscomponent";
import { MediaTextureComponentSystem } from "../scene-editor/componentsystems/NodeComponents/mediaTexture";
import { ModelAlternativeComponentSystem } from "../scene-editor/componentsystems/NodeComponents/modelalternativecomponent";
import { NavMeshComponentSystem } from "../scene-editor/componentsystems/NodeComponents/navmeshcomponent";
import { NodeLockableComponentSystem } from "../scene-editor/componentsystems/NodeComponents/nodeLockablecomponent";
import { PointLightComponentSystem } from "../scene-editor/componentsystems/NodeComponents/pointlightcomponent";
import { PointsOfInterestComponentSystem } from "../scene-editor/componentsystems/NodeComponents/pointsofinterestcomponent";
import { PostProcessComponentSystem } from "../scene-editor/componentsystems/NodeComponents/postprocesspropertiescomponent";
import { RotationComponentSystem } from "../scene-editor/componentsystems/NodeComponents/rotationcomponent";
import { SavedCO2VisualizerComponentSystem } from "../scene-editor/componentsystems/NodeComponents/savedco2visualizercomponent";
import { ScenePropertiesComponentSystem } from "../scene-editor/componentsystems/NodeComponents/scenepropertiescomponent";
import { SpawnPointComponentSystem } from "../scene-editor/componentsystems/NodeComponents/spawnpointcomponent";
import { SpotLightComponentSystem } from "../scene-editor/componentsystems/NodeComponents/spotlightcomponent";
import { Text2DComponentSystem } from "../scene-editor/componentsystems/NodeComponents/text2dcomponent";
import { UnselectableComponentSystem } from "../scene-editor/componentsystems/NodeComponents/unselectablecomponent";
import { VideoTextureComponentSystem } from "../scene-editor/componentsystems/NodeComponents/videotexturecomponent";
import { WarpComponentSystem } from "../scene-editor/componentsystems/NodeComponents/warpcomponent";
import { SERIALIZED_SPACE_FILENAME, VERTEX_NODE_NO_PARENT_GUID } from "./constants";
import { HevolusResourceType, Result, ResourceUtils, DetailedResult } from "./resource-utilities";
import Swal from "sweetalert2";
import { AugmentedStoreAssembly } from "../../AugmentedStoreAssembly";
import { Config } from "../../config";
import { UserProfileUtils } from "./user-profile-utilities";
import { NodeTogglerComponentSystem } from "../scene-editor/componentsystems/NodeComponents/nodetogglercomponent";
import { VolumeTriggerComponentSystem } from "../scene-editor/componentsystems/NodeComponents/volumetriggercomponent";
import { clone } from "lodash";
import { TaskListComponentSystem } from "../scene-editor/componentsystems/NodeComponents/tasklist";

export class SpaceCloneUtils {
    
    /**
     * Utiliity to clone a Space resource into a different tenant. All involved Model and Media resources will be also cloned.
     * If you pass a published resource GUID as id, it will publish the cloned resource on the destination Tenant for you.
     * If you already cloned that exact resource, it will ask you if you want to overwrite the resource or keep it still.
     * @param id The GUID of the resource you want to clone
     * @param destToken The Token of a valid user on the destination Tenant
     * @returns The state of the process and eventually the GUID of the brand new cloned resource. The GUID will be the private resource GUID if
     * the provided id is the GUID of a private resource, published otherwise.
     */
    static async cloneSpaceResource(id: string, token: string): Promise<DetailedResult>{
        const originToken = Vertex.Globals.bearerToken;
        
        //check input variables
        if(id === null || token === null){
            return { result: Result.Failed, message: `Invalid id or token` };
        }

        if(originToken === token){
            return { result: Result.Failed, message: `Origin and Destination Token are the same` };
        }

        const originUser = await UserProfileUtils.getUserInfo(originToken);
        const destUser = await UserProfileUtils.getUserInfo(token);

        if(!originUser || !destUser){
            return { result: Result.Failed, message: `Invalid user` };
        }

        if(originUser.vertex_tid === destUser.vertex_tid){
            return { result: Result.Failed, message: `Origin and Destination Tenant are the same` };
        }

        //get the space resource 
        const resource = await ResourceUtils.getResourceAsync(id, false, originToken);

        if(resource == null || resource.resourceKeys == null){
            console.log(`Failed to get resource info for GUID: ${id}`);

            return { result: Result.Failed, message: `Invalid resource` };
        }

        const hevoResourceType = ResourceUtils.getHevolusResourceType(resource);

        if(hevoResourceType !== HevolusResourceType.Space){
            return { result: Result.Failed, message: `Invalid resource` };
        }

        const cloneResult = await ResourceUtils.cloneResource(id, token);

        if(cloneResult.result === Result.Canceled){
            console.log(`Canceled clone space ${resource.name} ${id}`);

            return { result: Result.Canceled };
        }
        else if(cloneResult.result === Result.Failed || !cloneResult.value){
            console.log(`Failed to clone space ${resource.name} ${id}`);

            return { result: Result.Failed, message: `Failed to create the new resource` };
        }

        let clonedResourceId = cloneResult.value;

        //this will be used to map GUIDs of the resources included in the Space
        const guidMap: Map<string, string> = new Map<string, string>();

        ////// Here starts Vertx initialization. We need it because how Vertx Runtime is currently implemented (unfortunately)
        
        //register all possibly needed component systems
        Vertex.Globals.event.on("vertex:registerComponentSystems", async (componentSystem: Vertex.NodeComponentModel.ComponentSystem) => {
            componentSystem.register(new MousePickerComponentSystem());
            componentSystem.register(new GizmosComponentSystem());
            componentSystem.register(new NodeEditorPanelComponentSystem());
            componentSystem.register(new SceneHierarchyPanelComponentSystem());
            componentSystem.register(new ResourceExplorerPanelComponentSystem());
            componentSystem.register(new SidebarComponentSystem());
            // componentSystem.register(new SceneSettingsPanelComponentSystem());
            componentSystem.register(new PersistanceComponentSystem());
            componentSystem.register(new NodeLockableComponentSystem());
            componentSystem.register(new UnselectableComponentSystem());
            componentSystem.register(new ItemPropertiesComponentSystem());
            componentSystem.register(new LightHierarchyPanelComponentSystem());
            componentSystem.register(new SaveSettingsPanelComponentSystem());
            componentSystem.register(new SkyboxSettingsPanelComponentSystem());
            componentSystem.register(new SkyboxComponentSystem());
            componentSystem.register(new ModelAlternativeComponentSystem());
            componentSystem.register(new RotationComponentSystem());
            componentSystem.register(new VideoTextureComponentSystem());
            componentSystem.register(new MediaTextureComponentSystem());
            componentSystem.register(new NavMeshComponentSystem());            
            componentSystem.register(new CameraPropertiesPanelComponentSystem());
            componentSystem.register(new CameraPropertiesComponentSystem());
            componentSystem.register(new PostProcessPanelComponentSystem());
            componentSystem.register(new PostProcessComponentSystem());
            componentSystem.register(new PointsOfInterestPanelComponentSystem());
            componentSystem.register(new PointsOfInterestComponentSystem());
            componentSystem.register(new TeleportComponentSystem());
            componentSystem.register(new HiddenMeshComponentSystem());
            componentSystem.register(new AnimationComponentSystem());
            componentSystem.register(new DirectionalLightComponentSystem());
            componentSystem.register(new SpotLightComponentSystem());
            componentSystem.register(new PointLightComponentSystem());
            componentSystem.register(new LightMapsComponentSystem());
            componentSystem.register(new ChangeMaterialComponentSystem());
            componentSystem.register(new SceneValidatorComponentSystem());
            componentSystem.register(new LightMapsHandlerComponentSystem());
            componentSystem.register(new ScenePropertiesComponentSystem());
            componentSystem.register(new AvatarComponentSystem());
            componentSystem.register(new TrackedNodeComponentSystem());
            componentSystem.register(new AvatarGltfModelComponentSystem());
            componentSystem.register(new WarpComponentSystem());
            componentSystem.register(new IntroSplashPanelComponentSystem());
            componentSystem.register(new IntroSplashComponentSystem());
            componentSystem.register(new SpawnPointComponentSystem());
            componentSystem.register(new AdditionalComponentsPanelComponentSystem());
            componentSystem.register(new SavedCO2VisualizerComponentSystem());
            componentSystem.register(new Text2DComponentSystem());
            componentSystem.register(new GltfLoadingHandlerComponentSystem());
            componentSystem.register(new ActionSettingsComponentSystem());
            componentSystem.register(new ActionSettingsPanelComponentSystem());
            componentSystem.register(new CallToActionComponentSystem());
            componentSystem.register(new NodeTogglerComponentSystem());
            componentSystem.register(new VolumeTriggerComponentSystem());
            componentSystem.register(new TaskListComponentSystem());

            Vertex.Globals.event.fire("vertex:componentRegistrationComplete");
        });
        
        Vertex.Globals.vertexStackUrl = Config.VERTEX_URL_BASE;
        Vertex.Globals.spaceId = VERTEX_NODE_NO_PARENT_GUID;

        await VertexBabylon.InitVertexAsync({allowOffline: true});

        const cam = new BABYLON.ArcRotateCamera("cam", 0, 0, 0, new BABYLON.Vector3(0, 0, 0), Vertex.Globals.runtime.scene, true);

        let space = Vertex.Globals.runtime.space;

        if(resource.resourceKeys.includes(SERIALIZED_SPACE_FILENAME)){
            //let's get the bin file to check its content
            const binResult = await ResourceUtils.getAssetFromResource(clonedResourceId, SERIALIZED_SPACE_FILENAME, token);

            if(!binResult.ok){
                console.log(`Failed to get ${SERIALIZED_SPACE_FILENAME} from cloned space ${resource.name}.`);

                //we failed getting the bin file to update resource GUIDs. The space won't work correctly. Let's delete it.
                const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                
                if(!deleted){
                    console.log(`Failed to delete space ${clonedResourceId} on the destination Tenant.`);
                }

                return { result: Result.Failed, message: `Failed to get the Space nodes info` };
            }
            
            //store here all resource GUIDs to clone later
            const additionalResourceIDs: string[] = [];

            //store here nodes to be modified after cloning the resources
            const deserializedNodes: Vertex.NodeComponentModel.VertexNode[] = [];

            //pass the downloaded file as a buffer to the BSON deserialiser
            let spaceBuffer = await binResult.arrayBuffer();
            let binReader = new Vertex.BinaryReader(spaceBuffer);
            let bsonReader = new Vertex.BSONReader(binReader);

            // This method takes an Action<>, which is invoked for every property in the BSON object. No knowledge of how many nodes until loading has finished.
            // propertyName = the name of the BSON property. This will be the serialized NodeID. Can be checked to prevent reading non-node types
            // bsonType = a System.Type representing the BSON type of the read property. unused
            // _reader2 = the bsonReader itself at the time it reads that property, ready to deserialize only that property if called.
            bsonReader.readObject((propertyName, bsonType, _reader2) => {
                if (propertyName.startsWith("node_")) {

                    let node = space.createNode(propertyName) as Vertex.NodeComponentModel.VertexNode;
                    node.componentDataType = Vertex.NodeComponentModel.ComponentDataType.BSONObject;
                    node.readDataBSON(_reader2);

                    if(node.components.includes("GltfModel")){
                        let needResource = true;
                        const gltfModel = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;

                        if(node.components.includes("NavMesh")){
                            if(gltfModel.Id === Config.DEFAULT_NAVMESH_ID){
                                needResource = false;
                            }
                        }

                        if(needResource){
                            additionalResourceIDs.push(gltfModel?.Id);
                        }

                        if(node.components.includes("ModelAlternative")){
                            const altModel = node.getComponent("ModelAlternative") as AugmentedStoreAssembly.ModelAlternativeComponent;

                            if(altModel?.modelList?.length){
                                for(let i = 0; i < altModel.modelList.length; i++){
                                    const model = altModel.modelList[i];

                                    if(gltfModel.Id !== model){
                                        additionalResourceIDs.push(model);
                                    }
                                }
                            }
                        }
                    }

                    if(node.components.includes("MediaTexture")){
                        const mediaModel = node.getComponent("MediaTexture") as AugmentedStoreAssembly.MediaTextureComponent;
                        additionalResourceIDs.push(mediaModel?.id);
                    }

                    deserializedNodes.push(node);
                }
                else {
                    console.log(`Unknown property: ${propertyName} found in serialized BSON!`);
                }
            });

            //actually clone all needed resources and map the GUIDs
            for(let i = 0; i < additionalResourceIDs.length; i++){
                const resId = additionalResourceIDs[i];

                const cloneResult = await ResourceUtils.cloneResource(resId, token);

                if(Swal.isLoading()){
                    if(!Swal.getTitle().textContent.includes(resource.name)){
                        Swal.update({
                            title: `Cloning<br>${resource.name}`,
                        });
        
                        Swal.showLoading();
                    }
                }
                else{
                    //let's update the loader ui with the space name
                    Swal.fire({
                        icon: 'info',
                        title: `Cloning<br>${resource.name}`,
                        showCancelButton: false,
                        showCloseButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        heightAuto: false,
                        width: "auto",
                    });
                    
                    Swal.showLoading();
                }

                if(cloneResult.value && (cloneResult.result === Result.Success || cloneResult.result === Result.Canceled)){
                    guidMap.set(resId, cloneResult.value);
                }
                else{
                    console.log(`Failed to create clone for associated resource with GUID: ${resId}}`);

                    const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                
                    if(!deleted){
                        console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                    }
                    
                    return { result: Result.Failed, message: `Failed to clone Space associated resource ${resId}` };
                }
            }

            let binWriter = new Vertex.BinaryWriter(4096 * 1024); //a node really shouln't be bigger than 4096... So a limit of around 1024 nodes?
            let bsonWriter = new Vertex.BSONWriter(binWriter);
            let context = bsonWriter.startObject();

            for(let i = 0; i < deserializedNodes.length; i++){
                const node = deserializedNodes[i];

                if(node.components.includes("GltfModel")){
                    const gltfModel = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
                    const newId = guidMap.get(gltfModel.Id);

                    if(newId != null){
                        gltfModel.id = newId;
                    }
                    
                    if(node.components.includes("ModelAlternative")){
                        const altModel = node.getComponent("ModelAlternative") as AugmentedStoreAssembly.ModelAlternativeComponent;

                        if(altModel?.modelList?.length){
                            for(let i = 0; i < altModel.modelList.length; i++){
                                const model = altModel.modelList[i];

                                if(guidMap.has(model)){
                                    altModel.modelList[i] = guidMap.get(model);
                                }
                                else{
                                    //something went wrong 
                                    //we may want to fail here and communicate why in the return msg
                                }
                            }
                        }
                    }
                }

                if(node.components.includes("MediaTexture")){
                    const mediaTex = node.getComponent("MediaTexture") as AugmentedStoreAssembly.MediaTextureComponent;
                    const newId = guidMap.get(mediaTex.id);

                    if(newId != null){
                        mediaTex.id = guidMap.get(mediaTex.id);
                    }
                }

                bsonWriter.writeObject(`node_${node.id}`, node.writeDataBSON.bind(node));
            }

            bsonWriter.endObject(context);

            let newBuffer = binWriter.toBuffer();

            const spaceBinResp = await ResourceUtils.postAssetToResource(SERIALIZED_SPACE_FILENAME, clonedResourceId, newBuffer, token);

            if(!spaceBinResp.ok){
                console.log(`Failed to post the updated space bin file to ${clonedResourceId}`);

                const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                
                if(!deleted){
                    console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                }
                
                return { result: Result.Failed, message: `Failed to create the Space nodes info` };
            }

            //now just check if the space is already published and thus needs an update after the bin POST
            const clonedResource = await ResourceUtils.getResourceAsync(clonedResourceId, false, token);

            if(clonedResource && clonedResource.publishedResources && clonedResource.publishedResources.length > 0){
                const republishResp = await ResourceUtils.republishResource(clonedResourceId, token);

                if(!republishResp.ok){
                    console.log(`Failed to republish space ${resource.name} after updating the ${SERIALIZED_SPACE_FILENAME} file.`);
                }
            }
        }
        
        return { result: Result.Success, value: clonedResourceId };
    }

    /**
     * Utility to clone a Space node into the same Space. The node is also added to the Space.
     * @param node the node to clone
     */
    static async cloneSpaceNode(node: Vertex.NodeComponentModel.VertexNode): Promise<Vertex.NodeComponentModel.VertexNode>{
        if(!node || !node.Space){
            return;
        }

        const spaceResource = await ResourceUtils.getResourceData(node.Space.id);
        const compJsons = spaceResource.resourceKeys.filter(k => k.includes(node.id));

        let clonedNode: Vertex.NodeComponentModel.VertexNode = node.Space.createNode(node.name) as Vertex.NodeComponentModel.VertexNode;

        for(let i = 0; i < compJsons.length; i++){
            const compJson = compJsons[i];
            const fileResp = await ResourceUtils.getAssetFromResource(node.Space.id, compJson);

            if(fileResp.ok){
                const file = await fileResp.json();
                const newName = compJson.replace(node.id, clonedNode.id);
                const uploadResp = await ResourceUtils.postAssetToResource(newName, node.Space.id, JSON.stringify(file));

                if(!uploadResp.ok){
                    console.log(`Failed to upload ${newName} to ${clonedNode.id}`);
                }
            }
        }

        node.components.filter(c => c !== "GltfLoadingHandler").forEach(c => {
            const comp = node.getComponent(c);
            const compName = comp.name.endsWith("Component") ? comp.name : comp.name + "Component";

            const newComponent = node.createComponent(c);

            const baseKeys = Object.keys(new Vertex.NodeComponentModel.Component());
            const assemblyComp = AugmentedStoreAssembly[compName] ?? Vertex.NodeComponentModel[compName];
            if(!assemblyComp){
                return;
            }

            const assemblyCompInstance = new assemblyComp();
            const assemblyCompKeys = Object.keys(assemblyCompInstance);
            const compKeys = Object.keys(comp).filter(k => !baseKeys.includes(k)).filter(k => assemblyCompKeys.includes(k)).filter(k => !(comp[k] instanceof Vertex.NodeComponentModel.RPCEventBus || comp[k] instanceof Vertex.EventBus || comp[k] instanceof Map || k === "viewNode"));


            compKeys.forEach(key => {
                newComponent[key] = clone(comp[key]);
            });

            newComponent.node = clonedNode;
            
            const addedComp = clonedNode.addComponent(c, newComponent);

            addedComp.triggerOnChanged();
        });

        if(clonedNode.components.includes("GltfModel")){
            clonedNode.addComponent("GltfLoadingHandler") as CustomGltfLoadingHandlerComponent;
        }

        clonedNode.name = `${node.name} (Clone)`;

        node.Space.addNode(clonedNode);
        Vertex.Globals.event.fire("hierarchy:nodeAdded", clonedNode);

        return clonedNode;
    }
}
