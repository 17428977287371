import Swal from "sweetalert2";
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { Utils } from "../../../utilities/utils";
import { CustomChangeMaterialComponent } from "./changematerial";
import { CustomGltfLoadingHandlerComponent, IAwaitableComponent } from "./gltfLoadingHandlerComponent";
import { COMPONENTS_UI_PROPERTIES, SYSTEM_ACTION_PREFIX } from "../../../utilities/constants";
import { CustomActionSettingsComponent, ITriggerActionComponent } from "./actionsettingscomponent";
import { ResourceUtils } from "../../../utilities/resource-utilities";
export class CustomModelAlternativeComponent extends AugmentedStoreAssembly.ModelAlternativeComponent implements IAwaitableComponent, ITriggerActionComponent{
    isTriggerActionComponent: boolean = true;

    constructor() {
        super();
    }

    actionSettingsComp: CustomActionSettingsComponent;

    get actionSettings(): CustomActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as CustomActionSettingsComponent;
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    isPerformingOperation: boolean = false;
    isAwaitableComponent: boolean = true;
    funcOnLoad;


    public gltfModelComp: Vertex.NodeComponentModel.GltfModelComponent;
    public gltfLoadingHandlerComp: CustomGltfLoadingHandlerComponent;
    public queuedOperation: () => void;

    async onLoad(): Promise<void> {
        return await this.funcOnLoad();
    }

    public onActionTriggered = ((actionData: AugmentedStoreAssembly.ActionMessage) => {
        // For multiplayer
        // if (this.node.HasToken) {
        //     const actionIndex = this.actionIndexes.indexOf(actionData.actionIndex);
        //     if (actionIndex != -1) {
        //         const alternativeIndex = this.actionValues[actionIndex];
        //         const newModelId = this.modelList[alternativeIndex];
        //         this.enqueueOperation(newModelId);
        //     }
        // }
        // else if (Storage.getIsHost(Vertex.Globals.runtime.space.id) || Storage.getIsPublicMode()) {
        //     let success = await this.node.ensureToken(TokenOp.ModelAlternative, Vertex.NodeComponentModel.TokenHandoffPolicy.Persist);

        //     if (!success) {
        //         console.log(`Host failed to get token to change model.`);
        //         return;
        //     }

        //     const actionIndex = this.actionIndexes.indexOf(actionData.actionIndex);
        //     if (actionIndex != -1) {
        //         const alternativeIndex = this.actionValues[actionIndex];
        //         const newModelId = this.modelList[alternativeIndex];
        //         this.enqueueOperation(newModelId);
        //     }
        //     await this.node.tryReleaseToken(TokenOp.ModelAlternative, Vertex.NodeComponentModel.TokenHandoffPolicy.Persist);
        // } else {
        //     return;
        // }

        const actionIndex = this.actionIndexes.indexOf(actionData.actionIndex);
        if (actionIndex != -1) {
            const alternativeIndex = this.actionValues[actionIndex];
            const newModelId = this.modelList[alternativeIndex];
            this.enqueueOperation(newModelId);
        }
    }).bind(this);

    public enqueueOperation = (async (newModelId: string) => {
        this.gltfLoadingHandlerComp.onBusyLocal.off(this.queuedOperation);
        this.queuedOperation = null;

        if (this.isPerformingOperation) {
            this.queuedOperation = (async () => {
                await this.executeChangeModel(this.gltfLoadingHandlerComp, newModelId);
            }).bind(this);
        }
        else if (this.gltfLoadingHandlerComp.isBusyLocal) {
            this.queuedOperation = (async () => {
                if (this.gltfLoadingHandlerComp.isBusyLocal) {
                    return;
                }
                this.gltfLoadingHandlerComp.onBusyLocal.off(this.queuedOperation);
                await this.executeChangeModel(this.gltfLoadingHandlerComp, newModelId);
            }).bind(this);
            this.gltfLoadingHandlerComp.onBusyLocal.on(this.queuedOperation);
        }
        else {
            await this.executeChangeModel(this.gltfLoadingHandlerComp, newModelId);
        }
    }).bind(this);

    private executeChangeModel = (async (gltfLoadingHandlerComp: CustomGltfLoadingHandlerComponent, newModelId: string) => {

        this.queuedOperation = null;

        if (this.gltfModelComp.id == newModelId) {
            this.completeCurrentOperation(false);
            return;
        }

        this.isPerformingOperation = true;
        gltfLoadingHandlerComp.setBusyLocal(true);
        //await this.node.ensureToken(TokenOp.ModelAlternative, Vertex.NodeComponentModel.TokenHandoffPolicy.Persist);

        // For multiplayer
        // const isNodeSelected = ItemMenuComponentView.selectedNode === this.node;

        // if (isNodeSelected) {
        //     Vertex.Globals.event.fire('editor:clearSelection');
        //     this.gltfLoadingHandlerComp.reset();
        //     this.gltfModelComp.id = newModelId;
        //     this.gltfModelComp.triggerOnChanged();
        //     await this.completeCurrentOperation(true);
        // }
        // else {
        //     this.gltfLoadingHandlerComp.reset();
        //     this.gltfModelComp.id = newModelId;
        //     this.gltfModelComp.triggerOnChanged();
        //     await this.completeCurrentOperation(false);
        // }

        this.gltfLoadingHandlerComp.reset();
        this.gltfModelComp.id = newModelId;
        this.gltfModelComp.triggerOnChanged();
        await this.completeCurrentOperation(false);

    }).bind(this);

    private completeCurrentOperation = (async (isNodeSelectionNeeded: boolean) => {
        let onGltfLoaded = (async (gltfLoadingHandlerComp: CustomGltfLoadingHandlerComponent) => {
            this.gltfLoadingHandlerComp.onLoaded.off(onGltfLoaded);
            await Utils.delay(100);

            if (isNodeSelectionNeeded) {
                Vertex.Globals.event.fire("editor:selectNodeMenu", this.node);
            }

            if (this.queuedOperation != null) {
                this.queuedOperation();
            } else {
                this.isPerformingOperation = false;
                gltfLoadingHandlerComp.setBusyLocal(false);
                // await this.node.tryReleaseToken(TokenOp.ModelAlternative, Vertex.NodeComponentModel.TokenHandoffPolicy.Persist);
            }
        }).bind(this);

        if (this.gltfLoadingHandlerComp.isLoaded) {
            await onGltfLoaded(this.gltfLoadingHandlerComp);
        }
        else {
            this.gltfLoadingHandlerComp.onLoaded.on(onGltfLoaded);
        }
    }).bind(this);

    async getComponentTriggerableValueNames(): Promise<string[]> {
        const names = [];
        
        for (let i = 0; i < this.modelList.length; i++) {
            const res = await ResourceUtils.getResourceData(this.modelList[i]);
            names.push(res.name);
        }

        return names;
    }

}
export class ModelAlternativeComponentView extends Vertex.NodeComponentModel.ComponentViewBase{
    constructor() {
        super();
    }

    incompatibleComponents = ["Skins", "LightMaps", "NavMesh"];
    
    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

        let comp = component as CustomModelAlternativeComponent;

        let isCompatible = Utils.checkComponentsCompatibility("ModelAlternative", node, this.incompatibleComponents);

        if (!isCompatible) {
            node.removeComponent("ModelAlternative");
            //Vertex.Globals.event.fire("editor:selectNode", node);
            return;
        }

        //check if action settings component is present, and wait for it to be ready (during space clone it won't exist)
        Utils.waitForConditionAsync(_ => comp.actionSettings != null, 1000, 10000).then(_ => {
            if(comp.actionSettings){
                comp.actionSettings.isReady.then(_ => {
                    const changeMaterialComp = node.getComponent("ChangeMaterial") as CustomChangeMaterialComponent;
                    if (changeMaterialComp && (changeMaterialComp.actionIndexes.length > 0 || changeMaterialComp.triggerActionIndexes.length > 0)) {
                        let hasActions = false;
            
                        for(let i = 0; i < changeMaterialComp.actionIndexes.length; i++) {
                            if(!comp.actionSettings.actions[changeMaterialComp.actionIndexes[i]]?.actionName.startsWith(SYSTEM_ACTION_PREFIX)){
                                hasActions = true;
                                break;
                            }
                        }
            
                        if(!hasActions){
                            for(let i = 0; i < changeMaterialComp.triggerActionIndexes.length; i++) {
                                if(!comp.actionSettings.actions[changeMaterialComp.triggerActionIndexes[i]]?.actionName.startsWith(SYSTEM_ACTION_PREFIX)){
                                    hasActions = true;
                                    break;
                                }
                            }
                        }
            
                        if(hasActions){
                            Swal.fire({
                                icon: 'warning',
                                title: `Invalid component selection!`,
                                html: `Please remove ${COMPONENTS_UI_PROPERTIES.get("ChangeMaterial").name} actions before adding ModelAlternative`,
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showConfirmButton: true,
                                heightAuto: false
                            });
                            
                            node.removeComponent("ModelAlternative");
                        }
                    }
            
                    let onActionDeleted = (data) => {
                        const index = data.index;
                        const trigger = data.trigger;
            
                        if (comp.actionIndexes.includes(index)) {
                            let actionIndex = comp.actionIndexes.indexOf(index);
                            comp.actionIndexes.splice(actionIndex, 1);
                            comp.actionValues.splice(actionIndex, 1);
                        }
            
                        for(let i = 0; i < comp.actionIndexes.length; i++) {
                            if(comp.actionIndexes[i] > index) {
                                comp.actionIndexes[i] = comp.actionIndexes[i]-1;
                            }
                        }
            
                        comp.triggerActionValues = comp.triggerActionValues.filter((val, idx) => comp.triggerActionIndexes[idx] != index);
                        comp.triggerActionIndexes = comp.triggerActionIndexes.filter(idx => idx != index);
            
                        for(let i = 0; i < comp.triggerActionIndexes.length; i++) {
                            if(comp.triggerActionIndexes[i] > index) {
                                comp.triggerActionIndexes[i] = comp.triggerActionIndexes[i]-1;
                            }
                        }
            
                        if(trigger){
                            comp.triggerOnChanged();
                        }
                    };
                    Vertex.Globals.event.on("ActionSettings:ActionDeleted", onActionDeleted);
                    component.onRemoved.on(() => Vertex.Globals.event.off("ActionSettings:ActionDeleted", onActionDeleted));
                });
            }
        });

        comp.gltfModelComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
                
        if(!comp.modelList.find(x => x === comp.gltfModelComp.id)){
            comp.modelList.push(comp.gltfModelComp.id);
        }

        comp.funcOnLoad = async () => await this.OnLoad(comp, node);
    }

    OnLoad(comp: CustomModelAlternativeComponent, node){
        comp.funcOnLoad = () => { };

        comp.gltfLoadingHandlerComp = comp.node.getComponent("GltfLoadingHandler") as CustomGltfLoadingHandlerComponent;
        if(comp.gltfLoadingHandlerComp.isLoaded){
            this.onLoaded(comp.gltfLoadingHandlerComp);
        }
        else{
            comp.gltfLoadingHandlerComp.onLoaded.on(this.onLoaded);
        }
    }
    
    onLoaded = (async (gltfLoadingHandlerComp: CustomGltfLoadingHandlerComponent) => {
        const comp = gltfLoadingHandlerComp.node.getComponent("ModelAlternative") as CustomModelAlternativeComponent;
        comp.gltfLoadingHandlerComp.onLoaded.off(this.onLoaded);

        comp.actionSettings.onActionTriggered.on(comp.onActionTriggered);
        comp.onRemoved.on(() => comp.actionSettings.onActionTriggered.off(comp.onActionTriggered));
    }).bind(this);

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }
}

export class ModelAlternativeComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomModelAlternativeComponent();
    }

    constructor() {        
        super("ModelAlternative", new ModelAlternativeComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}