import Swal from "sweetalert2";
import { Utils } from "../../../utilities/utils";
import { CustomText2DComponent } from "../NodeComponents/text2dcomponent";
import { ADDITIONAL_COMPONENTS_UI_PROPERTIES, DEFAULT_SWAL_LOADING_MESSAGE } from "../../../utilities/constants";
import { NodeEditorPanelComponentComponentView, NodeEditorPanelComponentSystem } from "./nodeeditorpanel";

export class AdditionalComponentsPanelComponent extends Vertex.NodeComponentModel.Component {

    writeData(writer: Vertex.BinaryWriter): void {
    }

    readData(reader: Vertex.BinaryReader): void {
    }
}

export class AdditionalComponentsPanelComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    container: HTMLDivElement;
    panel: HTMLDivElement;
    list: HTMLUListElement;

    comp: AdditionalComponentsPanelComponent;

    public createAddtionalComponentNode(comp: string, position: number[]){
        return this._createAdditionalCompNode(comp, position);
    }

    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.comp = component as AdditionalComponentsPanelComponent;

        this.container = document.querySelector(".container-overlay-left");
        this.container.classList.remove("hidden");

        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id="additional-components-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text","max");

        let headerIcon = document.createElement("img");
        headerIcon.src = "/img/points-of-interest-icon.svg";
        headerIcon.classList.add("card-header-icon");
        
        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Additional Components";

        let body = document.createElement("div");
        body.classList.add("card-body", "p-0");

        this.panel = document.createElement("div");
        this.panel.classList.add("scene-hierarchy-panel", "h-100");

        this.list = document.createElement("ul") as HTMLUListElement;
        this.list.id = "ac-list";

        this.list.style.margin = "0";
        this.list.style.padding = "0";
        
        // Grid Area

        let leftSidebar = document.querySelector(".left-sidebar-grid");

        // Append
        leftSidebar.appendChild(card);
        card.appendChild(header);
        header.appendChild(headerIcon);
        header.appendChild(headerText);
        // header.appendChild(headerCheckbox);
        card.appendChild(body);
        body.appendChild(this.panel);
        this.panel.appendChild(this.list);
        // Utils.setupSidebarButton CHECKS FOR -panel postfix its own !!!!!!
        const sidebarButton = Utils.setupSidebarButton("AdditionalComponentsPanel", "additional-components-panel");
        this.drawList();
    }
    
    private drawList(){        
        let self = this; 

        while(this.list.hasChildNodes()){
            this.list.removeChild(this.list.firstChild);
        }

        let listCardinality = ADDITIONAL_COMPONENTS_UI_PROPERTIES.keys.length;

        let indx:number=0;

        ADDITIONAL_COMPONENTS_UI_PROPERTIES.forEach((v,k,m)=>{
            let listElement = document.createElement("div");
            listElement.classList.add("scene-list", "list-item", "skybox-holder", "draggable-poi");
            listElement.style.cursor = "default";

            let labelElement = document.createElement("div");
            labelElement.id = `${indx}-ac-name`;
            labelElement.classList.add("input-container");
            labelElement.style.width = "-webkit-fill-available";     
            labelElement.innerText = v;    
      
            let buttonContainer = document.createElement("div");
            buttonContainer.style.display = "flex";
            buttonContainer.style.width = "fit-content";

            let addButton = document.createElement("button");
            addButton.classList.add("btn", "btn-info", "float-right", "btn-sm", "ml-1");
            addButton.draggable = false;
            addButton.addEventListener("click", (event) => {
                event.stopPropagation();

                Swal.fire({
                    title: `Adding node`,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,            
                    heightAuto: true,
                });
        
                Swal.showLoading();

                const addedNode = this._createAdditionalCompNode(k);

                Swal.fire({
                    title: `Added ${addedNode.name}`,
                    icon: 'success',
                    showConfirmButton: true,
                    heightAuto: true
                });

                Vertex.Globals.event.fire("editor:selectNode", addedNode);
            });

            let addIcon = document.createElement("img");
            addIcon.classList.add("node-icon");
            addIcon.src = "/img/add-icon.svg";
            addButton.appendChild(addIcon);
            
            listElement.appendChild(labelElement);
            listElement.appendChild(buttonContainer);
            
            buttonContainer.appendChild(addButton);
    
            this.list.appendChild(listElement);
            indx++;
        });
    }

    private _createAdditionalCompNode(comp: string, position?: number[]){        
        const space = Vertex.Globals.runtime.space as Vertex.Space;
        const count = Utils.getComponentUICount(space.nodes, comp);
        const node = space.createNode(`${Utils.getComponentUIName(comp)} ${count}`);  

        let transformComp = node.addComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
        node.addComponent("NodeLockable");
        node.addComponent("ItemProperties");

        if(`${comp}` == "SavedCO2Visualizer"){
            const text2d: CustomText2DComponent = node.addComponent("Text2D") as CustomText2DComponent; //as Text2DComponent
            transformComp.position = position || [0, 2.5, 0];
            transformComp.triggerOnChanged();
        }
        else if(`${comp}` == "Text2D"){
            transformComp.position = position || [0, 2.5, 0];
            transformComp.triggerOnChanged();
        }
        else if(`${comp}` == "VolumeTrigger"){
            transformComp.position = position || [0, 0, 0];
            transformComp.triggerOnChanged();
        }

        const addedComp = node.addComponent(`${comp}`);
        addedComp.enabled = true;
        
        space.addNode(node);
        Vertex.Globals.event.fire("hierarchy:nodeAdded", node);

        return node;
    }
       
    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}

export class AdditionalComponentsPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AdditionalComponentsPanelComponent();
    }

    constructor() {
        super("AdditionalComponentsPanel", new AdditionalComponentsPanelComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
