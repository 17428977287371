import { isEqual } from "lodash";
import { CustomChangeMaterialComponent } from "../componentsystems/NodeComponents/changematerial";
import { CustomActionsRenderer } from "./customactionsrenderer";
import { CustomActionSettingsComponent } from "../componentsystems/NodeComponents/actionsettingscomponent";
import { COMPONENTS_UI_PROPERTIES } from "../../utilities/constants";
import { CustomTriggerableComponentItemRenderer } from "./customtriggerablecomponentItemrenderer";

export class CustomChangeMaterialInspectorRenderer implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }

    targetNode: Vertex.NodeComponentModel.VertexNode;
    changeMaterialComponent: CustomChangeMaterialComponent;
    property: string;

    panelBody: HTMLDivElement;

    static currentRenderer: CustomChangeMaterialInspectorRenderer;

    actionSettingsComp: CustomActionSettingsComponent;

    get actionSettings(): CustomActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as CustomActionSettingsComponent;
            
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    actionsRenderer: CustomActionsRenderer;
    triggerableComponentItemRenderers: CustomTriggerableComponentItemRenderer[] = [];


    RenderProperty(property: string, target: CustomChangeMaterialComponent): HTMLDivElement {
        this.changeMaterialComponent = target;

        this.panelBody = document.createElement("div");

        if (!target.skinJson) {
            return this.panelBody;
        }


        if (CustomChangeMaterialInspectorRenderer.currentRenderer) {
            Vertex.Globals.event.off("ActionSettings:ActionAdded", CustomChangeMaterialInspectorRenderer.currentRenderer.renderActions);
            Vertex.Globals.event.off("ActionSettings:ActionUpdated", CustomChangeMaterialInspectorRenderer.currentRenderer.renderActions);
            CustomChangeMaterialInspectorRenderer.currentRenderer.changeMaterialComponent.onChanged.off(CustomChangeMaterialInspectorRenderer.currentRenderer.renderActions);
        }



        //to do: anziche creare, aggiornare sempre l'esistente


        this.renderPresetList();

        this.actionsRenderer = new CustomActionsRenderer();
        this.renderActions().then((actionsRendererDivElement) => {
            this.checkComponentsAndDisableActions();
            this.panelBody.appendChild(actionsRendererDivElement);
        });

        //this.onChanged();

        CustomChangeMaterialInspectorRenderer.currentRenderer = this;

        this.changeMaterialComponent.onChanged.on(this.renderActions);
        Vertex.Globals.event.on("ActionSettings:ActionAdded", this.renderActions);
        Vertex.Globals.event.on("ActionSettings:ActionUpdated", this.renderActions);

        Vertex.Globals.event.off("component:componentAdded", this.onAddedComponent);
        Vertex.Globals.event.on("component:componentAdded", this.onAddedComponent);

        Vertex.Globals.event.off("component:componentRemoved", this.onRemovedComponent);
        Vertex.Globals.event.on("component:componentRemoved", this.onRemovedComponent);

        return this.panelBody;
    }

    componentsIncompatibleWithActions = ["ModelAlternative", "Animation"];


    onAddedComponent = ((addedComponent) => {
        if (addedComponent.node == this.targetNode) {
            this.checkComponentsAndDisableActions();
        }
    }).bind(this);

    onRemovedComponent = ((node) => {
        if (node == this.targetNode) {
            this.checkComponentsAndDisableActions();
        }
    }).bind(this);

    checkComponentsAndDisableActions = (() => {
        let incompatibleComponentsNames = this.targetNode.components.filter(compName => this.componentsIncompatibleWithActions.includes(compName));
        let incompatibleComponentsUINames = incompatibleComponentsNames.map(compName => COMPONENTS_UI_PROPERTIES.get(compName).name);

        if (incompatibleComponentsUINames.length > 0) {
            let msg = "";
            if (incompatibleComponentsUINames.length > 1) {
                let componentsNames = incompatibleComponentsUINames.join(",");
                msg = `Please remove ${componentsNames} components to add actions`
            }
            else if (incompatibleComponentsUINames.length == 1) {
                msg = `Please remove ${incompatibleComponentsUINames[0]} component to add actions`
            }
            this.actionsRenderer.SetEnabled(false, msg);
            this.triggerableComponentItemRenderers.forEach(renderer => renderer.SetEnabled(false, msg));
        }
        else {
            this.actionsRenderer.SetEnabled(true);
            this.triggerableComponentItemRenderers.forEach(renderer => renderer.SetEnabled(true));
        }
    }).bind(this);

    renderActions = (async (): Promise<HTMLElement> => {
        let presetsNames = this.changeMaterialComponent.skinJson?.presets.map(preset => preset.name);
        await this.actionSettings.isReady;

        const skinData = this.changeMaterialComponent.skinData;
        const currentPresetIndex = this.changeMaterialComponent.skinJson?.presets?.map((preset) => {
            let isPresent = false;
            const presetSkinData = this.changeMaterialComponent.getSkinDataFromSkinPreset(preset, true);
            for(let i = 0; i < presetSkinData.length; i++) {
                if(presetSkinData[i] == -1) {
                    continue;
                }
                if(presetSkinData[i] != skinData[i]) {
                    isPresent = false;
                    break;
                }
                isPresent = true;
            }//.every(v => skinData.includes(v));
            if(isPresent) {
                return this.changeMaterialComponent.skinJson?.presets?.indexOf(preset);
            }
        });
        
        this.triggerableComponentItemRenderers.forEach((renderer, index) => renderer.RenderItem(currentPresetIndex.includes(index)));

        return this.actionsRenderer.RenderActions(this.changeMaterialComponent, this.actionSettings, presetsNames);
    }).bind(this);

    renderPresetList = (() => {
        const self = this;

        const skinData = this.changeMaterialComponent.skinData;
        const currentPresetIndex = this.changeMaterialComponent.skinJson?.presets?.map((preset) => {
            let isPresent = false;
            const presetSkinData = this.changeMaterialComponent.getSkinDataFromSkinPreset(preset, true);
            for(let i = 0; i < presetSkinData.length; i++) {
                if(presetSkinData[i] == -1) {
                    continue;
                }
                if(presetSkinData[i] != skinData[i]) {
                    isPresent = false;
                    break;
                }
                isPresent = true;
            }//.every(v => skinData.includes(v));
            if(isPresent) {
                return this.changeMaterialComponent.skinJson?.presets?.indexOf(preset);
            }
        });
        const presets = this.changeMaterialComponent.skinJson?.presets;

        this.panelBody.innerText = "";

        this.triggerableComponentItemRenderers = [];

        for (let i = 0; i < presets.length; i++) {
            const preset = presets[i];

            const presetNamePromise = Promise.resolve(preset.name);
            const thumbnailUrlPromise = null;

            const isStarred = currentPresetIndex.includes(i);

            const onClickStar = (() => {
                this.changeMaterialComponent.setSkinPreset(preset);
                return true;
            });

            const triggerableRenderer = new CustomTriggerableComponentItemRenderer(self.changeMaterialComponent, self.actionSettings, i, presetNamePromise, thumbnailUrlPromise, isStarred, onClickStar);
            self.triggerableComponentItemRenderers.push(triggerableRenderer);
            
            const triggerableRendererElement = triggerableRenderer.RenderItem();
            self.panelBody.appendChild(triggerableRendererElement);
        }

    }).bind(this);
}