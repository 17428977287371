import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { Utils } from "../../../utilities/utils";
import { ITriggerActionComponent } from "./actionsettingscomponent";

export interface TaskList {
    tasks: Task[];
    name: string;
    description: string;
    ordered: boolean; //tasks must be completed in order
    time: number; //time to complete
    visible: boolean; //show/hide the task list in app
    events: Event[];
    completed: boolean; //all tasks completed
}

export interface Task {
    steps: Step[];
    name: string;
    description: string;
    ordered: boolean; //steps must be completed in order
    time: number; //time to complete
    events: Event[];
    completed: boolean; //all steps completed
}

export interface Step {
    systemAction: number; // SystemAction index
    name: string;
    description: string;
    events: Event[];
    completed: boolean;
}

export interface Event {
    type: EventType;
    time: number; // Time in seconds
    actions: number[]; // Action indexes
}

export enum EventType {
    //Start,
    End = 0,
    Error = 1,
    Inactive = 2,
}

export const TIMED_EVENTS = [EventType.Inactive]; //Events that occurs after a certain time, if defined

export class CustomTaskListComponent extends AugmentedStoreAssembly.TaskListComponent implements /*IAwaitableComponent,*/ ITriggerActionComponent{
    isTriggerActionComponent: boolean = true;

    constructor() {
        super();
    }

    actionSettingsComp: AugmentedStoreAssembly.ActionSettingsComponent;

    taskList: TaskList = {
        tasks: [],
        name: "",
        description: "",
        ordered: false,
        time: 0,
        visible: false,
        events: [],
        completed: false
    };
    
    isReady: Promise<void>;

    get actionSettings(): AugmentedStoreAssembly.ActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;

        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as AugmentedStoreAssembly.ActionSettingsComponent;
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    public onActionTriggered = ((actionData: AugmentedStoreAssembly.ActionMessage) => {
        const actionIndex = this.actionIndexes.indexOf(actionData.actionIndex);
        
        if (actionIndex !== -1) {
            const state = this.actionValues[actionIndex];

            //perform the action
            
            this.triggerOnChanged();
        }
    }).bind(this);

    /**
     * Validates the data, checking if the number of tasks, steps, and events match the data arrays.
     * Very basic validation atm.. needs to be improved
     * @returns true if the data is valid, false otherwise
     */
    private validateData(): boolean {
        const totalTasks = this.tasks?.length ?? 0;
        let totalTaskSteps = 0;

        if (totalTasks > 0) {
            for (let i = 0; i < this.tasks.length; i++) {
                totalTaskSteps += this.tasks[i];
            }
            
            if (totalTasks !== this.times?.length - 1 ||
                totalTasks !== this.ordered?.length -1 ||
                (totalTasks + totalTaskSteps) !== this.names?.length -1 ||
                (totalTasks + totalTaskSteps) !== this.descriptions?.length -1) {
                return false;
            }
        }

        if (totalTaskSteps !== this.steps?.length ||
            totalTaskSteps !== this.status?.length) {
            return false;
        }

        return true;
    }

    
    validateAndDeserialize() {
        const isDataValid = this.validateData();

        if (isDataValid) {
            this.deserializeData();
        }
        else {
            console.error("Invalid data for TaskListComponent");
        }
    }
    
    /**
     * Serializes the data to be saved in the node
     */
    serializeData() {
        let data = {
            tasks: [],
            steps: [],
            status: [],
            times: [],
            ordered: [],
            names: [],
            descriptions: [],
            visible: this.taskList.visible,
            triggerActionMap: [],
            triggerActionTimes: [],
            triggerActionValues: [],
            triggerActionIndexes: []
        };
        
        for (let i = 0; i < this.taskList.tasks.length; i++) {
            const task = this.taskList.tasks[i];

            data.tasks.push(task.steps.length);
            data.names.push(task.name);
            data.descriptions.push(task.description);
            data.ordered.push(task.ordered);
            data.times.push(task.time);
            
            //map task event triggers
            data.triggerActionMap.push(0);

            if (task.events.length) {
                for (let j = 0; j < task.events.length; j++) {
                    const event = task.events[j];

                    for(const action of event.actions) {
                        data.triggerActionMap[data.triggerActionMap.length-1]++;
    
                        data.triggerActionTimes.push(event.time);
                        data.triggerActionValues.push(event.type);
                        data.triggerActionIndexes.push(action);
                    }
                }
            }

            for (let j = 0; j < task.steps.length; j++) {
                const step = task.steps[j];

                data.steps.push(step.systemAction);
                data.names.push(step.name);
                data.descriptions.push(step.description);
                data.status.push(step.completed);

                //map step event triggers
                data.triggerActionMap.push(0);

                if (step.events.length) {
                    for (let k = 0; k < step.events.length; k++) {
                        const event = step.events[k];

                        for(const action of event.actions) {
                            data.triggerActionMap[data.triggerActionMap.length-1]++;
        
                            data.triggerActionTimes.push(event.time);
                            data.triggerActionValues.push(event.type);
                            data.triggerActionIndexes.push(action);
                        }
                    }
                }
            }
        }

        //map tasklist event triggers
        data.triggerActionMap.push(0);
        
        if (this.taskList.events.length) {
            for (let i = 0; i < this.taskList.events.length; i++) {
                const event = this.taskList.events[i];

                for(const action of event.actions) {
                    data.triggerActionMap[data.triggerActionMap.length-1]++;

                    data.triggerActionTimes.push(event.time);
                    data.triggerActionValues.push(event.type);
                    data.triggerActionIndexes.push(action);
                }
            }
        }

        data.names.push(this.taskList.name);
        data.descriptions.push(this.taskList.description);
        data.ordered.push(this.taskList.ordered);
        data.times.push(this.taskList.time);
        
        this.tasks = data.tasks;
        this.steps = data.steps;
        this.status = data.status;
        this.times = data.times;
        this.ordered = data.ordered;
        this.names = data.names;
        this.descriptions = data.descriptions;
        this.visible = data.visible;
        this.triggerActionTimes = data.triggerActionTimes;
        this.triggerActionMap = data.triggerActionMap;
        this.triggerActionValues = data.triggerActionValues;
        this.triggerActionIndexes = data.triggerActionIndexes;
    }

    /**
     * Deserializes the data from the node
     */
    deserializeData() {
        this.taskList = {
            tasks: [],
            name: this.names[this.names.length - 1] ?? "",
            description: this.descriptions[this.descriptions.length - 1] ?? "",
            ordered: this.ordered[this.ordered.length - 1] ?? false,
            time: this.times[this.times.length - 1] ?? 0,
            visible: this.visible,
            events: [],
            completed: false
        };

        let currentTriggerIndex = 0;
        let currentStep = 0;

        this.taskList.tasks = [];

        for (let i = 0; i < this.tasks.length; i++) {
            this.taskList.tasks.push(
                {
                    name: this.names[i + currentStep] ?? "",
                    description: this.descriptions[i + currentStep] ?? "",
                    ordered: this.ordered[i] ?? false,
                    time: this.times[i] ?? 0,
                    events: [],
                    completed: false,
                    steps: []
                }
            );

            const stepsCount = this.tasks[i];

            for (let j = currentStep; j < (currentStep + stepsCount); j++) {
                this.taskList.tasks[i].steps.push(
                    {
                        systemAction: this.steps[j],
                        name: this.names[i + j + 1] ?? "",
                        description: this.descriptions[i + j + 1] ?? "",
                        events: [],
                        completed: this.status[j]
                    }
                );
            }

            if (this.taskList.tasks[i].steps.every(step => step.completed)) {
                this.taskList.tasks[i].completed = true;
            }

            currentStep += stepsCount;
        }

        //add tasks events
        //we have events for each task and its steps
        let totalStepsCount = 0;

        for (let i = 0; i < this.taskList.tasks.length; i++) {
            const taskEventsCount = this.triggerActionMap[i + totalStepsCount];

            for (let j = currentTriggerIndex; j < (currentTriggerIndex + taskEventsCount); j++) {
                const sameTypeIndex = this.taskList.tasks[i].events.findIndex(e => e.type == this.triggerActionValues[j]);

                if (sameTypeIndex != -1) { //event type already exists, just add the action
                    this.taskList.tasks[i].events[sameTypeIndex].actions.push(this.triggerActionIndexes[j]);
                }
                else { //new event type
                    this.taskList.tasks[i].events.push(
                        {
                            type: this.triggerActionValues[j],
                            time: this.triggerActionTimes[j],
                            actions: [this.triggerActionIndexes[j]]
                        }
                    );
                }
            }

            currentTriggerIndex += taskEventsCount;

            //add events to steps
            const taskStepsCount = this.taskList.tasks[i].steps.length;

            for (let j = 0; j < taskStepsCount; j++) {
                const stepEventsCount = this.triggerActionMap[(i + 1) + totalStepsCount + j]; // i for the tasks, stepsCount for the previous steps, j for the current task step

                // Iterate through the events and add them to the steps
                for (let k = currentTriggerIndex; k < (currentTriggerIndex + stepEventsCount); k++) {
                    const sameTypeIndex = this.taskList.tasks[i].steps[j].events.findIndex(e => e.type == this.triggerActionValues[k]);

                    if (sameTypeIndex != -1) { //event type already exists, just add the action
                        this.taskList.tasks[i].steps[j].events[sameTypeIndex].actions.push(this.triggerActionIndexes[k]);
                    }
                    else { //new event type
                        this.taskList.tasks[i].steps[j].events.push(
                            {
                                type: this.triggerActionValues[k],
                                time: this.triggerActionTimes[k],
                                actions: [this.triggerActionIndexes[k]]
                            }
                        );
                    }
                }

                currentTriggerIndex += stepEventsCount;
            }

            totalStepsCount += taskStepsCount;
        }

        //add events to tasklist        
        for (let i = 0; i < this.triggerActionMap[this.triggerActionMap.length-1]; i++) { //last element is the number of events for the task list
            const sameTypeIndex = this.taskList.events.findIndex(e => e.type == this.triggerActionValues[i + currentTriggerIndex]);

            if (sameTypeIndex != -1) {  //event type already exists, just add the action
                this.taskList.events[sameTypeIndex].actions.push(this.triggerActionIndexes[i + currentTriggerIndex]);
            }
            else { //new event type
                this.taskList.events.push(
                    {
                        type: this.triggerActionValues[i + currentTriggerIndex],
                        time: this.triggerActionTimes[i + currentTriggerIndex],
                        actions: [this.triggerActionIndexes[i + currentTriggerIndex]]
                    }
                );
            }
        }
    }

    async getComponentTriggerableValueNames(): Promise<string[]> {
        //this won't be called for now
        return [];
    }

    onActionDeleted = ((data) => {
        const index = data.index;
        const trigger = data.trigger;

        //let's check if the deleted action was linked to a step
        const stepIndex = this.steps.indexOf(index);

        if (stepIndex !== -1) {
            this.steps[stepIndex] = -1;
        }
        
        // check if deleted action was in a trigger event and remove it
        this.triggerActionValues = this.triggerActionValues.filter((val, idx) => this.triggerActionIndexes[idx] != index);
        this.triggerActionIndexes = this.triggerActionIndexes.filter(idx => idx != index);

        for(let i = 0; i < this.triggerActionIndexes.length; i++) {
            if(this.triggerActionIndexes[i] > index) {
                this.triggerActionIndexes[i] = this.triggerActionIndexes[i]-1;
            }
        }
        
        if(trigger) {
            this.triggerOnChanged();
        }
    }).bind(this);
}

export class TaskListComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    comp: CustomTaskListComponent;

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.comp = component as CustomTaskListComponent;

        this.comp.isReady = new Promise<void>(async (resolve, reject) => {
            this.comp.validateAndDeserialize();

            resolve();
        });
        
        Utils.waitForCondition(_ => Array.from<Vertex.NodeComponentModel.VertexNode>(Vertex.Globals.runtime.space.nodes.values()).find((node) => node.components.includes("ActionSettings")) != null).then(_ => {
            this.comp.actionSettings.onActionTriggered.on(this.comp.onActionTriggered);
            this.comp.onRemoved.on(() => this.comp.actionSettings.onActionTriggered.off(this.comp.onActionTriggered));

            let onActionDeleted = (data) => {
                const index = data.index;
                const trigger = data.trigger;
                
                //it should never happen because here we use the system-actions
                //it happens instead, when you change a step value previously set
                if (this.comp.actionIndexes.includes(index)) {
                    let actionIndex = this.comp.actionIndexes.indexOf(index);
                    this.comp.actionIndexes.splice(actionIndex, 1);
                    this.comp.actionValues.splice(actionIndex, 1);
                }

                for(let i = 0; i < this.comp.actionIndexes.length; i++) {
                    if(this.comp.actionIndexes[i] > index) {
                        this.comp.actionIndexes[i] = this.comp.actionIndexes[i]-1;
                    }
                }

                this.comp.triggerActionValues = this.comp.triggerActionValues.filter((val, idx) => this.comp.triggerActionIndexes[idx] != index);
                this.comp.triggerActionIndexes = this.comp.triggerActionIndexes.filter(idx => idx != index);

                for(let i = 0; i < this.comp.triggerActionIndexes.length; i++) {
                    if(this.comp.triggerActionIndexes[i] > index) {
                        this.comp.triggerActionIndexes[i] = this.comp.triggerActionIndexes[i]-1;
                    }
                }

                for(let i = 0; i < this.comp.steps.length; i++) {
                    if(this.comp.steps[i] > index) {
                        this.comp.steps[i] = this.comp.steps[i] - 1;
                    }
                }

                if(trigger) {
                    this.comp.triggerOnChanged();
                }
            };

            Vertex.Globals.event.on("ActionSettings:ActionDeleted", onActionDeleted);

            component.onRemoved.on(() => Vertex.Globals.event.off("ActionSettings:ActionDeleted", onActionDeleted));
        });
    }


    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class TaskListComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomTaskListComponent();
    }

    constructor() {        
        super("TaskList", new TaskListComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
