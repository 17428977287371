//export class PointLightComponent extends Vertex.NodeComponentModel.Component {

import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";


export class PointLightComponentView extends Vertex.NodeComponentModel.ComponentViewBase {


    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        var comp = component as AugmentedStoreAssembly.PointLightComponent;

        var scene = node.viewNode.getScene();
        var light = new BABYLON.PointLight("pointLight", BABYLON.Vector3.Zero(), scene);
        light.parent = node.viewNode;

       
        var sphere = BABYLON.MeshBuilder.CreateSphere("Name", { diameter: .05 }, scene);
        sphere.parent = node.viewNode;

        component.onChanged.on(() => {
            light.range = comp.range;
            light.intensity = comp.intensity;
            light.radius = comp.radius;
            light.diffuse.r = comp.diffuseR;
            light.diffuse.g = comp.diffuseG;
            light.diffuse.b = comp.diffuseB;
            light.specular.r = comp.diffuseR;
            light.specular.g = comp.diffuseG;
            light.specular.b = comp.diffuseB;
        });


        node.event.on("Lighting:DiffuseChanged", (val: string) => {
            comp.diffuseR = BABYLON.Color3.FromHexString(val).r;
            comp.diffuseG = BABYLON.Color3.FromHexString(val).g;
            comp.diffuseB = BABYLON.Color3.FromHexString(val).b;
            comp.triggerOnChanged();
        });
        node.event.on("Lighting:SpecularChanged", (val: string) => {
            comp.specularR = BABYLON.Color3.FromHexString(val).r;
            comp.specularG = BABYLON.Color3.FromHexString(val).g;
            comp.specularB = BABYLON.Color3.FromHexString(val).b;
            comp.triggerOnChanged();
        });

        node.event.on("Lighting:IntensityChanged", (val: number) => { comp.intensity = val; comp.triggerOnChanged(); });
        node.event.on("Lighting:RangeChanged", (val: number) => { comp.range = val; comp.triggerOnChanged(); });
        node.event.on("Lighting:RadiusChanged", (val: number) => { comp.radius = val; comp.triggerOnChanged(); });
        comp.triggerOnChanged();
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }

    update(): void {
    }
}

export class PointLightComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.PointLightComponent();
    }

    constructor() {
        super("PointLight", new PointLightComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
        Vertex.Globals.event.on("Vertx:CreatePointLight", ()=>{
            
            let space = Vertex.Globals.runtime.space as Vertex.Space;

            let lightNode = space.createNode("Point Light");
            lightNode.addComponent("NodeLockable");
            space.addNode(lightNode);
            Vertex.Globals.event.fire("hierarchy:nodeAdded", lightNode);

            lightNode.addComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;

            let lightComp = new AugmentedStoreAssembly.PointLightComponent;
            lightComp.diffuseR = lightComp.diffuseG = lightComp.diffuseB = lightComp.specularR = lightComp.specularG = lightComp.specularB = 1;
            lightComp.range = 1;
            lightComp.intensity = 1;
            lightComp.radius = 0.0001;
            lightNode.addComponent("PointLight", lightComp);
        })
    }
}
