import { difference } from "lodash";
import Swal from "sweetalert2";
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { HevolusApp, isMajorGte } from "../../../utilities/versioning";
import { ResourceUtils } from "../../../utilities/resource-utilities";
import { SERIALIZED_SPACE_FILENAME, SPACE_EDITOR_VERSION_PREFIX_TAG, SYSTEM_RESOURCE_FILENAMES } from "../../../utilities/constants";

export interface IValidable {
    isValidableComponent: boolean;
    getUsedFiles(): Promise<string[]>;
    removeMissingFile(fileName : string) : Promise<void>;
}

export class SceneValidatorComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }
}

export class SceneValidatorComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    static isValidated: boolean = false;

    instanceOfIValidableComponent(object: any): object is IValidable {
        return object && object.isValidableComponent;
    }

    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const spaceResource = await ResourceUtils.getResourceData(Vertex.Globals.spaceId);
        const spaceEditorVersionTag = spaceResource?.tags?.find(t => t.startsWith(SPACE_EDITOR_VERSION_PREFIX_TAG));
        
        if(spaceEditorVersionTag == null){
            console.log(`Validation skipped because of missing Space Editor version info.`);

            return;
        }

        let isValid = await isMajorGte(spaceEditorVersionTag, HevolusApp.SpaceEditor);

        if(!isValid){
            console.log(`Validation skipped because of Space Editor version incompatibility.`);

            return;
        }

        Swal.fire({
            icon: "info",
            title: "Validating Space...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            heightAuto: false
        });

        Swal.showLoading();

        //Retrieve space files used by components
        let usedFiles: string[] = [SERIALIZED_SPACE_FILENAME];
        const vertexRuntime = Vertex.Globals.runtime as Vertex.VertexRuntime;

        let hasInvalidNodes = false;
        let hasUpdatedComponents = false;
        let hasMissingFiles = false;
        
        for(let node of vertexRuntime.space.nodes.values()) {
            //check if entity resources are still valid
            if(node.components.includes("GltfModel") || node.components.includes("MediaTexture")){
                let resId = "";
                let resource = null;
    
                if(node.components.includes("GltfModel")){
                    let gltfModel = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
                    resId = gltfModel.Id;    
                }
                else if(node.components.includes("MediaTexture")){
                    let mediaTexture = node.getComponent("MediaTexture") as AugmentedStoreAssembly.MediaTextureComponent;
                    resId = mediaTexture.id;
                }
    
                if(resId !== ""){
                    resource = await ResourceUtils.getResourceData(resId);
                    
                    if(resource == null){
                        Vertex.Globals.runtime.space.destroyNode(node);	//Destroy node if resource is not found
                        hasInvalidNodes = true;
                        continue;
                    }

                    if(node.components.includes("ModelAlternative")){
                        const comp = node.getComponent("ModelAlternative") as AugmentedStoreAssembly.ModelAlternativeComponent;
        
                        if(comp){
                            for(let i = 0; i < comp.modelList.length; i++){
                                const alternative = comp.modelList[i];
                                const altResource = await ResourceUtils.getResourceData(alternative);
        
                                if(altResource == null){
                                    hasUpdatedComponents = true;

                                    comp.modelList.splice(i, 1);
                                    
                                    const listenerIndex = comp.actionValues.findIndex(v => v == i);

                                    if(listenerIndex !== -1){
                                        comp.actionValues.splice(listenerIndex, 1);
                                        comp.actionIndexes.splice(listenerIndex, 1);
                                    }

                                    const triggers = comp.triggerActionValues.filter(v => v == i);

                                    for(let trigger of triggers){
                                        const triggerIndex = comp.triggerActionValues.indexOf(trigger);
                                        comp.triggerActionValues.splice(triggerIndex, 1);
                                        comp.triggerActionIndexes.splice(triggerIndex, 1);
                                    }

                                    i--;
                                }
                            }
                        }
                    }
                    
                }
                
            }

            for (let i = 0; i < node.components.length; i++) {
                let component = node.getComponent(node.components[i]);

                if (this.instanceOfIValidableComponent(component)) {
                    usedFiles.push(...await component.getUsedFiles());
                }
            }
        }

        const resource = await ResourceUtils.getResourceData(Vertex.Globals.spaceId);

        //Find and delete unused scene files
        if (resource) {
            const resFiles = resource.resourceKeys || [];
            let unusedFiles = difference(resFiles, usedFiles).filter(file => file && !SYSTEM_RESOURCE_FILENAMES.includes(file) && !file.endsWith(".json"));
            let missingFiles = difference(usedFiles, resFiles).filter(file => file && !SYSTEM_RESOURCE_FILENAMES.includes(file) && !file.endsWith(".json"));
            
            unusedFiles.forEach(async (file) => {
                try {
                    await ResourceUtils.deleteAssetFromResource(Vertex.Globals.spaceId, file);
                } catch (error) {
                    console.log(error);
                }
            });

            if(missingFiles.length > 0){
                hasMissingFiles = true;

                for(let node of vertexRuntime.space.nodes.values()) {
                    for(let componentName of node.components){
                        let component = node.getComponent(componentName);
                        
                        if (this.instanceOfIValidableComponent(component)) {
                            for(let missingFile of missingFiles){
                                await component.removeMissingFile(missingFile);
                            }
                        }
                    }
                }
            }
        }

        if(hasInvalidNodes || hasUpdatedComponents || hasMissingFiles){
            await Swal.fire({
                icon: "success",
                title: "Space issues have been resolved!",
                html: `Save the Space to apply the changes.`,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                footer: `Issues may be represented by missing files or invalid Nodes or Components due to the deletion or corruption of a Resource (e.g. a Model or a Media).`,
                heightAuto: false
            });
        }

        SceneValidatorComponentView.isValidated = true;

        if(Swal.isVisible()){
            Swal.close();
        }

        Vertex.Globals.event.fire("editor:space-loaded", node);
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}

export class SceneValidatorComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new SceneValidatorComponent();
    }

    constructor() {

        super("SceneValidator", new SceneValidatorComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
