// [VERTEX RUNTIME] This file is auto generated from a CodeSchema.
// DO NOT EDIT!
export module AugmentedStoreAssembly {
    export class SerializedNodesBindingComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.serializedNodeId = [];
            this.deserializedNodeId = [];
        }
        serializedNodeId: string[];
        deserializedNodeId: string[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[SerializedNodesBindingComponent]", this);
            for (var i = 0; i < this.serializedNodeId.length; ++i)
                writer.writeString(this.serializedNodeId[i]);

            for (var i = 0; i < this.deserializedNodeId.length; ++i)
                writer.writeString(this.deserializedNodeId[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[SerializedNodesBindingComponent]", this);
            for (var i = 0; i < this.serializedNodeId.length; ++i)
                this.serializedNodeId[i] = reader.readString();

            for (var i = 0; i < this.deserializedNodeId.length; ++i)
                this.deserializedNodeId[i] = reader.readString();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "serializedNodeId" && type === Vertex.BsonType.Array){
                reader.readArray(this, "serializedNodeId", reader.readString);
                return;
            }

            if (name === "deserializedNodeId" && type === Vertex.BsonType.Array){
                reader.readArray(this, "deserializedNodeId", reader.readString);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeArray("serializedNodeId", this.serializedNodeId, writer.writeString);
            writer.writeArray("deserializedNodeId", this.deserializedNodeId, writer.writeString);
            writer.endObject(ctx);
        }
    }
    export class UserComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.userId = "";
        }
        userId: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[UserComponent]", this);
            writer.writeString(this.userId);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[UserComponent]", this);
            this.userId = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "userId"){
                reader.readString(this, "userId");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("userId", this.userId);
            writer.endObject(ctx);
        }
    }
    export class UserInfoComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.userId = "";
            this.userName = "";
            this.isHost = false;
            this.latitude = 0;
            this.longitude = 0;
            this.distanceFromHost = 0;
        }
        userId: string;
        userName: string;
        isHost: boolean;
        latitude: number;
        longitude: number;
        distanceFromHost: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[UserInfoComponent]", this);
            writer.writeString(this.userId);
            writer.writeString(this.userName);
            writer.writeBool(this.isHost);
            writer.writeDouble(this.latitude);
            writer.writeDouble(this.longitude);
            writer.writeDouble(this.distanceFromHost);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[UserInfoComponent]", this);
            this.userId = reader.readString();
            this.userName = reader.readString();
            this.isHost = reader.readBool();
            this.latitude = reader.readDouble();
            this.longitude = reader.readDouble();
            this.distanceFromHost = reader.readDouble();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "userId"){
                reader.readString(this, "userId");
                return;
            }

            if (name === "userName"){
                reader.readString(this, "userName");
                return;
            }

            if (name === "isHost"){
                reader.readBoolean(this, "isHost");
                return;
            }

            if (name === "latitude"){
                reader.readDouble(this, "latitude");
                return;
            }

            if (name === "longitude"){
                reader.readDouble(this, "longitude");
                return;
            }

            if (name === "distanceFromHost"){
                reader.readDouble(this, "distanceFromHost");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("userId", this.userId);
            writer.writeString("userName", this.userName);
            writer.writeBoolean("isHost", this.isHost);
            writer.writeDouble("latitude", this.latitude);
            writer.writeDouble("longitude", this.longitude);
            writer.writeDouble("distanceFromHost", this.distanceFromHost);
            writer.endObject(ctx);
        }
    }
    export class WishlistComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.resourceIds = [];
            this.configurations = [];
        }
        resourceIds: string[];
        configurations: string[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[WishlistComponent]", this);
            for (var i = 0; i < this.resourceIds.length; ++i)
                writer.writeString(this.resourceIds[i]);

            for (var i = 0; i < this.configurations.length; ++i)
                writer.writeString(this.configurations[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[WishlistComponent]", this);
            for (var i = 0; i < this.resourceIds.length; ++i)
                this.resourceIds[i] = reader.readString();

            for (var i = 0; i < this.configurations.length; ++i)
                this.configurations[i] = reader.readString();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "resourceIds" && type === Vertex.BsonType.Array){
                reader.readArray(this, "resourceIds", reader.readString);
                return;
            }

            if (name === "configurations" && type === Vertex.BsonType.Array){
                reader.readArray(this, "configurations", reader.readString);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeArray("resourceIds", this.resourceIds, writer.writeString);
            writer.writeArray("configurations", this.configurations, writer.writeString);
            writer.endObject(ctx);
        }
    }
    export class CallToActionComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[CallToActionComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[CallToActionComponent]", this);
        }
    }
    export class NodeTogglerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.enabled = false;
            this.actionIndexes = [];
            this.actionValues = [];
            this.triggerActionIndexes = [];
            this.triggerActionValues = [];
        }
        declare enabled: boolean;
        actionIndexes: number[];
        actionValues: number[];
        triggerActionIndexes: number[];
        triggerActionValues: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[NodeTogglerComponent]", this);
            writer.writeBool(this.enabled);
            for (var i = 0; i < this.actionIndexes.length; ++i)
                writer.writeInt32(this.actionIndexes[i]);

            for (var i = 0; i < this.actionValues.length; ++i)
                writer.writeInt32(this.actionValues[i]);

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                writer.writeInt32(this.triggerActionIndexes[i]);

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                writer.writeInt32(this.triggerActionValues[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[NodeTogglerComponent]", this);
            this.enabled = reader.readBool();
            for (var i = 0; i < this.actionIndexes.length; ++i)
                this.actionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.actionValues.length; ++i)
                this.actionValues[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                this.triggerActionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                this.triggerActionValues[i] = reader.readInt32();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "enabled"){
                reader.readBoolean(this, "enabled");
                return;
            }

            if (name === "actionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionIndexes", reader.readInt32);
                return;
            }

            if (name === "actionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionValues", reader.readInt32);
                return;
            }

            if (name === "triggerActionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionIndexes", reader.readInt32);
                return;
            }

            if (name === "triggerActionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionValues", reader.readInt32);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("enabled", this.enabled);
            writer.writeArray("actionIndexes", this.actionIndexes, writer.writeInt32);
            writer.writeArray("actionValues", this.actionValues, writer.writeInt32);
            writer.writeArray("triggerActionIndexes", this.triggerActionIndexes, writer.writeInt32);
            writer.writeArray("triggerActionValues", this.triggerActionValues, writer.writeInt32);
            writer.endObject(ctx);
        }
    }
    export class VolumeTriggerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.enabled = false;
            this.actionIndexes = [];
            this.actionValues = [];
            this.triggerActionIndexes = [];
            this.triggerActionValues = [];
        }
        declare enabled: boolean;
        actionIndexes: number[];
        actionValues: number[];
        triggerActionIndexes: number[];
        triggerActionValues: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[VolumeTriggerComponent]", this);
            writer.writeBool(this.enabled);
            for (var i = 0; i < this.actionIndexes.length; ++i)
                writer.writeInt32(this.actionIndexes[i]);

            for (var i = 0; i < this.actionValues.length; ++i)
                writer.writeInt32(this.actionValues[i]);

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                writer.writeInt32(this.triggerActionIndexes[i]);

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                writer.writeInt32(this.triggerActionValues[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[VolumeTriggerComponent]", this);
            this.enabled = reader.readBool();
            for (var i = 0; i < this.actionIndexes.length; ++i)
                this.actionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.actionValues.length; ++i)
                this.actionValues[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                this.triggerActionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                this.triggerActionValues[i] = reader.readInt32();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "enabled"){
                reader.readBoolean(this, "enabled");
                return;
            }

            if (name === "actionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionIndexes", reader.readInt32);
                return;
            }

            if (name === "actionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionValues", reader.readInt32);
                return;
            }

            if (name === "triggerActionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionIndexes", reader.readInt32);
                return;
            }

            if (name === "triggerActionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionValues", reader.readInt32);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("enabled", this.enabled);
            writer.writeArray("actionIndexes", this.actionIndexes, writer.writeInt32);
            writer.writeArray("actionValues", this.actionValues, writer.writeInt32);
            writer.writeArray("triggerActionIndexes", this.triggerActionIndexes, writer.writeInt32);
            writer.writeArray("triggerActionValues", this.triggerActionValues, writer.writeInt32);
            writer.endObject(ctx);
        }
    }
    export class TaskListComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.tasks = [];
            this.steps = [];
            this.status = [];
            this.times = [];
            this.ordered = [];
            this.names = [];
            this.descriptions = [];
            this.visible = false;
            this.actionIndexes = [];
            this.actionValues = [];
            this.triggerActionMap = [];
            this.triggerActionTimes = [];
            this.triggerActionIndexes = [];
            this.triggerActionValues = [];
        }
        tasks: number[];
        steps: number[];
        status: boolean[];
        times: number[];
        ordered: boolean[];
        names: string[];
        descriptions: string[];
        visible: boolean;
        actionIndexes: number[];
        actionValues: number[];
        triggerActionMap: number[];
        triggerActionTimes: number[];
        triggerActionIndexes: number[];
        triggerActionValues: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[TaskListComponent]", this);
            for (var i = 0; i < this.tasks.length; ++i)
                writer.writeInt32(this.tasks[i]);

            for (var i = 0; i < this.steps.length; ++i)
                writer.writeInt32(this.steps[i]);

            for (var i = 0; i < this.status.length; ++i)
                writer.writeBool(this.status[i]);

            for (var i = 0; i < this.times.length; ++i)
                writer.writeInt32(this.times[i]);

            for (var i = 0; i < this.ordered.length; ++i)
                writer.writeBool(this.ordered[i]);

            for (var i = 0; i < this.names.length; ++i)
                writer.writeString(this.names[i]);

            for (var i = 0; i < this.descriptions.length; ++i)
                writer.writeString(this.descriptions[i]);

            writer.writeBool(this.visible);
            for (var i = 0; i < this.actionIndexes.length; ++i)
                writer.writeInt32(this.actionIndexes[i]);

            for (var i = 0; i < this.actionValues.length; ++i)
                writer.writeInt32(this.actionValues[i]);

            for (var i = 0; i < this.triggerActionMap.length; ++i)
                writer.writeInt32(this.triggerActionMap[i]);

            for (var i = 0; i < this.triggerActionTimes.length; ++i)
                writer.writeInt32(this.triggerActionTimes[i]);

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                writer.writeInt32(this.triggerActionIndexes[i]);

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                writer.writeInt32(this.triggerActionValues[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[TaskListComponent]", this);
            for (var i = 0; i < this.tasks.length; ++i)
                this.tasks[i] = reader.readInt32();

            for (var i = 0; i < this.steps.length; ++i)
                this.steps[i] = reader.readInt32();

            for (var i = 0; i < this.status.length; ++i)
                this.status[i] = reader.readBool();

            for (var i = 0; i < this.times.length; ++i)
                this.times[i] = reader.readInt32();

            for (var i = 0; i < this.ordered.length; ++i)
                this.ordered[i] = reader.readBool();

            for (var i = 0; i < this.names.length; ++i)
                this.names[i] = reader.readString();

            for (var i = 0; i < this.descriptions.length; ++i)
                this.descriptions[i] = reader.readString();

            this.visible = reader.readBool();
            for (var i = 0; i < this.actionIndexes.length; ++i)
                this.actionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.actionValues.length; ++i)
                this.actionValues[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionMap.length; ++i)
                this.triggerActionMap[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionTimes.length; ++i)
                this.triggerActionTimes[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                this.triggerActionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                this.triggerActionValues[i] = reader.readInt32();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "tasks" && type === Vertex.BsonType.Array){
                reader.readArray(this, "tasks", reader.readInt32);
                return;
            }

            if (name === "steps" && type === Vertex.BsonType.Array){
                reader.readArray(this, "steps", reader.readInt32);
                return;
            }

            if (name === "status" && type === Vertex.BsonType.Array){
                reader.readArray(this, "status", reader.readBoolean);
                return;
            }

            if (name === "times" && type === Vertex.BsonType.Array){
                reader.readArray(this, "times", reader.readInt32);
                return;
            }

            if (name === "ordered" && type === Vertex.BsonType.Array){
                reader.readArray(this, "ordered", reader.readBoolean);
                return;
            }

            if (name === "names" && type === Vertex.BsonType.Array){
                reader.readArray(this, "names", reader.readString);
                return;
            }

            if (name === "descriptions" && type === Vertex.BsonType.Array){
                reader.readArray(this, "descriptions", reader.readString);
                return;
            }

            if (name === "visible"){
                reader.readBoolean(this, "visible");
                return;
            }

            if (name === "actionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionIndexes", reader.readInt32);
                return;
            }

            if (name === "actionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionValues", reader.readInt32);
                return;
            }

            if (name === "triggerActionMap" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionMap", reader.readInt32);
                return;
            }

            if (name === "triggerActionTimes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionTimes", reader.readInt32);
                return;
            }

            if (name === "triggerActionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionIndexes", reader.readInt32);
                return;
            }

            if (name === "triggerActionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionValues", reader.readInt32);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeArray("tasks", this.tasks, writer.writeInt32);
            writer.writeArray("steps", this.steps, writer.writeInt32);
            writer.writeArray("status", this.status, writer.writeBoolean);
            writer.writeArray("times", this.times, writer.writeInt32);
            writer.writeArray("ordered", this.ordered, writer.writeBoolean);
            writer.writeArray("names", this.names, writer.writeString);
            writer.writeArray("descriptions", this.descriptions, writer.writeString);
            writer.writeBoolean("visible", this.visible);
            writer.writeArray("actionIndexes", this.actionIndexes, writer.writeInt32);
            writer.writeArray("actionValues", this.actionValues, writer.writeInt32);
            writer.writeArray("triggerActionMap", this.triggerActionMap, writer.writeInt32);
            writer.writeArray("triggerActionTimes", this.triggerActionTimes, writer.writeInt32);
            writer.writeArray("triggerActionIndexes", this.triggerActionIndexes, writer.writeInt32);
            writer.writeArray("triggerActionValues", this.triggerActionValues, writer.writeInt32);
            writer.endObject(ctx);
        }
    }
    export class TShirtComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[TShirtComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[TShirtComponent]", this);
        }
    }
    export class TenantInfoComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.tenantInfoResourceId = "";
        }
        tenantInfoResourceId: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[TenantInfoComponent]", this);
            writer.writeString(this.tenantInfoResourceId);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[TenantInfoComponent]", this);
            this.tenantInfoResourceId = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "tenantInfoResourceId"){
                reader.readString(this, "tenantInfoResourceId");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("tenantInfoResourceId", this.tenantInfoResourceId);
            writer.endObject(ctx);
        }
    }
    export class SpawnPointComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[SpawnPointComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[SpawnPointComponent]", this);
        }
    }
    export class LightMapsHandlerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[LightMapsHandlerComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[LightMapsHandlerComponent]", this);
        }
    }
    export class SummonerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.summonerId = "";
            this.summonedIDs = [];
        }
        summonerId: string;
        summonedIDs: string[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[SummonerComponent]", this);
            writer.writeString(this.summonerId);
            for (var i = 0; i < this.summonedIDs.length; ++i)
                writer.writeString(this.summonedIDs[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[SummonerComponent]", this);
            this.summonerId = reader.readString();
            for (var i = 0; i < this.summonedIDs.length; ++i)
                this.summonedIDs[i] = reader.readString();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "summonerId"){
                reader.readString(this, "summonerId");
                return;
            }

            if (name === "summonedIDs" && type === Vertex.BsonType.Array){
                reader.readArray(this, "summonedIDs", reader.readString);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("summonerId", this.summonerId);
            writer.writeArray("summonedIDs", this.summonedIDs, writer.writeString);
            writer.endObject(ctx);
        }
    }
    export class RotationComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.speed = 0;
            this.isClockwise = false;
            this.count = 0;
        }
        speed: number;
        isClockwise: boolean;
        count: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[RotationComponent]", this);
            writer.writeDouble(this.speed);
            writer.writeBool(this.isClockwise);
            writer.writeInt32(this.count);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[RotationComponent]", this);
            this.speed = reader.readDouble();
            this.isClockwise = reader.readBool();
            this.count = reader.readInt32();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "speed"){
                reader.readDouble(this, "speed");
                return;
            }

            if (name === "isClockwise"){
                reader.readBoolean(this, "isClockwise");
                return;
            }

            if (name === "count"){
                reader.readInt32(this, "count");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeDouble("speed", this.speed);
            writer.writeBoolean("isClockwise", this.isClockwise);
            writer.writeInt32("count", this.count);
            writer.endObject(ctx);
        }
    }
    export class PostProcessPropertiesComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.fxaaEnabled = false;
            this.antiAliasingSamples = 0;
            this.bloomEnabled = false;
            this.bloomWeight = 0;
            this.bloomKernel = 0;
            this.bloomScale = 0;
            this.bloomThreshold = 0;
            this.toneMappingEnabled = false;
            this.screenSpaceReflectionStrength = 0;
            this.screenSpaceReflectionSample = 0;
            this.screenSpaceAmbientOcclusionEnabled = false;
            this.screenSpaceAmbientOcclusionTotalStrength = 0;
            this.vignetteEnabled = false;
            this.vignetteWeight = 0;
            this.vignetteRounded = false;
            this.vignetteColorR = 0;
            this.vignetteColorG = 0;
            this.vignetteColorB = 0;
            this.vignetteColorA = 0;
            this.contrast = 0;
            this.exposure = 0;
        }
        fxaaEnabled: boolean;
        antiAliasingSamples: number;
        bloomEnabled: boolean;
        bloomWeight: number;
        bloomKernel: number;
        bloomScale: number;
        bloomThreshold: number;
        toneMappingEnabled: boolean;
        screenSpaceReflectionStrength: number;
        screenSpaceReflectionSample: number;
        screenSpaceAmbientOcclusionEnabled: boolean;
        screenSpaceAmbientOcclusionTotalStrength: number;
        vignetteEnabled: boolean;
        vignetteWeight: number;
        vignetteRounded: boolean;
        vignetteColorR: number;
        vignetteColorG: number;
        vignetteColorB: number;
        vignetteColorA: number;
        contrast: number;
        exposure: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[PostProcessPropertiesComponent]", this);
            writer.writeBool(this.fxaaEnabled);
            writer.writeInt32(this.antiAliasingSamples);
            writer.writeBool(this.bloomEnabled);
            writer.writeDouble(this.bloomWeight);
            writer.writeInt32(this.bloomKernel);
            writer.writeDouble(this.bloomScale);
            writer.writeDouble(this.bloomThreshold);
            writer.writeBool(this.toneMappingEnabled);
            writer.writeDouble(this.screenSpaceReflectionStrength);
            writer.writeInt32(this.screenSpaceReflectionSample);
            writer.writeBool(this.screenSpaceAmbientOcclusionEnabled);
            writer.writeDouble(this.screenSpaceAmbientOcclusionTotalStrength);
            writer.writeBool(this.vignetteEnabled);
            writer.writeDouble(this.vignetteWeight);
            writer.writeBool(this.vignetteRounded);
            writer.writeDouble(this.vignetteColorR);
            writer.writeDouble(this.vignetteColorG);
            writer.writeDouble(this.vignetteColorB);
            writer.writeDouble(this.vignetteColorA);
            writer.writeDouble(this.contrast);
            writer.writeDouble(this.exposure);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[PostProcessPropertiesComponent]", this);
            this.fxaaEnabled = reader.readBool();
            this.antiAliasingSamples = reader.readInt32();
            this.bloomEnabled = reader.readBool();
            this.bloomWeight = reader.readDouble();
            this.bloomKernel = reader.readInt32();
            this.bloomScale = reader.readDouble();
            this.bloomThreshold = reader.readDouble();
            this.toneMappingEnabled = reader.readBool();
            this.screenSpaceReflectionStrength = reader.readDouble();
            this.screenSpaceReflectionSample = reader.readInt32();
            this.screenSpaceAmbientOcclusionEnabled = reader.readBool();
            this.screenSpaceAmbientOcclusionTotalStrength = reader.readDouble();
            this.vignetteEnabled = reader.readBool();
            this.vignetteWeight = reader.readDouble();
            this.vignetteRounded = reader.readBool();
            this.vignetteColorR = reader.readDouble();
            this.vignetteColorG = reader.readDouble();
            this.vignetteColorB = reader.readDouble();
            this.vignetteColorA = reader.readDouble();
            this.contrast = reader.readDouble();
            this.exposure = reader.readDouble();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "fxaaEnabled"){
                reader.readBoolean(this, "fxaaEnabled");
                return;
            }

            if (name === "antiAliasingSamples"){
                reader.readInt32(this, "antiAliasingSamples");
                return;
            }

            if (name === "bloomEnabled"){
                reader.readBoolean(this, "bloomEnabled");
                return;
            }

            if (name === "bloomWeight"){
                reader.readDouble(this, "bloomWeight");
                return;
            }

            if (name === "bloomKernel"){
                reader.readInt32(this, "bloomKernel");
                return;
            }

            if (name === "bloomScale"){
                reader.readDouble(this, "bloomScale");
                return;
            }

            if (name === "bloomThreshold"){
                reader.readDouble(this, "bloomThreshold");
                return;
            }

            if (name === "toneMappingEnabled"){
                reader.readBoolean(this, "toneMappingEnabled");
                return;
            }

            if (name === "screenSpaceReflectionStrength"){
                reader.readDouble(this, "screenSpaceReflectionStrength");
                return;
            }

            if (name === "screenSpaceReflectionSample"){
                reader.readInt32(this, "screenSpaceReflectionSample");
                return;
            }

            if (name === "screenSpaceAmbientOcclusionEnabled"){
                reader.readBoolean(this, "screenSpaceAmbientOcclusionEnabled");
                return;
            }

            if (name === "screenSpaceAmbientOcclusionTotalStrength"){
                reader.readDouble(this, "screenSpaceAmbientOcclusionTotalStrength");
                return;
            }

            if (name === "vignetteEnabled"){
                reader.readBoolean(this, "vignetteEnabled");
                return;
            }

            if (name === "vignetteWeight"){
                reader.readDouble(this, "vignetteWeight");
                return;
            }

            if (name === "vignetteRounded"){
                reader.readBoolean(this, "vignetteRounded");
                return;
            }

            if (name === "vignetteColorR"){
                reader.readDouble(this, "vignetteColorR");
                return;
            }

            if (name === "vignetteColorG"){
                reader.readDouble(this, "vignetteColorG");
                return;
            }

            if (name === "vignetteColorB"){
                reader.readDouble(this, "vignetteColorB");
                return;
            }

            if (name === "vignetteColorA"){
                reader.readDouble(this, "vignetteColorA");
                return;
            }

            if (name === "contrast"){
                reader.readDouble(this, "contrast");
                return;
            }

            if (name === "exposure"){
                reader.readDouble(this, "exposure");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("fxaaEnabled", this.fxaaEnabled);
            writer.writeInt32("antiAliasingSamples", this.antiAliasingSamples);
            writer.writeBoolean("bloomEnabled", this.bloomEnabled);
            writer.writeDouble("bloomWeight", this.bloomWeight);
            writer.writeInt32("bloomKernel", this.bloomKernel);
            writer.writeDouble("bloomScale", this.bloomScale);
            writer.writeDouble("bloomThreshold", this.bloomThreshold);
            writer.writeBoolean("toneMappingEnabled", this.toneMappingEnabled);
            writer.writeDouble("screenSpaceReflectionStrength", this.screenSpaceReflectionStrength);
            writer.writeInt32("screenSpaceReflectionSample", this.screenSpaceReflectionSample);
            writer.writeBoolean("screenSpaceAmbientOcclusionEnabled", this.screenSpaceAmbientOcclusionEnabled);
            writer.writeDouble("screenSpaceAmbientOcclusionTotalStrength", this.screenSpaceAmbientOcclusionTotalStrength);
            writer.writeBoolean("vignetteEnabled", this.vignetteEnabled);
            writer.writeDouble("vignetteWeight", this.vignetteWeight);
            writer.writeBoolean("vignetteRounded", this.vignetteRounded);
            writer.writeDouble("vignetteColorR", this.vignetteColorR);
            writer.writeDouble("vignetteColorG", this.vignetteColorG);
            writer.writeDouble("vignetteColorB", this.vignetteColorB);
            writer.writeDouble("vignetteColorA", this.vignetteColorA);
            writer.writeDouble("contrast", this.contrast);
            writer.writeDouble("exposure", this.exposure);
            writer.endObject(ctx);
        }
    }
    export class VideoTextureComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.index = 0;
            this.videoTime = 0;
        }
        index: number;
        videoTime: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[VideoTextureComponent]", this);
            writer.writeInt32(this.index);
            writer.writeDouble(this.videoTime);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[VideoTextureComponent]", this);
            this.index = reader.readInt32();
            this.videoTime = reader.readDouble();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "index"){
                reader.readInt32(this, "index");
                return;
            }

            if (name === "videoTime"){
                reader.readDouble(this, "videoTime");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeInt32("index", this.index);
            writer.writeDouble("videoTime", this.videoTime);
            writer.endObject(ctx);
        }
    }
    export class MediaTextureComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.isSlideshow = false;
            this.slideshowSeconds = 0;
            this.index = 0;
            this.id = "";
            this.isVideoPlaying = false;
            this.videoTime = 0;
            this.pdfCurrentPage = 0;
        }
        isSlideshow: boolean;
        slideshowSeconds: number;
        index: number;
        id: string;
        isVideoPlaying: boolean;
        videoTime: number;
        pdfCurrentPage: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[MediaTextureComponent]", this);
            writer.writeBool(this.isSlideshow);
            writer.writeInt32(this.slideshowSeconds);
            writer.writeInt32(this.index);
            writer.writeString(this.id);
            writer.writeBool(this.isVideoPlaying);
            writer.writeDouble(this.videoTime);
            writer.writeInt32(this.pdfCurrentPage);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[MediaTextureComponent]", this);
            this.isSlideshow = reader.readBool();
            this.slideshowSeconds = reader.readInt32();
            this.index = reader.readInt32();
            this.id = reader.readString();
            this.isVideoPlaying = reader.readBool();
            this.videoTime = reader.readDouble();
            this.pdfCurrentPage = reader.readInt32();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "isSlideshow"){
                reader.readBoolean(this, "isSlideshow");
                return;
            }

            if (name === "slideshowSeconds"){
                reader.readInt32(this, "slideshowSeconds");
                return;
            }

            if (name === "index"){
                reader.readInt32(this, "index");
                return;
            }

            if (name === "id"){
                reader.readString(this, "id");
                return;
            }

            if (name === "isVideoPlaying"){
                reader.readBoolean(this, "isVideoPlaying");
                return;
            }

            if (name === "videoTime"){
                reader.readDouble(this, "videoTime");
                return;
            }

            if (name === "pdfCurrentPage"){
                reader.readInt32(this, "pdfCurrentPage");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("isSlideshow", this.isSlideshow);
            writer.writeInt32("slideshowSeconds", this.slideshowSeconds);
            writer.writeInt32("index", this.index);
            writer.writeString("id", this.id);
            writer.writeBoolean("isVideoPlaying", this.isVideoPlaying);
            writer.writeDouble("videoTime", this.videoTime);
            writer.writeInt32("pdfCurrentPage", this.pdfCurrentPage);
            writer.endObject(ctx);
        }
    }
    export class CameraPropertiesComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.minZ = 0;
            this.maxZ = 0;
            this.lowerRadiusLimit = 0;
            this.upperRadiusLimit = 0;
            this.panningSensibility = 0;
            this.inertia = 0;
            this.panningInertia = 0;
            this.wheelPrecision = 0;
            this.pinchPrecision = 0;
            this.fov = 0;
            this.betaLimitMode = 0;
        }
        minZ: number;
        maxZ: number;
        lowerRadiusLimit: number;
        upperRadiusLimit: number;
        panningSensibility: number;
        inertia: number;
        panningInertia: number;
        wheelPrecision: number;
        pinchPrecision: number;
        fov: number;
        betaLimitMode: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[CameraPropertiesComponent]", this);
            writer.writeDouble(this.minZ);
            writer.writeDouble(this.maxZ);
            writer.writeDouble(this.lowerRadiusLimit);
            writer.writeDouble(this.upperRadiusLimit);
            writer.writeDouble(this.panningSensibility);
            writer.writeDouble(this.inertia);
            writer.writeDouble(this.panningInertia);
            writer.writeDouble(this.wheelPrecision);
            writer.writeDouble(this.pinchPrecision);
            writer.writeDouble(this.fov);
            writer.writeInt32(this.betaLimitMode);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[CameraPropertiesComponent]", this);
            this.minZ = reader.readDouble();
            this.maxZ = reader.readDouble();
            this.lowerRadiusLimit = reader.readDouble();
            this.upperRadiusLimit = reader.readDouble();
            this.panningSensibility = reader.readDouble();
            this.inertia = reader.readDouble();
            this.panningInertia = reader.readDouble();
            this.wheelPrecision = reader.readDouble();
            this.pinchPrecision = reader.readDouble();
            this.fov = reader.readDouble();
            this.betaLimitMode = reader.readInt32();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "minZ"){
                reader.readDouble(this, "minZ");
                return;
            }

            if (name === "maxZ"){
                reader.readDouble(this, "maxZ");
                return;
            }

            if (name === "lowerRadiusLimit"){
                reader.readDouble(this, "lowerRadiusLimit");
                return;
            }

            if (name === "upperRadiusLimit"){
                reader.readDouble(this, "upperRadiusLimit");
                return;
            }

            if (name === "panningSensibility"){
                reader.readDouble(this, "panningSensibility");
                return;
            }

            if (name === "inertia"){
                reader.readDouble(this, "inertia");
                return;
            }

            if (name === "panningInertia"){
                reader.readDouble(this, "panningInertia");
                return;
            }

            if (name === "wheelPrecision"){
                reader.readDouble(this, "wheelPrecision");
                return;
            }

            if (name === "pinchPrecision"){
                reader.readDouble(this, "pinchPrecision");
                return;
            }

            if (name === "fov"){
                reader.readDouble(this, "fov");
                return;
            }

            if (name === "betaLimitMode"){
                reader.readInt32(this, "betaLimitMode");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeDouble("minZ", this.minZ);
            writer.writeDouble("maxZ", this.maxZ);
            writer.writeDouble("lowerRadiusLimit", this.lowerRadiusLimit);
            writer.writeDouble("upperRadiusLimit", this.upperRadiusLimit);
            writer.writeDouble("panningSensibility", this.panningSensibility);
            writer.writeDouble("inertia", this.inertia);
            writer.writeDouble("panningInertia", this.panningInertia);
            writer.writeDouble("wheelPrecision", this.wheelPrecision);
            writer.writeDouble("pinchPrecision", this.pinchPrecision);
            writer.writeDouble("fov", this.fov);
            writer.writeInt32("betaLimitMode", this.betaLimitMode);
            writer.endObject(ctx);
        }
    }
    export class NotificationComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.message = "";
        }
        message: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[NotificationComponent]", this);
            writer.writeString(this.message);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[NotificationComponent]", this);
            this.message = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "message"){
                reader.readString(this, "message");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("message", this.message);
            writer.endObject(ctx);
        }
    }
    export class GltfLoadingHandlerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.canOnlyBeUsedByHost = false;
        }
        canOnlyBeUsedByHost: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[GltfLoadingHandlerComponent]", this);
            writer.writeBool(this.canOnlyBeUsedByHost);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[GltfLoadingHandlerComponent]", this);
            this.canOnlyBeUsedByHost = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "canOnlyBeUsedByHost"){
                reader.readBoolean(this, "canOnlyBeUsedByHost");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("canOnlyBeUsedByHost", this.canOnlyBeUsedByHost);
            writer.endObject(ctx);
        }
    }
    export class ForceReleaseTokenComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.nodeId = "";
        }
        nodeId: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ForceReleaseTokenComponent]", this);
            writer.writeString(this.nodeId);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ForceReleaseTokenComponent]", this);
            this.nodeId = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "nodeId"){
                reader.readString(this, "nodeId");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("nodeId", this.nodeId);
            writer.endObject(ctx);
        }
    }
    export class MakeNodeUsableOnlyByHostComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.nodeId = "";
            this.canOnlyBeUsedByHost = false;
        }
        nodeId: string;
        canOnlyBeUsedByHost: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[MakeNodeUsableOnlyByHostComponent]", this);
            writer.writeString(this.nodeId);
            writer.writeBool(this.canOnlyBeUsedByHost);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[MakeNodeUsableOnlyByHostComponent]", this);
            this.nodeId = reader.readString();
            this.canOnlyBeUsedByHost = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "nodeId"){
                reader.readString(this, "nodeId");
                return;
            }

            if (name === "canOnlyBeUsedByHost"){
                reader.readBoolean(this, "canOnlyBeUsedByHost");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("nodeId", this.nodeId);
            writer.writeBoolean("canOnlyBeUsedByHost", this.canOnlyBeUsedByHost);
            writer.endObject(ctx);
        }
    }
    export class ObjectManipulatorComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ObjectManipulatorComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ObjectManipulatorComponent]", this);
        }
    }
    export class NavMeshComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[NavMeshComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[NavMeshComponent]", this);
        }
    }
    export class WebRtcControllerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.guestId = "";
            this.audio = false;
            this.video = false;
        }
        guestId: string;
        audio: boolean;
        video: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[WebRtcControllerComponent]", this);
            writer.writeString(this.guestId);
            writer.writeBool(this.audio);
            writer.writeBool(this.video);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[WebRtcControllerComponent]", this);
            this.guestId = reader.readString();
            this.audio = reader.readBool();
            this.video = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "guestId"){
                reader.readString(this, "guestId");
                return;
            }

            if (name === "audio"){
                reader.readBoolean(this, "audio");
                return;
            }

            if (name === "video"){
                reader.readBoolean(this, "video");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("guestId", this.guestId);
            writer.writeBoolean("audio", this.audio);
            writer.writeBoolean("video", this.video);
            writer.endObject(ctx);
        }
    }
    export class AnimationComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.shouldPlay = [];
            this.shouldPlayForward = [];
            this.lastPlayedIndex = 0;
            this.actionIndexes = [];
            this.actionValues = [];
            this.triggerActionIndexes = [];
            this.triggerActionValues = [];
        }
        shouldPlay: boolean[];
        shouldPlayForward: boolean[];
        lastPlayedIndex: number;
        actionIndexes: number[];
        actionValues: number[];
        triggerActionIndexes: number[];
        triggerActionValues: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[AnimationComponent]", this);
            for (var i = 0; i < this.shouldPlay.length; ++i)
                writer.writeBool(this.shouldPlay[i]);

            for (var i = 0; i < this.shouldPlayForward.length; ++i)
                writer.writeBool(this.shouldPlayForward[i]);

            writer.writeInt32(this.lastPlayedIndex);
            for (var i = 0; i < this.actionIndexes.length; ++i)
                writer.writeInt32(this.actionIndexes[i]);

            for (var i = 0; i < this.actionValues.length; ++i)
                writer.writeInt32(this.actionValues[i]);

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                writer.writeInt32(this.triggerActionIndexes[i]);

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                writer.writeInt32(this.triggerActionValues[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[AnimationComponent]", this);
            for (var i = 0; i < this.shouldPlay.length; ++i)
                this.shouldPlay[i] = reader.readBool();

            for (var i = 0; i < this.shouldPlayForward.length; ++i)
                this.shouldPlayForward[i] = reader.readBool();

            this.lastPlayedIndex = reader.readInt32();
            for (var i = 0; i < this.actionIndexes.length; ++i)
                this.actionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.actionValues.length; ++i)
                this.actionValues[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                this.triggerActionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                this.triggerActionValues[i] = reader.readInt32();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "shouldPlay" && type === Vertex.BsonType.Array){
                reader.readArray(this, "shouldPlay", reader.readBoolean);
                return;
            }

            if (name === "shouldPlayForward" && type === Vertex.BsonType.Array){
                reader.readArray(this, "shouldPlayForward", reader.readBoolean);
                return;
            }

            if (name === "lastPlayedIndex"){
                reader.readInt32(this, "lastPlayedIndex");
                return;
            }

            if (name === "actionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionIndexes", reader.readInt32);
                return;
            }

            if (name === "actionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionValues", reader.readInt32);
                return;
            }

            if (name === "triggerActionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionIndexes", reader.readInt32);
                return;
            }

            if (name === "triggerActionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionValues", reader.readInt32);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeArray("shouldPlay", this.shouldPlay, writer.writeBoolean);
            writer.writeArray("shouldPlayForward", this.shouldPlayForward, writer.writeBoolean);
            writer.writeInt32("lastPlayedIndex", this.lastPlayedIndex);
            writer.writeArray("actionIndexes", this.actionIndexes, writer.writeInt32);
            writer.writeArray("actionValues", this.actionValues, writer.writeInt32);
            writer.writeArray("triggerActionIndexes", this.triggerActionIndexes, writer.writeInt32);
            writer.writeArray("triggerActionValues", this.triggerActionValues, writer.writeInt32);
            writer.endObject(ctx);
        }
    }
    export class ItemPropertiesComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.isSelectable = false;
            this.isMovable = false;
            this.isWishlistable = false;
            this.requiresFocus = false;
            this.useInAR = false;
        }
        isSelectable: boolean;
        isMovable: boolean;
        isWishlistable: boolean;
        requiresFocus: boolean;
        useInAR: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ItemPropertiesComponent]", this);
            writer.writeBool(this.isSelectable);
            writer.writeBool(this.isMovable);
            writer.writeBool(this.isWishlistable);
            writer.writeBool(this.requiresFocus);
            writer.writeBool(this.useInAR);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ItemPropertiesComponent]", this);
            this.isSelectable = reader.readBool();
            this.isMovable = reader.readBool();
            this.isWishlistable = reader.readBool();
            this.requiresFocus = reader.readBool();
            this.useInAR = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "isSelectable"){
                reader.readBoolean(this, "isSelectable");
                return;
            }

            if (name === "isMovable"){
                reader.readBoolean(this, "isMovable");
                return;
            }

            if (name === "isWishlistable"){
                reader.readBoolean(this, "isWishlistable");
                return;
            }

            if (name === "requiresFocus"){
                reader.readBoolean(this, "requiresFocus");
                return;
            }

            if (name === "useInAR"){
                reader.readBoolean(this, "useInAR");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("isSelectable", this.isSelectable);
            writer.writeBoolean("isMovable", this.isMovable);
            writer.writeBoolean("isWishlistable", this.isWishlistable);
            writer.writeBoolean("requiresFocus", this.requiresFocus);
            writer.writeBoolean("useInAR", this.useInAR);
            writer.endObject(ctx);
        }
    }
    export class ScenePropertiesComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.modelCount = 0;
            this.mediaCount = 0;
            this.textCount = 0;
        }
        modelCount: number;
        mediaCount: number;
        textCount: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ScenePropertiesComponent]", this);
            writer.writeInt32(this.modelCount);
            writer.writeInt32(this.mediaCount);
            writer.writeInt32(this.textCount);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ScenePropertiesComponent]", this);
            this.modelCount = reader.readInt32();
            this.mediaCount = reader.readInt32();
            this.textCount = reader.readInt32();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "modelCount"){
                reader.readInt32(this, "modelCount");
                return;
            }

            if (name === "mediaCount"){
                reader.readInt32(this, "mediaCount");
                return;
            }

            if (name === "textCount"){
                reader.readInt32(this, "textCount");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeInt32("modelCount", this.modelCount);
            writer.writeInt32("mediaCount", this.mediaCount);
            writer.writeInt32("textCount", this.textCount);
            writer.endObject(ctx);
        }
    }
    export class NodeLockableComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.isLocked = false;
        }
        isLocked: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[NodeLockableComponent]", this);
            writer.writeBool(this.isLocked);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[NodeLockableComponent]", this);
            this.isLocked = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "isLocked"){
                reader.readBoolean(this, "isLocked");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("isLocked", this.isLocked);
            writer.endObject(ctx);
        }
    }
    export class UnselectableComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[UnselectableComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[UnselectableComponent]", this);
        }
    }
    export class SummonableComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[SummonableComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[SummonableComponent]", this);
        }
    }
    export class DestructorComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.onDestroy = new Vertex.NodeComponentModel.RPCEventBus("OnDestroy", DestructorMessage);
            this.events.set(this.onDestroy.eventName, this.onDestroy);
        }
        onDestroy: Vertex.NodeComponentModel.RPCEventBus<DestructorMessage>;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[DestructorComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[DestructorComponent]", this);
        }
    }
    export class LoadingFeedbackComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[LoadingFeedbackComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[LoadingFeedbackComponent]", this);
        }
    }
    export class SkyboxComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.activeSkyboxIndex = 0;
            this.actionIndexes = [];
            this.actionValues = [];
            this.triggerActionIndexes = [];
            this.triggerActionValues = [];
        }
        activeSkyboxIndex: number;
        actionIndexes: number[];
        actionValues: number[];
        triggerActionIndexes: number[];
        triggerActionValues: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[SkyboxComponent]", this);
            writer.writeInt32(this.activeSkyboxIndex);
            for (var i = 0; i < this.actionIndexes.length; ++i)
                writer.writeInt32(this.actionIndexes[i]);

            for (var i = 0; i < this.actionValues.length; ++i)
                writer.writeInt32(this.actionValues[i]);

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                writer.writeInt32(this.triggerActionIndexes[i]);

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                writer.writeInt32(this.triggerActionValues[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[SkyboxComponent]", this);
            this.activeSkyboxIndex = reader.readInt32();
            for (var i = 0; i < this.actionIndexes.length; ++i)
                this.actionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.actionValues.length; ++i)
                this.actionValues[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                this.triggerActionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                this.triggerActionValues[i] = reader.readInt32();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "activeSkyboxIndex"){
                reader.readInt32(this, "activeSkyboxIndex");
                return;
            }

            if (name === "actionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionIndexes", reader.readInt32);
                return;
            }

            if (name === "actionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionValues", reader.readInt32);
                return;
            }

            if (name === "triggerActionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionIndexes", reader.readInt32);
                return;
            }

            if (name === "triggerActionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionValues", reader.readInt32);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeInt32("activeSkyboxIndex", this.activeSkyboxIndex);
            writer.writeArray("actionIndexes", this.actionIndexes, writer.writeInt32);
            writer.writeArray("actionValues", this.actionValues, writer.writeInt32);
            writer.writeArray("triggerActionIndexes", this.triggerActionIndexes, writer.writeInt32);
            writer.writeArray("triggerActionValues", this.triggerActionValues, writer.writeInt32);
            writer.endObject(ctx);
        }
    }
    export class PersistanceComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.assetId = "";
        }
        assetId: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[PersistanceComponent]", this);
            writer.writeString(this.assetId);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[PersistanceComponent]", this);
            this.assetId = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "assetId"){
                reader.readString(this, "assetId");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("assetId", this.assetId);
            writer.endObject(ctx);
        }
    }
    export class ChangeMaterialComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.id = "";
            this.updatedMeshIndexes = [];
            this.updatedMatIndexes = [];
            this.actionIndexes = [];
            this.actionValues = [];
            this.triggerActionIndexes = [];
            this.triggerActionValues = [];
        }
        id: string;
        updatedMeshIndexes: number[];
        updatedMatIndexes: number[];
        actionIndexes: number[];
        actionValues: number[];
        triggerActionIndexes: number[];
        triggerActionValues: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ChangeMaterialComponent]", this);
            writer.writeString(this.id);
            for (var i = 0; i < this.updatedMeshIndexes.length; ++i)
                writer.writeInt32(this.updatedMeshIndexes[i]);

            for (var i = 0; i < this.updatedMatIndexes.length; ++i)
                writer.writeInt32(this.updatedMatIndexes[i]);

            for (var i = 0; i < this.actionIndexes.length; ++i)
                writer.writeInt32(this.actionIndexes[i]);

            for (var i = 0; i < this.actionValues.length; ++i)
                writer.writeInt32(this.actionValues[i]);

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                writer.writeInt32(this.triggerActionIndexes[i]);

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                writer.writeInt32(this.triggerActionValues[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ChangeMaterialComponent]", this);
            this.id = reader.readString();
            for (var i = 0; i < this.updatedMeshIndexes.length; ++i)
                this.updatedMeshIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.updatedMatIndexes.length; ++i)
                this.updatedMatIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.actionIndexes.length; ++i)
                this.actionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.actionValues.length; ++i)
                this.actionValues[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                this.triggerActionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                this.triggerActionValues[i] = reader.readInt32();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "id"){
                reader.readString(this, "id");
                return;
            }

            if (name === "updatedMeshIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "updatedMeshIndexes", reader.readInt32);
                return;
            }

            if (name === "updatedMatIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "updatedMatIndexes", reader.readInt32);
                return;
            }

            if (name === "actionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionIndexes", reader.readInt32);
                return;
            }

            if (name === "actionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionValues", reader.readInt32);
                return;
            }

            if (name === "triggerActionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionIndexes", reader.readInt32);
                return;
            }

            if (name === "triggerActionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionValues", reader.readInt32);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("id", this.id);
            writer.writeArray("updatedMeshIndexes", this.updatedMeshIndexes, writer.writeInt32);
            writer.writeArray("updatedMatIndexes", this.updatedMatIndexes, writer.writeInt32);
            writer.writeArray("actionIndexes", this.actionIndexes, writer.writeInt32);
            writer.writeArray("actionValues", this.actionValues, writer.writeInt32);
            writer.writeArray("triggerActionIndexes", this.triggerActionIndexes, writer.writeInt32);
            writer.writeArray("triggerActionValues", this.triggerActionValues, writer.writeInt32);
            writer.endObject(ctx);
        }
    }
    export class LetteringComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.assetId = "";
            this.lettersChar = [];
        }
        assetId: string;
        lettersChar: string[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[LetteringComponent]", this);
            writer.writeString(this.assetId);
            for (var i = 0; i < this.lettersChar.length; ++i)
                writer.writeString(this.lettersChar[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[LetteringComponent]", this);
            this.assetId = reader.readString();
            for (var i = 0; i < this.lettersChar.length; ++i)
                this.lettersChar[i] = reader.readString();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "assetId"){
                reader.readString(this, "assetId");
                return;
            }

            if (name === "lettersChar" && type === Vertex.BsonType.Array){
                reader.readArray(this, "lettersChar", reader.readString);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("assetId", this.assetId);
            writer.writeArray("lettersChar", this.lettersChar, writer.writeString);
            writer.endObject(ctx);
        }
    }
    export class ResetTransformComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.originalPos = [];
            this.originalRot = [];
            this.originalScale = [];
        }
        originalPos: number[];
        originalRot: number[];
        originalScale: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ResetTransformComponent]", this);
            for (var i = 0; i < this.originalPos.length; ++i)
                writer.writeDouble(this.originalPos[i]);

            for (var i = 0; i < this.originalRot.length; ++i)
                writer.writeDouble(this.originalRot[i]);

            for (var i = 0; i < this.originalScale.length; ++i)
                writer.writeDouble(this.originalScale[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ResetTransformComponent]", this);
            for (var i = 0; i < this.originalPos.length; ++i)
                this.originalPos[i] = reader.readDouble();

            for (var i = 0; i < this.originalRot.length; ++i)
                this.originalRot[i] = reader.readDouble();

            for (var i = 0; i < this.originalScale.length; ++i)
                this.originalScale[i] = reader.readDouble();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "OriginalPos" && type === Vertex.BsonType.Array){
                reader.readArray(this, "originalPos", reader.readDouble);
                return;
            }

            if (name === "OriginalRot" && type === Vertex.BsonType.Array){
                reader.readArray(this, "originalRot", reader.readDouble);
                return;
            }

            if (name === "OriginalScale" && type === Vertex.BsonType.Array){
                reader.readArray(this, "originalScale", reader.readDouble);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeArray("OriginalPos", this.originalPos, writer.writeDouble);
            writer.writeArray("OriginalRot", this.originalRot, writer.writeDouble);
            writer.writeArray("OriginalScale", this.originalScale, writer.writeDouble);
            writer.endObject(ctx);
        }
    }
    export class ModelAlternativeComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.modelList = [];
            this.actionIndexes = [];
            this.actionValues = [];
            this.triggerActionIndexes = [];
            this.triggerActionValues = [];
        }
        modelList: string[];
        actionIndexes: number[];
        actionValues: number[];
        triggerActionIndexes: number[];
        triggerActionValues: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ModelAlternativeComponent]", this);
            for (var i = 0; i < this.modelList.length; ++i)
                writer.writeString(this.modelList[i]);

            for (var i = 0; i < this.actionIndexes.length; ++i)
                writer.writeInt32(this.actionIndexes[i]);

            for (var i = 0; i < this.actionValues.length; ++i)
                writer.writeInt32(this.actionValues[i]);

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                writer.writeInt32(this.triggerActionIndexes[i]);

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                writer.writeInt32(this.triggerActionValues[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ModelAlternativeComponent]", this);
            for (var i = 0; i < this.modelList.length; ++i)
                this.modelList[i] = reader.readString();

            for (var i = 0; i < this.actionIndexes.length; ++i)
                this.actionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.actionValues.length; ++i)
                this.actionValues[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionIndexes.length; ++i)
                this.triggerActionIndexes[i] = reader.readInt32();

            for (var i = 0; i < this.triggerActionValues.length; ++i)
                this.triggerActionValues[i] = reader.readInt32();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "modelList" && type === Vertex.BsonType.Array){
                reader.readArray(this, "modelList", reader.readString);
                return;
            }

            if (name === "actionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionIndexes", reader.readInt32);
                return;
            }

            if (name === "actionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "actionValues", reader.readInt32);
                return;
            }

            if (name === "triggerActionIndexes" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionIndexes", reader.readInt32);
                return;
            }

            if (name === "triggerActionValues" && type === Vertex.BsonType.Array){
                reader.readArray(this, "triggerActionValues", reader.readInt32);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeArray("modelList", this.modelList, writer.writeString);
            writer.writeArray("actionIndexes", this.actionIndexes, writer.writeInt32);
            writer.writeArray("actionValues", this.actionValues, writer.writeInt32);
            writer.writeArray("triggerActionIndexes", this.triggerActionIndexes, writer.writeInt32);
            writer.writeArray("triggerActionValues", this.triggerActionValues, writer.writeInt32);
            writer.endObject(ctx);
        }
    }
    export class LightMapsComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.lightMapsData = [];
        }
        lightMapsData: number[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[LightMapsComponent]", this);
            for (var i = 0; i < this.lightMapsData.length; ++i)
                writer.writeInt32(this.lightMapsData[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[LightMapsComponent]", this);
            for (var i = 0; i < this.lightMapsData.length; ++i)
                this.lightMapsData[i] = reader.readInt32();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "lightMapsData" && type === Vertex.BsonType.Array){
                reader.readArray(this, "lightMapsData", reader.readInt32);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeArray("lightMapsData", this.lightMapsData, writer.writeInt32);
            writer.endObject(ctx);
        }
    }
    export class CameraLocationComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.locationName = "";
        }
        locationName: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[CameraLocationComponent]", this);
            writer.writeString(this.locationName);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[CameraLocationComponent]", this);
            this.locationName = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "locationName"){
                reader.readString(this, "locationName");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("locationName", this.locationName);
            writer.endObject(ctx);
        }
    }
    export class AvatarComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.text = "";
            this.imageURL = "";
            this.hide = false;
        }
        text: string;
        imageURL: string;
        hide: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[AvatarComponent]", this);
            writer.writeString(this.text);
            writer.writeString(this.imageURL);
            writer.writeBool(this.hide);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[AvatarComponent]", this);
            this.text = reader.readString();
            this.imageURL = reader.readString();
            this.hide = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "text"){
                reader.readString(this, "text");
                return;
            }

            if (name === "imageURL"){
                reader.readString(this, "imageURL");
                return;
            }

            if (name === "hide"){
                reader.readBoolean(this, "hide");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("text", this.text);
            writer.writeString("imageURL", this.imageURL);
            writer.writeBoolean("hide", this.hide);
            writer.endObject(ctx);
        }
    }
    export class WarpComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.type = "";
            this.data = "";
            this.hide = false;
            this.privateHost = "";
            this.publicHost = "";
            this.hidePrivateHost = false;
            this.hidePublicHost = false;
        }
        type: string;
        data: string;
        hide: boolean;
        privateHost: string;
        publicHost: string;
        hidePrivateHost: boolean;
        hidePublicHost: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[WarpComponent]", this);
            writer.writeString(this.type);
            writer.writeString(this.data);
            writer.writeBool(this.hide);
            writer.writeString(this.privateHost);
            writer.writeString(this.publicHost);
            writer.writeBool(this.hidePrivateHost);
            writer.writeBool(this.hidePublicHost);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[WarpComponent]", this);
            this.type = reader.readString();
            this.data = reader.readString();
            this.hide = reader.readBool();
            this.privateHost = reader.readString();
            this.publicHost = reader.readString();
            this.hidePrivateHost = reader.readBool();
            this.hidePublicHost = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "type"){
                reader.readString(this, "type");
                return;
            }

            if (name === "data"){
                reader.readString(this, "data");
                return;
            }

            if (name === "hide"){
                reader.readBoolean(this, "hide");
                return;
            }

            if (name === "privateHost"){
                reader.readString(this, "privateHost");
                return;
            }

            if (name === "publicHost"){
                reader.readString(this, "publicHost");
                return;
            }

            if (name === "hidePrivateHost"){
                reader.readBoolean(this, "hidePrivateHost");
                return;
            }

            if (name === "hidePublicHost"){
                reader.readBoolean(this, "hidePublicHost");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("type", this.type);
            writer.writeString("data", this.data);
            writer.writeBoolean("hide", this.hide);
            writer.writeString("privateHost", this.privateHost);
            writer.writeString("publicHost", this.publicHost);
            writer.writeBoolean("hidePrivateHost", this.hidePrivateHost);
            writer.writeBoolean("hidePublicHost", this.hidePublicHost);
            writer.endObject(ctx);
        }
    }
    export class PhysicalMeshComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.type = "";
            this.data = "";
            this.hide = false;
        }
        type: string;
        data: string;
        hide: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[PhysicalMeshComponent]", this);
            writer.writeString(this.type);
            writer.writeString(this.data);
            writer.writeBool(this.hide);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[PhysicalMeshComponent]", this);
            this.type = reader.readString();
            this.data = reader.readString();
            this.hide = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "type"){
                reader.readString(this, "type");
                return;
            }

            if (name === "data"){
                reader.readString(this, "data");
                return;
            }

            if (name === "hide"){
                reader.readBoolean(this, "hide");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("type", this.type);
            writer.writeString("data", this.data);
            writer.writeBoolean("hide", this.hide);
            writer.endObject(ctx);
        }
    }
    export class VolumeComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.type = "";
            this.data = "";
            this.hide = false;
        }
        type: string;
        data: string;
        hide: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[VolumeComponent]", this);
            writer.writeString(this.type);
            writer.writeString(this.data);
            writer.writeBool(this.hide);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[VolumeComponent]", this);
            this.type = reader.readString();
            this.data = reader.readString();
            this.hide = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "type"){
                reader.readString(this, "type");
                return;
            }

            if (name === "data"){
                reader.readString(this, "data");
                return;
            }

            if (name === "hide"){
                reader.readBoolean(this, "hide");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("type", this.type);
            writer.writeString("data", this.data);
            writer.writeBoolean("hide", this.hide);
            writer.endObject(ctx);
        }
    }
    export class ShapeComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.type = "";
            this.data = "";
            this.hide = false;
        }
        type: string;
        data: string;
        hide: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ShapeComponent]", this);
            writer.writeString(this.type);
            writer.writeString(this.data);
            writer.writeBool(this.hide);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ShapeComponent]", this);
            this.type = reader.readString();
            this.data = reader.readString();
            this.hide = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "type"){
                reader.readString(this, "type");
                return;
            }

            if (name === "data"){
                reader.readString(this, "data");
                return;
            }

            if (name === "hide"){
                reader.readBoolean(this, "hide");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("type", this.type);
            writer.writeString("data", this.data);
            writer.writeBoolean("hide", this.hide);
            writer.endObject(ctx);
        }
    }
    export class IntroSplashComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[IntroSplashComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[IntroSplashComponent]", this);
        }
    }
    export class FreezeTransformComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.freezeOnUnity = false;
            this.freezeOnWeb = false;
        }
        freezeOnUnity: boolean;
        freezeOnWeb: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[FreezeTransformComponent]", this);
            writer.writeBool(this.freezeOnUnity);
            writer.writeBool(this.freezeOnWeb);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[FreezeTransformComponent]", this);
            this.freezeOnUnity = reader.readBool();
            this.freezeOnWeb = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "freezeOnUnity"){
                reader.readBoolean(this, "freezeOnUnity");
                return;
            }

            if (name === "freezeOnWeb"){
                reader.readBoolean(this, "freezeOnWeb");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("freezeOnUnity", this.freezeOnUnity);
            writer.writeBoolean("freezeOnWeb", this.freezeOnWeb);
            writer.endObject(ctx);
        }
    }
    export class SpotLightComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.intensity = 0;
            this.range = 0;
            this.radius = 0;
            this.diffuseR = 0;
            this.diffuseG = 0;
            this.diffuseB = 0;
            this.specularR = 0;
            this.specularG = 0;
            this.specularB = 0;
            this.angle = 0;
        }
        intensity: number;
        range: number;
        radius: number;
        diffuseR: number;
        diffuseG: number;
        diffuseB: number;
        specularR: number;
        specularG: number;
        specularB: number;
        angle: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[SpotLightComponent]", this);
            writer.writeDouble(this.intensity);
            writer.writeDouble(this.range);
            writer.writeDouble(this.radius);
            writer.writeDouble(this.diffuseR);
            writer.writeDouble(this.diffuseG);
            writer.writeDouble(this.diffuseB);
            writer.writeDouble(this.specularR);
            writer.writeDouble(this.specularG);
            writer.writeDouble(this.specularB);
            writer.writeDouble(this.angle);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[SpotLightComponent]", this);
            this.intensity = reader.readDouble();
            this.range = reader.readDouble();
            this.radius = reader.readDouble();
            this.diffuseR = reader.readDouble();
            this.diffuseG = reader.readDouble();
            this.diffuseB = reader.readDouble();
            this.specularR = reader.readDouble();
            this.specularG = reader.readDouble();
            this.specularB = reader.readDouble();
            this.angle = reader.readDouble();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "intensity"){
                reader.readDouble(this, "intensity");
                return;
            }

            if (name === "range"){
                reader.readDouble(this, "range");
                return;
            }

            if (name === "radius"){
                reader.readDouble(this, "radius");
                return;
            }

            if (name === "diffuseR"){
                reader.readDouble(this, "diffuseR");
                return;
            }

            if (name === "diffuseG"){
                reader.readDouble(this, "diffuseG");
                return;
            }

            if (name === "diffuseB"){
                reader.readDouble(this, "diffuseB");
                return;
            }

            if (name === "specularR"){
                reader.readDouble(this, "specularR");
                return;
            }

            if (name === "specularG"){
                reader.readDouble(this, "specularG");
                return;
            }

            if (name === "specularB"){
                reader.readDouble(this, "specularB");
                return;
            }

            if (name === "angle"){
                reader.readDouble(this, "angle");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeDouble("intensity", this.intensity);
            writer.writeDouble("range", this.range);
            writer.writeDouble("radius", this.radius);
            writer.writeDouble("diffuseR", this.diffuseR);
            writer.writeDouble("diffuseG", this.diffuseG);
            writer.writeDouble("diffuseB", this.diffuseB);
            writer.writeDouble("specularR", this.specularR);
            writer.writeDouble("specularG", this.specularG);
            writer.writeDouble("specularB", this.specularB);
            writer.writeDouble("angle", this.angle);
            writer.endObject(ctx);
        }
    }
    export class PointLightComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.intensity = 0;
            this.range = 0;
            this.radius = 0;
            this.diffuseR = 0;
            this.diffuseG = 0;
            this.diffuseB = 0;
            this.specularR = 0;
            this.specularG = 0;
            this.specularB = 0;
        }
        intensity: number;
        range: number;
        radius: number;
        diffuseR: number;
        diffuseG: number;
        diffuseB: number;
        specularR: number;
        specularG: number;
        specularB: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[PointLightComponent]", this);
            writer.writeDouble(this.intensity);
            writer.writeDouble(this.range);
            writer.writeDouble(this.radius);
            writer.writeDouble(this.diffuseR);
            writer.writeDouble(this.diffuseG);
            writer.writeDouble(this.diffuseB);
            writer.writeDouble(this.specularR);
            writer.writeDouble(this.specularG);
            writer.writeDouble(this.specularB);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[PointLightComponent]", this);
            this.intensity = reader.readDouble();
            this.range = reader.readDouble();
            this.radius = reader.readDouble();
            this.diffuseR = reader.readDouble();
            this.diffuseG = reader.readDouble();
            this.diffuseB = reader.readDouble();
            this.specularR = reader.readDouble();
            this.specularG = reader.readDouble();
            this.specularB = reader.readDouble();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "intensity"){
                reader.readDouble(this, "intensity");
                return;
            }

            if (name === "range"){
                reader.readDouble(this, "range");
                return;
            }

            if (name === "radius"){
                reader.readDouble(this, "radius");
                return;
            }

            if (name === "diffuseR"){
                reader.readDouble(this, "diffuseR");
                return;
            }

            if (name === "diffuseG"){
                reader.readDouble(this, "diffuseG");
                return;
            }

            if (name === "diffuseB"){
                reader.readDouble(this, "diffuseB");
                return;
            }

            if (name === "specularR"){
                reader.readDouble(this, "specularR");
                return;
            }

            if (name === "specularG"){
                reader.readDouble(this, "specularG");
                return;
            }

            if (name === "specularB"){
                reader.readDouble(this, "specularB");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeDouble("intensity", this.intensity);
            writer.writeDouble("range", this.range);
            writer.writeDouble("radius", this.radius);
            writer.writeDouble("diffuseR", this.diffuseR);
            writer.writeDouble("diffuseG", this.diffuseG);
            writer.writeDouble("diffuseB", this.diffuseB);
            writer.writeDouble("specularR", this.specularR);
            writer.writeDouble("specularG", this.specularG);
            writer.writeDouble("specularB", this.specularB);
            writer.endObject(ctx);
        }
    }
    export class DirectionalLightComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.intensity = 0;
            this.range = 0;
            this.radius = 0;
            this.diffuseR = 0;
            this.diffuseG = 0;
            this.diffuseB = 0;
            this.specularR = 0;
            this.specularG = 0;
            this.specularB = 0;
        }
        intensity: number;
        range: number;
        radius: number;
        diffuseR: number;
        diffuseG: number;
        diffuseB: number;
        specularR: number;
        specularG: number;
        specularB: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[DirectionalLightComponent]", this);
            writer.writeDouble(this.intensity);
            writer.writeDouble(this.range);
            writer.writeDouble(this.radius);
            writer.writeDouble(this.diffuseR);
            writer.writeDouble(this.diffuseG);
            writer.writeDouble(this.diffuseB);
            writer.writeDouble(this.specularR);
            writer.writeDouble(this.specularG);
            writer.writeDouble(this.specularB);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[DirectionalLightComponent]", this);
            this.intensity = reader.readDouble();
            this.range = reader.readDouble();
            this.radius = reader.readDouble();
            this.diffuseR = reader.readDouble();
            this.diffuseG = reader.readDouble();
            this.diffuseB = reader.readDouble();
            this.specularR = reader.readDouble();
            this.specularG = reader.readDouble();
            this.specularB = reader.readDouble();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "intensity"){
                reader.readDouble(this, "intensity");
                return;
            }

            if (name === "range"){
                reader.readDouble(this, "range");
                return;
            }

            if (name === "radius"){
                reader.readDouble(this, "radius");
                return;
            }

            if (name === "diffuseR"){
                reader.readDouble(this, "diffuseR");
                return;
            }

            if (name === "diffuseG"){
                reader.readDouble(this, "diffuseG");
                return;
            }

            if (name === "diffuseB"){
                reader.readDouble(this, "diffuseB");
                return;
            }

            if (name === "specularR"){
                reader.readDouble(this, "specularR");
                return;
            }

            if (name === "specularG"){
                reader.readDouble(this, "specularG");
                return;
            }

            if (name === "specularB"){
                reader.readDouble(this, "specularB");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeDouble("intensity", this.intensity);
            writer.writeDouble("range", this.range);
            writer.writeDouble("radius", this.radius);
            writer.writeDouble("diffuseR", this.diffuseR);
            writer.writeDouble("diffuseG", this.diffuseG);
            writer.writeDouble("diffuseB", this.diffuseB);
            writer.writeDouble("specularR", this.specularR);
            writer.writeDouble("specularG", this.specularG);
            writer.writeDouble("specularB", this.specularB);
            writer.endObject(ctx);
        }
    }
    export class AttachedMediaComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[AttachedMediaComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[AttachedMediaComponent]", this);
        }
    }
    export class ContextMenuComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ContextMenuComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ContextMenuComponent]", this);
        }
    }
    export class MeshColliderComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[MeshColliderComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[MeshColliderComponent]", this);
        }
    }
    export class CollisionMeshComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.identification = "";
        }
        identification: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[CollisionMeshComponent]", this);
            writer.writeString(this.identification);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[CollisionMeshComponent]", this);
            this.identification = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "identification"){
                reader.readString(this, "identification");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("identification", this.identification);
            writer.endObject(ctx);
        }
    }
    export class ScenarioTracerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.currentScenario = "";
        }
        currentScenario: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ScenarioTracerComponent]", this);
            writer.writeString(this.currentScenario);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ScenarioTracerComponent]", this);
            this.currentScenario = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "currentScenario"){
                reader.readString(this, "currentScenario");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("currentScenario", this.currentScenario);
            writer.endObject(ctx);
        }
    }
    export class ScenarioCleanerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ScenarioCleanerComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ScenarioCleanerComponent]", this);
        }
    }
    export class AIComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[AIComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[AIComponent]", this);
        }
    }
    export class WebRtcComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.sndContext = "";
            this.isActive = false;
            this.video = false;
            this.audio = false;
        }
        sndContext: string;
        isActive: boolean;
        video: boolean;
        audio: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[WebRtcComponent]", this);
            writer.writeString(this.sndContext);
            writer.writeBool(this.isActive);
            writer.writeBool(this.video);
            writer.writeBool(this.audio);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[WebRtcComponent]", this);
            this.sndContext = reader.readString();
            this.isActive = reader.readBool();
            this.video = reader.readBool();
            this.audio = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "sndContext"){
                reader.readString(this, "sndContext");
                return;
            }

            if (name === "isActive"){
                reader.readBoolean(this, "isActive");
                return;
            }

            if (name === "video"){
                reader.readBoolean(this, "video");
                return;
            }

            if (name === "audio"){
                reader.readBoolean(this, "audio");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("sndContext", this.sndContext);
            writer.writeBoolean("isActive", this.isActive);
            writer.writeBoolean("video", this.video);
            writer.writeBoolean("audio", this.audio);
            writer.endObject(ctx);
        }
    }
    export class MainMenuComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[MainMenuComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[MainMenuComponent]", this);
        }
    }
    export class SavedCO2CalculatorComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.contributors = [];
        }
        contributors: string[];
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[SavedCO2CalculatorComponent]", this);
            for (var i = 0; i < this.contributors.length; ++i)
                writer.writeString(this.contributors[i]);

        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[SavedCO2CalculatorComponent]", this);
            for (var i = 0; i < this.contributors.length; ++i)
                this.contributors[i] = reader.readString();

        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "contributors" && type === Vertex.BsonType.Array){
                reader.readArray(this, "contributors", reader.readString);
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeArray("contributors", this.contributors, writer.writeString);
            writer.endObject(ctx);
        }
    }
    export class SavedCO2VisualizerComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.tenantInfoResPublishedId = "";
        }
        tenantInfoResPublishedId: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[SavedCO2VisualizerComponent]", this);
            writer.writeString(this.tenantInfoResPublishedId);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[SavedCO2VisualizerComponent]", this);
            this.tenantInfoResPublishedId = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "tenantInfoResPublishedId"){
                reader.readString(this, "tenantInfoResPublishedId");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("tenantInfoResPublishedId", this.tenantInfoResPublishedId);
            writer.endObject(ctx);
        }
    }
    export class Text2DComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.text = "";
            this.font = "";
            this.color = "";
            this.size = 0;
            this.bold = false;
            this.italic = false;
            this.underlined = false;
            this.strikethrough = false;
            this.textAlignment = 0;
        }
        text: string;
        font: string;
        color: string;
        size: number;
        bold: boolean;
        italic: boolean;
        underlined: boolean;
        strikethrough: boolean;
        textAlignment: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[Text2DComponent]", this);
            writer.writeString(this.text);
            writer.writeString(this.font);
            writer.writeString(this.color);
            writer.writeDouble(this.size);
            writer.writeBool(this.bold);
            writer.writeBool(this.italic);
            writer.writeBool(this.underlined);
            writer.writeBool(this.strikethrough);
            writer.writeInt32(this.textAlignment);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[Text2DComponent]", this);
            this.text = reader.readString();
            this.font = reader.readString();
            this.color = reader.readString();
            this.size = reader.readDouble();
            this.bold = reader.readBool();
            this.italic = reader.readBool();
            this.underlined = reader.readBool();
            this.strikethrough = reader.readBool();
            this.textAlignment = reader.readInt32();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "text"){
                reader.readString(this, "text");
                return;
            }

            if (name === "font"){
                reader.readString(this, "font");
                return;
            }

            if (name === "color"){
                reader.readString(this, "color");
                return;
            }

            if (name === "size"){
                reader.readDouble(this, "size");
                return;
            }

            if (name === "bold"){
                reader.readBoolean(this, "bold");
                return;
            }

            if (name === "italic"){
                reader.readBoolean(this, "italic");
                return;
            }

            if (name === "underlined"){
                reader.readBoolean(this, "underlined");
                return;
            }

            if (name === "strikethrough"){
                reader.readBoolean(this, "strikethrough");
                return;
            }

            if (name === "textAlignment"){
                reader.readInt32(this, "textAlignment");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("text", this.text);
            writer.writeString("font", this.font);
            writer.writeString("color", this.color);
            writer.writeDouble("size", this.size);
            writer.writeBoolean("bold", this.bold);
            writer.writeBoolean("italic", this.italic);
            writer.writeBoolean("underlined", this.underlined);
            writer.writeBoolean("strikethrough", this.strikethrough);
            writer.writeInt32("textAlignment", this.textAlignment);
            writer.endObject(ctx);
        }
    }
    export class MusicBackgroundComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.currentSongUrl = "";
        }
        currentSongUrl: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[MusicBackgroundComponent]", this);
            writer.writeString(this.currentSongUrl);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[MusicBackgroundComponent]", this);
            this.currentSongUrl = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "currentSongUrl"){
                reader.readString(this, "currentSongUrl");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("currentSongUrl", this.currentSongUrl);
            writer.endObject(ctx);
        }
    }
    export class PriorityComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.priorityIndex = 0;
        }
        priorityIndex: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[PriorityComponent]", this);
            writer.writeInt32(this.priorityIndex);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[PriorityComponent]", this);
            this.priorityIndex = reader.readInt32();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "priorityIndex"){
                reader.readInt32(this, "priorityIndex");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeInt32("priorityIndex", this.priorityIndex);
            writer.endObject(ctx);
        }
    }
    export class AudioSourceComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.fileName = "";
            this.loop = false;
            this.autoplay = false;
            this.isPlaying = false;
            this.volume = 0;
            this.isSpatial = false;
            this.maxDistance = 0;
        }
        fileName: string;
        loop: boolean;
        autoplay: boolean;
        isPlaying: boolean;
        volume: number;
        isSpatial: boolean;
        maxDistance: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[AudioSourceComponent]", this);
            writer.writeString(this.fileName);
            writer.writeBool(this.loop);
            writer.writeBool(this.autoplay);
            writer.writeBool(this.isPlaying);
            writer.writeDouble(this.volume);
            writer.writeBool(this.isSpatial);
            writer.writeDouble(this.maxDistance);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[AudioSourceComponent]", this);
            this.fileName = reader.readString();
            this.loop = reader.readBool();
            this.autoplay = reader.readBool();
            this.isPlaying = reader.readBool();
            this.volume = reader.readDouble();
            this.isSpatial = reader.readBool();
            this.maxDistance = reader.readDouble();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "fileName"){
                reader.readString(this, "fileName");
                return;
            }

            if (name === "loop"){
                reader.readBoolean(this, "loop");
                return;
            }

            if (name === "autoplay"){
                reader.readBoolean(this, "autoplay");
                return;
            }

            if (name === "isPlaying"){
                reader.readBoolean(this, "isPlaying");
                return;
            }

            if (name === "volume"){
                reader.readDouble(this, "volume");
                return;
            }

            if (name === "isSpatial"){
                reader.readBoolean(this, "isSpatial");
                return;
            }

            if (name === "maxDistance"){
                reader.readDouble(this, "maxDistance");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("fileName", this.fileName);
            writer.writeBoolean("loop", this.loop);
            writer.writeBoolean("autoplay", this.autoplay);
            writer.writeBoolean("isPlaying", this.isPlaying);
            writer.writeDouble("volume", this.volume);
            writer.writeBoolean("isSpatial", this.isSpatial);
            writer.writeDouble("maxDistance", this.maxDistance);
            writer.endObject(ctx);
        }
    }
    export class FocusComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.follow = false;
        }
        follow: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[FocusComponent]", this);
            writer.writeBool(this.follow);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[FocusComponent]", this);
            this.follow = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "follow"){
                reader.readBoolean(this, "follow");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("follow", this.follow);
            writer.endObject(ctx);
        }
    }
    export class HiddenMeshComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.disableColliders = false;
        }
        disableColliders: boolean;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[HiddenMeshComponent]", this);
            writer.writeBool(this.disableColliders);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[HiddenMeshComponent]", this);
            this.disableColliders = reader.readBool();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "disableColliders"){
                reader.readBoolean(this, "disableColliders");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeBoolean("disableColliders", this.disableColliders);
            writer.endObject(ctx);
        }
    }
    export class PointsOfInterestComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
        }
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[PointsOfInterestComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[PointsOfInterestComponent]", this);
        }
    }
    export class TokenMiddlemanComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.onRequestToken = new Vertex.NodeComponentModel.RPCEventBus("OnRequestToken", TokenMiddlemanMessage);
            this.events.set(this.onRequestToken.eventName, this.onRequestToken);
        }
        onRequestToken: Vertex.NodeComponentModel.RPCEventBus<TokenMiddlemanMessage>;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[TokenMiddlemanComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[TokenMiddlemanComponent]", this);
        }
    }
    export class ActionSettingsComponent extends Vertex.NodeComponentModel.Component {
        constructor() {
            super();
            this.onActionTriggered = new Vertex.NodeComponentModel.RPCEventBus("OnActionTriggered", ActionMessage);
            this.events.set(this.onActionTriggered.eventName, this.onActionTriggered);
        }
        onActionTriggered: Vertex.NodeComponentModel.RPCEventBus<ActionMessage>;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ActionSettingsComponent]", this);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ActionSettingsComponent]", this);
        }
    }
    export class DestructorMessage extends Vertex.NodeComponentModel.Structure {
        constructor() {
            super();
            this.message = "";
        }
        message: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[DestructorMessage]", this);
            writer.writeString(this.message);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[DestructorMessage]", this);
            this.message = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "Message"){
                reader.readString(this, "message");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("Message", this.message);
            writer.endObject(ctx);
        }
    }
    export class TokenMiddlemanMessage extends Vertex.NodeComponentModel.Structure {
        constructor() {
            super();
            this.message = "";
        }
        message: string;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[TokenMiddlemanMessage]", this);
            writer.writeString(this.message);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[TokenMiddlemanMessage]", this);
            this.message = reader.readString();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "Message"){
                reader.readString(this, "message");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeString("Message", this.message);
            writer.endObject(ctx);
        }
    }
    export class ActionMessage extends Vertex.NodeComponentModel.Structure {
        constructor() {
            super();
            this.actionIndex = 0;
        }
        actionIndex: number;
        writeData(writer: Vertex.BinaryWriter, delta: boolean): void {
            super.writeData(writer, delta);
            writer.setDebugState("[ActionMessage]", this);
            writer.writeInt32(this.actionIndex);
        }
        readData(reader: Vertex.BinaryReader): void {
            super.readData(reader);
            reader.setDebugState("[ActionMessage]", this);
            this.actionIndex = reader.readInt32();
        }
        bsonReadAction(name: string, type: Vertex.BsonType, reader: Vertex.BSONReader) : void {
            if (name === "actionIndex"){
                reader.readInt32(this, "actionIndex");
                return;
            }

        }
        readDataBSON(reader: Vertex.BSONReader): void {
            reader.readObject(this.bsonReadAction.bind(this));
        }
        writeDataBSON(writer: Vertex.BSONWriter): void {
            let ctx = writer.startObject();
            writer.writeInt32("actionIndex", this.actionIndex);
            writer.endObject(ctx);
        }
    }
}
