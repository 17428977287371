import { DEFAULT_SLIDESHOW_SECONDS } from "../../utilities/constants";
import { Utils } from "../../utilities/utils";

export class CustomNumberfieldInspector implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode, min : number = null, max : number = null, integer: boolean = false) {
        this.targetNode = targetNode;
        this.integer = integer;
        
        if(min != null && max != null && min <= max ||
            min != null && max == null){
            this.min = this.integer ? Math.round(min) : min;
        }

        if(max != null && min != null && max >= min ||
            max != null && min == null){
            this.max = this.integer ? Math.round(max) : max;
        }
    }
    
    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: Vertex.NodeComponentModel.Component;
    property: string;
    min : number;
    max : number;
    integer: boolean;

    row = document.createElement("div");

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        //Assuming property is "shouldPlay"
        this.property = property;

        //Get GLTF
        let animComp = null;// target as CustomAnimationComponent;
        
       // this.renderAnimationList(outer, animComp);

        let row = document.createElement("div"); row.classList.add("row", "control-group","input-row","align-items-center","pad-border-bottom");
        let nameCol = document.createElement("div"); nameCol.classList.add("col", "control-label"); nameCol.innerText = Utils.getHumanReadableString(property); row.appendChild(nameCol);
        let inputCol = document.createElement("div"); inputCol.classList.add("col-3", "control-value");
        let inputField = document.createElement("input"); inputField.type = "number"; inputField.autocomplete = "off"; 
        inputField.spellcheck = false; inputField.classList.add("form-control");
        inputField.value = target[property];

        if(this.min != null){
            inputField.setAttribute("min", this.min.toString());
        }

        if(this.max != null){
            inputField.setAttribute("max", this.max.toString());
        }

        inputField.addEventListener("change", (event) => {
            if(!inputField.value){
                inputField.value = target[property];
                return;
            }

            let value = parseFloat(inputField.value);
            
            if(this.integer){
                value = Math.round(value);
            }
            
            if (this.max != null && value > this.max){
                value = this.max;
            }

            if(this.min != null && value < this.min){
                value = this.min;
            }

            inputField.value = value.toString();

            target[property] = inputField.value;
            target.triggerOnChanged();
        });

        inputCol.appendChild(inputField);
        row.appendChild(inputCol);

        return row;
    }
}