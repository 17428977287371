import { Config } from "../../config";
import { ObjectModel, Project, ProjectParams } from "../api/amaz3d/amaz3d-types";
import { GltfStructure } from "./gltf-utilities";

export const MAX_RESOURCE_NAME_LENGTH = 30;
export const MAX_POI_NAME_LENGTH = 30;
export const MAX_TAG_LENGTH = 30;
export const MAX_QR_NAME_LENGTH = 30;
export const MAX_QR_DESCRIPTION_LENGTH = 200;

export const MAX_PRESET_CATEGORY_NAME_LENGTH = 30;
export const MAX_PRESET_NAME_LENGTH = 30;

export const MAX_AI_DOCUMENT_SIZE = 20971520; //20MB
export const MAX_ALLOWED_AI_DOCUMENTS = 20;

/** PORTAL INFO */
export const PORTAL_VERSION = "1.6.0"

/** CHANGE MATERIAL MODEL */
export const CHANGE_MATERIAL_MODEL_FILENAME = "skins.json";
export const CURRENT_CHANGE_MATERIAL_MODEL_VERSION = "3"; 
export const MIN_CHANGE_MATERIAL_MODEL_VERSION = "2"; 

 /** IMAGERY */
export const GENERIC_THUMBNAIL_IMG_URI = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABmJLR0QA/wD/AP+gvaeTAAAAC0lEQVQI12NgAAIAAAUAAeImBZsAAAAASUVORK5CYII=";
export const SPACE_THUMB_URI = "/img/space_thumb.jpg";
export const DECK_THUMB_URI  = "/img/deck_thumb.jpg";
export const ROOM_THUMB_URI  = "/img/room_thumb.jpg";
export const MODEL_THUMB_URI = "/img/model_thumb.jpg";
export const MEDIA_THUMB_URI = "/img/media_thumb.jpg";
export const BASE_THUMB_URI  = "/img/base_thumb.jpg";
export const MISSING_TEXTURE_MATERIAL_PREVIEW_IMG_URI = "/img/texture.png";
export const SET_THUMB_ICON_IMG_URI = "/img/set-icon-thumb.png";
export const GLTF_NODE_IMG_URI = "/img/item.svg";
export const GLTF_PARENT_NODE_IMG_URI = "/img/group.svg";

/** IN-APP URIs */
export const SPACE_EDITOR_URL = "/scene-editor/";
export const MODEL_EDITOR_URL = "/model-editor/";

/** EXTERNAL APP URLS */
export const HOLOMUSEUM_DOMAIN = "https://holomuseum.azurewebsites.net/";
export const REMOTESELLING_DOMAIN = "https://remoteselling.azurewebsites.net/";
export const HOLOPROTOTYPE_DOMAIN = "https://holoprototype.azurewebsites.net/";
export const HEVOCOLLABORATION_DOMAIN = "https://hevocollaboration.azurewebsites.net/";
export const DECK_EDITOR_URL = "https://hevocollaboration-deckeditor.azurewebsites.net/";
export const SURFACEHUB_DOMAIN = "https://hevocollaboration-hub.azurewebsites.net/";
export const H_SHARE_DOMAIN = "https://hshare.hevolus.it/";
export const MEDIA_CONVERTER_LOGIC_APP_URL = "https://prod-05.switzerlandnorth.logic.azure.com:443/workflows/b8d70a51be8e429c98eb085653c15ab3/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=YRcDt3i3CamrmSnCopwPkS0fgGvGGN9usOaW7WQgo_Q";

/** SPATIAL SERVICES */
export const VERTEX_AOA_PLATFORM = "VTX-AOA";
export const VERTEX_QR_PLATFORM = "VTX-QR";
export const VERTEX_SPATIAL_SERVICE = "spatial";

export const DEFAULT_QR_ANCHOR_POS: string = "pos=0,0,0";
export const DEFAULT_QR_ANCHOR_ROT: string = "rot=0,0,0";
export const DEFAULT_QR_ANCHOR_PARAMS: string = "?" + DEFAULT_QR_ANCHOR_POS + "&" + DEFAULT_QR_ANCHOR_ROT;

/** MEDIA SETTINGS */
 export const DEFAULT_MEDIA_VOLUME: number = 50;
 export const MAXIMUM_VIDEO_SIZE_IN_BYTES: number = 209715200; //200MB
 export const B_TO_MB_FACTOR = 1024 * 1024;

/** CAMERA SETTINGS */
export enum BetaLimitRad {
    Quarter = Math.PI / 2.0,
    Half = Math.PI,
    Zero = 0,
    Free = null
}

export enum BetaLimitDeg {
    Quarter = 90,
    Half = 180,
    Zero = 0
}

export const PREVIEW_MODE_CAMERA_LOWER_RADIUS_LIMIT: number = 1.9;
export const PREVIEW_MODE_CAMERA_UPPER_RADIUS_LIMIT: number = 10;
export const CAMERA_RADIUS_THRESHOLD = 2;
export const CAMERA_RADIUS = 3;
export const CAMERA_PANNING_SENSIBILITY = 1000;
export const CAMERA_PANNING_SENSIBILITY_MIN = 1;
export const CAMERA_PANNING_SENSIBILITY_MAX = 1000;
export const CAMERA_INERTIA = 0.5;
export const CAMERA_PANNING_INERTIA = 0.5;
export const CAMERA_WHEEL_PRECISION = 180;
export const CAMERA_WHEEL_PRECISION_MIN = 1;
export const CAMERA_WHEEL_PRECISION_MAX = 300;
export const CAMERA_PINCH_PRECISION = 250;
export const CAMERA_PINCH_PRECISION_MIN = 1;
export const CAMERA_PINCH_PRECISION_MAX = 300;
export const CAMERA_MIN_Z = 0.1;
export const CAMERA_MIN_Z_MIN = 0.01;
export const CAMERA_MIN_Z_MAX = 0.85;
export const CAMERA_MAX_Z = 100;
export const CAMERA_MAX_Z_MIN = 1;
export const CAMERA_MAX_Z_MAX = 1000;
export const CAMERA_FOV = 0.8;
export const CAMERA_FOV_MIN = 0.3;
export const CAMERA_FOV_MAX = 0.8;
export const CAMERA_LOWER_RADIUS_LIMIT = 0.1;
export const CAMERA_UPPER_RADIUS_LIMIT = 100;
export const CAMERA_LOWER_BETA_LIMIT = 0;
export const CAMERA_UPPER_BETA_LIMIT = BetaLimitDeg.Quarter;
export const CAMERA_ANGULAR_SENSIBILITY_X = 500;
export const CAMERA_ANGULAR_SENSIBILITY_Y = 500;
export const CAMERA_INERTIAL_RADIUS_OFFSET = 0;
export const CAMERA_ZOOM_ON_FACTOR = 0.0002;

/** SKYBOX SETTINGS */
export const SKYBOX_SIZE_RATIO = 0.98;
export const SKYBOX_MAX_SIZE_BYTES = 68157440;
export const MAXIMUM_SKYBOX_LOAD_TIME_IN_MS = 25000;


/** PLATFORM NATIVE EXTENSIONS */
export const PLATFORM_NATIVE_MODEL_TYPES = ["gltf"];
export const PLATFORM_NATIVE_MODEL_BUFFER_TYPES = ["bin"];
export const ALLOWED_IMAGE_TYPES = ["jpg", "jpeg", "png"];
export const ALLOWED_AUDIO_TYPES = ["mp3"];
export const ALLOWED_VIDEO_TYPES = ["webm", "mp4"];
export const ALLOWED_DOC_TYPES = ["txt"];
export const ALLOWED_PDF_TYPES = ["pdf"];
export const ALLOWED_LIGHTMAPS_TYPES = ["jpg", "jpeg", "png", "hdr"];
export const ALLOWED_SKYBOX_TYPES = ["hdr"];
export const ALLOWED_INTROSPLASH_TYPES = ALLOWED_IMAGE_TYPES.concat(ALLOWED_VIDEO_TYPES);
export const ALLOWED_ATTACHED_TYPES = ALLOWED_IMAGE_TYPES.concat(ALLOWED_AUDIO_TYPES).concat(ALLOWED_VIDEO_TYPES).concat(ALLOWED_DOC_TYPES).concat(ALLOWED_PDF_TYPES);
export const DDS_TEXTURE_EXTENSION = "dds";
export const KHRONOS_TEXTURE_EXTENSION = "ktx";
export const PLATFORM_NATIVE_COMPRESSED_IMAGE_SUFFIXES = ["-etc2", "-pvrtc"];
export const HDR_TEXTURE_EXTENSION = "hdr";
export const ENVIRONMENT_TEXTURE_EXTENSION = "env";

/** 3D CONVERTERS SUPPORTED EXTENSIONS */
export const DEFAULT_3D_CONVERTER_EXTENSIONS = ["fbx", "obj"];
export const VERTX_STP_CONVERTER_EXTENSIONS = ["stp"]
export const ADVANCED_3D_CONVERTER_EXTENSIONS = ["gltf", "glb", "fbx", "obj", "stp", "stl"];


/** SUPPORTED FORMAT EXTENSIONS */
export const SUPPORTED_AI_EXTERNAL_DATA_SOURCE_CONFIG_TYPES = ["json"];
export const SUPPORTED_AI_DOC_TYPES = ["pdf", "txt", "md", "xlsx", "csv", "docx"];
export const SUPPORTED_AI_AUDIO_TYPES = ["mp3", "wav", "opus", "flac", "m4a", "wma", "aac", "amr","speex"];

export const COMPRESSED_IMAGE_TYPES = ["dds", "ktx"];
export const SUPPORTED_AUDIO_FORMATS: string[] = ["opus", "mp2", "mp3", "aac", "wma", "mka", "ogg", "wav", "m4a"];
export const SUPPORTED_VIDEO_FORMATS: string[] = ["webm", "asf", "avi", "ast", "dv", "flv",
    "3gp", "3gp2", "mp2", "mp4", "mkv", "mk3d", "mka", "mks", "mxf", "wtv"];
export const MEDIA_CONVERSION_STATUS_POLLING_TIME_IN_MS = 2000;

export const SUPPORTED_ATTACHED_TYPES = ALLOWED_IMAGE_TYPES.concat(ALLOWED_DOC_TYPES).concat(ALLOWED_PDF_TYPES).concat(SUPPORTED_AUDIO_FORMATS).concat(SUPPORTED_VIDEO_FORMATS);
export const SUPPORTED_INTROSPLASH_TYPES = SUPPORTED_VIDEO_FORMATS.concat(ALLOWED_IMAGE_TYPES);

export const MODEL_3D_VALID_EXTENSIONS = Array.from(new Set(PLATFORM_NATIVE_MODEL_TYPES.concat(DEFAULT_3D_CONVERTER_EXTENSIONS).concat(VERTX_STP_CONVERTER_EXTENSIONS).concat(ADVANCED_3D_CONVERTER_EXTENSIONS)));
export const MEDIA_VALID_EXTENSIONS = Array.from(new Set(ALLOWED_IMAGE_TYPES.concat(ALLOWED_DOC_TYPES).concat(ALLOWED_PDF_TYPES).concat(SUPPORTED_AUDIO_FORMATS).concat(SUPPORTED_VIDEO_FORMATS)));
export const BASE_VALID_EXTENSIONS = MODEL_3D_VALID_EXTENSIONS.concat(PLATFORM_NATIVE_MODEL_BUFFER_TYPES);
export const MODEL_VALID_EXTENSIONS = BASE_VALID_EXTENSIONS.concat(MEDIA_VALID_EXTENSIONS);//.concat(Utils.ALLOWED_MODEL_BUFFER_TYPES);

export const MAX_ALLOWED_MODEL_FILES = 1;


/** VERTX STEP CONVERTER SETTINGS */
export const DEFAULT_STP2GLTF_TOLERANCE_IN_MM: number = 1;
export const STP2GLTF_TOLERANCE_STEP_IN_MM: number = 1;
export const STP2GLTF_TOLERANCE_MIN_IN_MM: number = 1;
export const STP2GLTF_TOLERANCE_MAX_IN_MM: number = 1000;

export const DEFAULT_STP2GLTF_ANGULAR_PRECISION_IN_DEG: number = 2;
export const STP2GLTF_ANGULAR_PRECISION_STEP_IN_DEG: number = 0.1;
export const STP2GLTF_ANGULAR_PRECISION_MIN_IN_DEG: number = 0.1;
export const STP2GLTF_ANGULAR_PRECISION_MAX_IN_DEG: number = 90;

export const DEFAULT_STP2GLTF_NORMAL_ANGLE_IN_DEG: number = 30;
export const STP2GLTF_NORMAL_ANGLE_STEP_IN_DEG: number = 0.1;
export const STP2GLTF_NORMAL_ANGLE_MIN_IN_DEG: number = 0.1;
export const STP2GLTF_NORMAL_ANGLE_MAX_IN_DEG: number = 90;


/** SYSTEM FILENAMES */
export const MODEL_INFO_FILENAME = "model-info.json";
export const MESH_GLTF_FILENAME = "mesh.gltf";
export const MESH_GLB_FILENAME = "mesh.glb";
export const AOA_MODEL_FILENAME = "model.bin";
export const MESH_GLTF_MANIFEST_FILENAME = "mesh.gltf.manifest";
export const SPACE_MANIFEST_FILENAME = "space-manifest.json";
export const VERTEX_RESOURCE_FILENAME = "vertex-resource.json";
export const AS_CUSTOM_UI_SETTINGS_FILENAME = "as-ui-custom.json";
export const AS_PUBLIC_CATALOG_FILENAME = "as-public-catalog.json";
export const AS_VERSE_CATALOG_FILENAME = "as-verse-catalog.json";
export const SUSTAINABILITY_FILENAME = "sustainability.json";
export const AVATAR_SETTINGS_FILENAME = "avatar-settings.json";
export const H_SHARE_SETTINGS_FILENAME = "h-share-settings.json";
export const AVATAR_T_SHIRT_TEXTURE_FILENAME = "tshirt.mp2";
export const TENANT_INFO_RESOURCE_NAME = "Tenant Info";
export const GENERATED_ZIP_FILENAME = "zip.zip";
export const SERIALIZED_SPACE_FILENAME = "scene.bin";
export const SERIALIZED_PREVIEW_SPACE_FILENAME = "preview_scene.bin";
export const DEFAULT_SKYBOX_FILENAME = "default-skybox.dds";
export const CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION = "babylon-env";
export const RESOURCE_THUMB_FILENAME = "thumb.png";
export const RESOURCE_THUMB_WSA_FILENAME = "thumb.dds";
export const RESOURCE_THUMB_ANDROID_FILENAME = "thumb-etc2.ktx";
export const RESOURCE_THUMB_IOS_FILENAME = "thumb-pvrtc.ktx";
export const RESOURCE_THUMB_ALL_PLATFORM_FILENAMES = [RESOURCE_THUMB_FILENAME, RESOURCE_THUMB_WSA_FILENAME, RESOURCE_THUMB_ANDROID_FILENAME, RESOURCE_THUMB_IOS_FILENAME];
export const QR_CATALOG_RESOURCE_NAME = "qr catalog";
export const HOLOPROTOTYPE_BASE_METADATA_FILENAME = "holoprototype-metadata.json";
export const TAGS_CATALOG_FILENAME = "tags.json";
export const QRS_CATALOG_FILENAME = "qrs.json";
export const SUSTAINABILITY_RESOURCE_NAME = "sustainability.json";
export const MEDIA_CONVERSION_STATUS_FILENAME = "media-conversion-status.json";

/** VERTEX RESOURCE API BASE URI */
export const RESOURCE_API_URI = `https://${Config.VERTEX_URL_BASE}/core/resource/`;

/** SYSTEM TYPE TAGS */
export const TYPE_MODEL_TAG = "sys-type-model";
export const TYPE_BASE_TAG = "sys-type-base";
export const TYPE_SPACE_TAG = "sys-type-space";
export const TYPE_ROOM_TAG = "sys-type-room";
export const TYPE_MEDIA_TAG = "sys-type-media";
export const TYPE_CATALOG_TAG = "sys-type-catalog";
export const TYPE_DECK_TAG = "sys-type-deck";
export const TYPE_AVATAR_TAG = "sys-type-avatar";
export const TYPE_SYSTEM_TAG = "sys-type-system";

/** SYSTEM CONTENT TAGS */
export const CONTENT_IMAGE_TAG = "sys-content-image";
export const CONTENT_VIDEO_TAG = "sys-content-video";
export const CONTENT_AUDIO_TAG = "sys-content-audio";
export const CONTENT_DOC_TAG = "sys-content-doc";
export const CONTENT_PDF_TAG = "sys-content-pdf";
export const CONTENT_TAG_TAG = "sys-content-tag";
export const CONTENT_QR_TAG = "sys-content-qr";
export const CONTENT_AVATAR_TAG = "sys-content-avatar";

/** SYSTEM APP TAGS */
export const APP_PORTAL_TAG = "sys-app-portal";
export const APP_AUGMENTEDSTORE_TAG = "sys-app-augmentedstore";
export const APP_HOLOMUSEUM_TAG = "sys-app-holomuseum";
export const APP_REMOTESELLING_TAG = "sys-app-remoteselling";
export const APP_HOLOPROTOTYPE_TAG = "sys-app-holoprototype";
export const APP_HEVOCOLLABORATION_TAG = "sys-app-hevocollaboration";

/** SYSTEM SPACE EDITOR TAGS */
export const SPACE_EDITOR_VERSION_PREFIX_TAG = "sys-spaceeditorversion-";

/** SYSTEM PRIVACY TAGS */
export const SPACEPRIVACY_OFFLINE_TAG = "sys-spaceprivacy-offline";
export const SPACEPRIVACY_PRIVATE_TAG = "sys-spaceprivacy-private";
export const SPACEPRIVACY_PUBLISHED_TAG = "sys-spaceprivacy-published";
export const SPACEPRIVACY_PUBLIC_TAG = "sys-spaceprivacy-public";

/** SYSTEM VISIBILITY TAGS */
export const HIDDEN_RESOURCE_TAG = "sys-visibility-hidden";

/** SYSTEM AZURE REMOTE RENDERING TAGS */
export const ARR_RESOURCE = "sys-arr";
export const ARRASSET_TAG = "arrAsset";

/** OTHER SYSTEM TAGS */
export const TENANT_INFO_TAG = "sys-tenant-info";
export const PLATFORM_VERSIONING_TAG = "sys-platform-versioning";
export const DISPOSABLE_RESOURCE_TAG = "sys-disposable";
export const CLONED_RESOURCE_TAG = "sys-cloned";
export const ORIGINAL_RESOURCE_GUID_PREFIX_TAG = "sys-originalguid-";
export const VERTX_TENANT_ID_PREFIX_TAG = "sys-companyid-";

/** SYSTEM FILENAMES COLLECTION */
export const SYSTEM_RESOURCE_FILENAMES = [SERIALIZED_SPACE_FILENAME, HOLOPROTOTYPE_BASE_METADATA_FILENAME, CHANGE_MATERIAL_MODEL_FILENAME,
    TAGS_CATALOG_FILENAME, MESH_GLTF_MANIFEST_FILENAME, SPACE_MANIFEST_FILENAME, VERTEX_RESOURCE_FILENAME, RESOURCE_THUMB_FILENAME, RESOURCE_THUMB_WSA_FILENAME,
    RESOURCE_THUMB_ANDROID_FILENAME, RESOURCE_THUMB_IOS_FILENAME, AS_CUSTOM_UI_SETTINGS_FILENAME, AS_PUBLIC_CATALOG_FILENAME,
    AOA_MODEL_FILENAME, MODEL_INFO_FILENAME, MEDIA_CONVERSION_STATUS_FILENAME, GENERATED_ZIP_FILENAME, H_SHARE_SETTINGS_FILENAME, AVATAR_T_SHIRT_TEXTURE_FILENAME, AVATAR_SETTINGS_FILENAME];

/** SYSTEM TAGS COLLECTION */
export const SYSTEM_TAGS = [HIDDEN_RESOURCE_TAG, TENANT_INFO_TAG, PLATFORM_VERSIONING_TAG, DISPOSABLE_RESOURCE_TAG, ARR_RESOURCE, ARRASSET_TAG,
        SPACEPRIVACY_OFFLINE_TAG, SPACEPRIVACY_PRIVATE_TAG, SPACEPRIVACY_PUBLISHED_TAG, SPACEPRIVACY_PUBLIC_TAG, CLONED_RESOURCE_TAG, ORIGINAL_RESOURCE_GUID_PREFIX_TAG,
        TYPE_BASE_TAG, TYPE_CATALOG_TAG,TYPE_DECK_TAG,TYPE_MEDIA_TAG,TYPE_MODEL_TAG,TYPE_SPACE_TAG,TYPE_ROOM_TAG,TYPE_SYSTEM_TAG,
        APP_AUGMENTEDSTORE_TAG, APP_HEVOCOLLABORATION_TAG,APP_HOLOMUSEUM_TAG,APP_HOLOPROTOTYPE_TAG,APP_PORTAL_TAG,APP_REMOTESELLING_TAG,
        CONTENT_AUDIO_TAG, CONTENT_DOC_TAG,CONTENT_PDF_TAG,CONTENT_IMAGE_TAG,CONTENT_VIDEO_TAG,CONTENT_QR_TAG,CONTENT_TAG_TAG];

export const SYSTEM_TAG_PREFIXES = [SPACE_EDITOR_VERSION_PREFIX_TAG, ORIGINAL_RESOURCE_GUID_PREFIX_TAG, VERTX_TENANT_ID_PREFIX_TAG];

export const DEFAULT_AI_ASSISTANT_INSTRUCTIONS = `Remember that it's important to respond to users with firmness and clarity.
Make sure to provide direct and concise answers, avoiding ambiguity or uncertainty.
Show confidence in your responses to ensure effective and misunderstandings-free communication.`;

export const VERTEX_NODE_NO_PARENT_GUID = "00000000-0000-0000-0000-000000000000";

export enum LanguageCode {
    Italiano = "it",
    English = "en",
    Arabic = "ar",
    //Afrikaans = "af",
    //Bengali = "bn",
    //Bulgarski = "bg",
    //Čeština = "cs",
    Chinese = "zh",
    //Dansk = "da",
    Deutsch = "de",
    //Eestikeel = "et",
    Elliniká = "el",
    Español  = "es",
    Français = "fr",
    //Gaeilge = "ga",
    Hindi = "hi",
    //Hrvatski = "hr",
    //Indonesian = "id",
    Japanese = "ja",
    Korean = "ko",
    //Latviešuvaloda = "lv",
    //Lietuviųkalba = "lt",
    //Magyar = "hu",
    //Malti = "mt",
    Nederlands = "nl",
    Norsk = "no",
    //Polski = "pl",
    Português = "pt",
    //Română = "ro",
    Russian = "ru",
    //Slovenčina = "sk",
    //Slovenščina = "sl",
    //Suomi = "fi",
    Svenska = "sv",
}

export const SYSTEM_DATA_MAX_LENGTH = 3000;
export const DEFAULT_DOCUMENT_LANGUAGE = "en";
export const DEFAULT_MAX_TOKENS = 800;
export const DEFAULT_TEMPERATURE = 0.70;
export const DEFAULT_VOICE_GENDER = "female";
export const DEFAULT_MIN_RANKING_SCORE = 1.2;
export const MIN_RANKING_SCORE_MIN = 0;
export const MIN_RANKING_SCORE_MAX = 4;
export const MIN_RANKING_SCORE_STEP = 0.1;
export const DEFAULT_MIN_WORDS_VALIDATION = 50;
export const MIN_WORDS_VALIDATION_MIN = 0;
export const MIN_WORDS_VALIDATION_MAX = 1000;
export const MIN_WORDS_VALIDATION_STEP = 10;
export const DEFAULT_NUMBER_OF_INTERACTIONS = 2;
export const NUMBER_OF_INTERACTIONS_MIN = 0;
export const NUMBER_OF_INTERACTIONS_MAX = 3;
export const NUMBER_OF_INTERACTIONS_STEP = 1;

export enum ColliderEvent {
    OnTriggerEnter = 0,
    OnTriggerExit = 1,
    OnTriggerStay = 2
}

/** OPTIMIZATION */

export interface ModelInfo{
    objectModel?: ObjectModel,
    project?: Project,
    currentOptimizationId?: string
    gltf?: GltfStructure,
    originalModelName?: string, //TODO: this field will be removed. It is kept for retrocompat only. Use name prop in originalModelProperties object
    modelProperties?: ModelProperties;
}

export interface ModelProperties {
    name?: string,
    measurementUnit?: MeasurementUnit,
    yup?: boolean,
    rootTransform?: Transform,
    pivotPosition?: PivotPosition,
}

export interface Transform {
    translation?: number[],
    rotation?: number[],
    scale?: number[]
}
export enum MeasurementUnit {
    Millimeters = "mm",
    Centimeters = "cm",
    Meters = "m",
    None = ""
}

export enum PivotPosition {
    Original = "Original",
    BottomCenter = "BottomCenter"
}

export enum OptimizationPanelStep {
    Conversion = 0,
    Optimization = 1,
    Advanced = 2,
    Confirm = 3,
}

export enum OptimizationPreset {
    VLow = 'vlow',
    Low = 'low',
    Medium = 'medium',
    High = 'high',
    VHigh = 'vhigh'
}

export interface OptimizationParams {
    polygonReduction?: number;
    preserve3DBoundaries?: OptimizationPreset;
    preserveUV?: OptimizationPreset;
    boundaryImportance?: OptimizationPreset;
    shadingImportance?: number;
    normalWeight?: number;
    normalContrast?: number;
    projectNormals?: boolean;
    discardUV?: boolean;
    hardEdges?: boolean;
    smoothEdges?: boolean;
    removeIsolatedVertices?: boolean;
    removeDuplicatedFaces?: boolean;
    removeDegeneratedFaces?: boolean;
    removeDuplicatedVertices?: boolean;
    removeSmallGeometries?: boolean;
    removeSmallGeometriesSize?: number;
    removeSmallGeometriesCount?: number;
    removeHiddenObjects?: boolean;
}

export interface HShareResourceSettings {
    companyId: string;
    companyName: string;
    resourceName: string;
    publishParent: string;
    defaultPresets?: number[];
    openChat?: boolean;
    audioEnabled?: boolean;
}

export interface HShareTenantSettings {
    systemMessage: string;
    documentLanguage: string;
}

export const DEFAULT_HSHARE_TENANT_SETTINGS: HShareTenantSettings = {
    systemMessage: DEFAULT_AI_ASSISTANT_INSTRUCTIONS,
    documentLanguage: DEFAULT_DOCUMENT_LANGUAGE
}

export const GLTF_ROOT_TRANSFORM_NODE_NAME = "RootTransform";
export const GLTF_PIVOT_NODE_NAME = "Pivot ---AutoGenerated---";

export const STEPS_NEEDING_OPTIMIZATION_LICENSE = [OptimizationPanelStep.Optimization, OptimizationPanelStep.Advanced];

export const DEFAULT_PROJECT_PARAMS: ProjectParams = {
    mergePrimitives: true,
};

export const ORIGINAL_OPTIMIZATION_NAME = "Original";

export const ORIGINAL_OPTIMIZATION_PARAMS: OptimizationParams = {
    discardUV: false,
    polygonReduction: 0
};

export const DEFAULT_OPTIMIZATION_PRESET: OptimizationParams = {
    polygonReduction: 50,
    preserve3DBoundaries: OptimizationPreset.Low,
    preserveUV: OptimizationPreset.Low,
    boundaryImportance: OptimizationPreset.Medium,
    shadingImportance: 0,
    normalWeight: 1,
    normalContrast: 0,
    projectNormals: false,
    discardUV: false,
    hardEdges: true,
    smoothEdges: true,
    removeIsolatedVertices: true,
    removeDuplicatedFaces: true,
    removeDegeneratedFaces: true,
    removeDuplicatedVertices: true,
    removeSmallGeometries: false,
    removeSmallGeometriesSize: 0,
    removeSmallGeometriesCount: 0,
    removeHiddenObjects: false,
};

export const HARD_SURFACE_PRESET: OptimizationParams = {
    polygonReduction: 50,
    preserve3DBoundaries: OptimizationPreset.High,
    preserveUV: OptimizationPreset.Low,
    boundaryImportance: OptimizationPreset.Medium,
    shadingImportance: 0,
    normalWeight: 1,
    normalContrast: 0,
    projectNormals: false,
    discardUV: false,
    hardEdges: true,
    smoothEdges: false,
    removeIsolatedVertices: true,
    removeDuplicatedFaces: true,
    removeDegeneratedFaces: true,
    removeDuplicatedVertices: true,
    removeSmallGeometries: false,
    removeSmallGeometriesSize: 0,
    removeSmallGeometriesCount: 0,
    removeHiddenObjects: false,
};

export const ORGANIC_SURFACE_PRESET: OptimizationParams = {
    polygonReduction: 50,
    preserve3DBoundaries: OptimizationPreset.Low,
    preserveUV: OptimizationPreset.Low,
    boundaryImportance: OptimizationPreset.Medium,
    shadingImportance: 1,
    normalWeight: 1,
    normalContrast: 0,
    projectNormals: false,
    discardUV: false,
    hardEdges: true,
    smoothEdges: true,
    removeIsolatedVertices: true,
    removeDuplicatedFaces: true,
    removeDegeneratedFaces: true,
    removeDuplicatedVertices: true,
    removeSmallGeometries: false,
    removeSmallGeometriesSize: 0,
    removeSmallGeometriesCount: 0,
    removeHiddenObjects: false,
};

export const SMOOTH_SURFACE_PRESET: OptimizationParams = {
    polygonReduction: 50,
    preserve3DBoundaries: OptimizationPreset.Medium,
    preserveUV: OptimizationPreset.Low,
    boundaryImportance: OptimizationPreset.Medium,
    shadingImportance: 0,
    normalWeight: 1,
    normalContrast: 0,
    projectNormals: false,
    discardUV: false,
    hardEdges: true,
    smoothEdges: true,
    removeIsolatedVertices: true,
    removeDuplicatedFaces: true,
    removeDegeneratedFaces: true,
    removeDuplicatedVertices: true,
    removeSmallGeometries: false,
    removeSmallGeometriesSize: 0,
    removeSmallGeometriesCount: 0
}; 

export const OPTIMIZATION_PRESETS = [
    DEFAULT_OPTIMIZATION_PRESET,
    SMOOTH_SURFACE_PRESET,
    ORGANIC_SURFACE_PRESET,
    HARD_SURFACE_PRESET
];

export const OPTIMIZATION_PRESETS_NAMES = ["Default", "Smooth Surface", "Organic Surface", "Hard Surface"];

export const OPTIMIZATION_OPTIONS_NAMES = ["Polygon reduction", "Preserve 3D boundaries", "Preserve UV", "Boundary importance", "Shading importance", "Normal weight", "Normal contrast", "Project normals", "Discard UV", "Hard edges", "Smooth edges", "Remove isolated vertices", "Remove duplicated faces", "Remove degenerated faces", "Remove duplicated vertices", "Remove small geometries", "Remove small geometries size", "Remove small geometries count", "Remove hidden objects"];


export const ADAPTA_BLOB_URL_SIGNATURE_PARAM = "se";

/* LICENSES */
export const VERTXCLOUD_STANDARD_LICENSE = "VERTXCLOUD_STANDARD";
export const VERTXCLOUD_DEVELOPER_LICENSE = "VERTXCLOUD_DEVELOPER";
export const VERTXCLOUD_TENANTADMIN_LICENSE = "VERTXCLOUD_TENANTADMIN";

export const AUGMENTEDSTORE_ADMIN_LICENSE = "HEVOLUS_AUGMENTEDSTORE_ADMIN";
export const AUGMENTEDSTORE_CONTENTCREATOR_LICENSE = "HEVOLUS_AUGMENTEDSTORE_CONTENTCREATOR";
export const AUGMENTEDSTORE_HOST_LICENSE = "HEVOLUS_AUGMENTEDSTORE_HOST";
export const HSPACES_HOST_LICENSE = "HEVOLUS_HSPACES_HOST";

export const REMOTESELLING_ADMIN_LICENSE = "HEVOLUS_REMOTESELLING_ADMIN";
export const REMOTESELLING_CONTENTCREATOR_LICENSE = "HEVOLUS_REMOTESELLING_CONTENTCREATOR";
export const REMOTESELLING_HOST_LICENSE = "HEVOLUS_REMOTESELLING_HOST";

export const HOLOMUSEUM_ADMIN_LICENSE = "HEVOLUS_HOLOMUSEUM_ADMIN";
export const HOLOMUSEUM_CONTENTCREATOR_LICENSE = "HEVOLUS_HOLOMUSEUM_CONTENTCREATOR";
export const HOLOMUSEUM_HOST_LICENSE = "HEVOLUS_HOLOMUSEUM_HOST";

export const HOLOPROTOTYPE_ADMIN_LICENSE = "HEVOLUS_HOLOPROTOTYPE_ADMIN";
export const HOLOPROTOTYPE_CONTENTCREATOR_LICENSE = "HEVOLUS_HOLOPROTOTYPE_CONTENTCREATOR";
export const HOLOPROTOTYPE_HOST_LICENSE = "HEVOLUS_HOLOPROTOTYPE_HOST";

export const HEVOCOLLABORATION_ADMIN_LICENSE = "HEVOLUS_HEVOCOLLABORATION_ADMIN";
export const HEVOCOLLABORATION_CONTENTCREATOR_LICENSE = "HEVOLUS_HEVOCOLLABORATION_CONTENTCREATOR";
export const HEVOCOLLABORATION_HOST_LICENSE = "HEVOLUS_HEVOCOLLABORATION_HOST";
export const HEVOCOLLABORATION_SPECTATORVIEW_LICENSE = "HEVOLUS_HEVOCOLLABORATION_SPECTATORVIEW";

export const HEVOLUS_HVERSE_3DCONVERTER_LICENSE = "HEVOLUS_HVERSE_3DCONVERTER";
export const HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE = "HEVOLUS_HVERSE_3DOPTIMIZER";

export const STEP2GLTF_CONVERTER_LICENSE = "VERTX_STEPTOGLTF";
export const REMOTERENDERING_LICENSE = "VERTXCLOUDREMOTERENDERING";
export const PROVISIONAL_REMOTERENDERING_LICENSE = "ADDON_REMOTERENDERING";
export const REALISTIC_AVATAR_LICENSE = "ADDON_REALISTICAVATARS";

export const EXPERIMENTAL = "EXPERIMENTAL";

/** GLTF MATERIAL */
export const DEFAULT_BASE_COLOR_FACTOR: [number, number, number, number] = [1, 1, 1, 1];

export const TRIPLANAR_TILE_MIN: number = -10000;
export const TRIPLANAR_TILE_MAX: number = 10000;

export const UV_SCALE_MAP_MIN: number = -10000;
export const UV_SCALE_MAP_MAX: number = 10000;

export const MAX_MATERIAL_NAME_LENGTH: number = 30;
export const MAX_MATERIAL_CATEGORY_NAME_LENGTH: number = 30;

export const DEFAULT_SLIDESHOW_SECONDS: number = 5;

/** AVATAR SETTINGS */
export interface AvatarSettingsModel {
    tShirtEnabled: boolean;
    tShirtLogo: string;
    tShirtColor: string;
}

export const DEFAULT_AVATAR_T_SHIRT_COLOR = "#808080";

export const DEFAULT_AVATAR_SETTINGS: AvatarSettingsModel = {
    tShirtEnabled: false,
    tShirtLogo: "",
    tShirtColor: DEFAULT_AVATAR_T_SHIRT_COLOR
};

/** COMPANIES AND TENANTS ITEMS IDS */
export const SAVED_CO2_ITEM_ID = "sustainability-savedco2";
export const TENANT_INFO_PUBLISHED_RESOURCE_ID_ITEM_ID = "tenantinfo-publishedid";

export const AVATAR_TSHIRT_ITEM_GROUP = "avatar-tshirt";
export const AVATAR_TSHIRT_ENABLED_ITEM_ID = "avatar-tshirt-enabled";
export const AVATAR_TSHIRT_LOGO_ITEM_ID = "avatar-tshirt-logo";
export const AVATAR_TSHIRT_COLOR_ITEM_ID = "avatar-tshirt-color";

export const HSTORE_ITEM_GROUP = "hstore";
export const HSTORE_PRIMARYCOLOR_ITEM_ID = "hstore-primarycolor"; //ui
export const HSTORE_SECONDARYCOLOR_ITEM_ID = "hstore-secondarycolor"; //ui
export const HSTORE_LOGO_ITEM_ID = "hstore-logo"; //ui
export const HSTORE_DOMAIN_ITEM_ID = "hstore-domain"; //setting

export const HSHARE_ITEM_GROUP = "hshare";
export const HSHARE_COMPANY_SYSTEMMESSAGE_ITEM_ID = "hshare-company-systemmessage";
export const HSHARE_COMPANY_DOCUMENTLANGUAGE_ITEM_ID = "hshare-company-documentlanguage";

export const HSTORE_COMPANY_PUBLIC_LINKS_ITEM_ID = "hstore-company-publiclinks";

/** EDITOR UI */
export const COMPONENTS_UI_PROPERTIES: Map<string, { name: string, icon?: string, order?: number }> = new Map(
    [
        ["ChangeMaterial", { name: "Preset", icon: "/img/resources-icon.svg" }],
        ["ItemProperties", { name: "Properties", icon: "/img/setting.svg" }],
        ["NavMesh", { name: "Navigation Mesh", icon: "/img/navigation-mesh.svg"}],
        ["LightMaps", { name: "Lightmapping", icon: "/img/light-mapping.svg" }],
        ["RotationComponent", { name: "Auto Rotation", icon: "/img/auto-rotation.svg" }],
        ["ModelAlternative", { name: "Alternative Model", icon: "/img/alternative-model.svg" }],
        ["SpawnPoint", {name: "Spawn Point"}],
        ["CallToAction", {name: "Call To Action" , icon: "/img/call-to action.svg"}],
        ["VideoTexture", {name: "Video Texture" , icon: "/img/video-texture.svg"}],
        ["Warp", {name: "Warp" , icon: "/img/warp.svg"}],
        ["NodeToggler", {name: "Node Toggler" , icon: "/img/eyeOn.png"}],
        ["VolumeTrigger", {name: "Volume Trigger" , icon: "/img/volume-trigger-filled-icon.svg"}],


        //Sidebar tooltips
        ["GltfHierarchyPanel", { name: "Model Hierarchy", order: 0, icon: "/img/scenes-icon.svg" }],
        ["GltfRootTransformPanel", { name: "Root Transform", order: 1, icon: "/img/root-transform-panel-icon.svg" }],
        ["QrExplorerPanel", { name: "QR Resources", order: 1, icon: "/img/qr-explorer-panel-icon.svg" }],


        ["SceneHierarchyPanel", { name: "Space Hierarchy", order: 0, icon: "/img/scenes-icon.svg"}],
        ["ResourceExplorerPanel", { name: "Resources", order: 1, icon: "/img/resources-icon.svg"}],
        ["CameraPropertiesPanel", { name: "Camera", order: 2, icon: "/img/camera-icon.svg"}],
        ["LightHierarchyPanel", { name: "Lights", order: 3, icon: "/img/light-icon.svg"}],
        ["SkyboxSettingsPanel", { name: "Skybox", order: 4, icon: "/img/skybox-icon.svg"}],
        ["LightMapsHandler", { name: "Lightmaps", order: 5, icon: "/img/light-maps-icon.svg"}],
        ["IntroSplashPanel", { name: "Intro Splash", order: 6, icon: "/img/intro-splash-icon.svg"}],
        ["PostProcessPanel", { name: "Post Process", order: 7, icon: "/img/setting.svg"}],
        //FRANZ 2 - QUI VIENE GESTITA TITOLO PANNELLO REGOLE
        ["ActionSettingsPanel", { name: "Actions", order: 8, icon: "/img/action-settings-icon.svg"}],
        ["PointsOfInterestPanel", { name: "Points of Interest", order: 9, icon: "/img/points-of-interest-icon.svg"}],
        ["AdditionalComponentsPanel", { name: "Additional Components", order: 10, icon: "/img/additional-components-icon.svg"}],
        ["TaskListPanel", { name: "Task List", order: 11, icon: "/img/task-list-icon.svg"}],
        ["SaveSettingsPanel", { name: "Save", order: 100, icon: "/img/save-icon.svg"}],

    ]
);

export const ADDITIONAL_COMPONENTS_UI_PROPERTIES: Map<string, string> = new Map( 
    [
        ["SavedCO2Visualizer","CO2 Visualizer"],
        ["Text2D","Text 2D"],
        ["VolumeTrigger","Volume Trigger"],
    ]);

export const AVATAR_STYLE_KEY = "HEVOLUS.Avatar.Style";
export const AVATAR_PHOTO_FILENAME:string = "photo";
export const AVATAR_MANIFEST_FILENAME:string = "manifest.json";
export const CARTOON_AVATAR_MANIFEST_FIX: string = "cartoon-manifest.json"
export const REALISTIC_BUST_AVATAR_MANIFEST_FIX: string = "realistic-bust-manifest.json"
export const DEFAULT_AVATARS_CATALOG_FILENAME = "defaultCartoonAvatars.json";
export const AVATAR_TSHIRT_REF_FILENAME: string = "tshirt-ref.json";

export const AVATAR_TEXTURE_FILENAME: string = "mesh_image0.png";

export const DEFAULT_SWAL_LOADING_MESSAGE = "Loading ...";

export const SYSTEM_ACTION_PREFIX = "sys-action";
export const SYSTEM_ACTION_SEPARATOR = "_";

export const TASKLIST_NAME_MAX_LENGTH = 30;
export const TASKLIST_TASK_NAME_MAX_LENGTH = 30;
export const TASKLIST_STEP_NAME_MAX_LENGTH = 30;

export const TASKLIST_DESCRIPTION_MAX_LENGTH = 100;
export const TASKLIST_TASK_DESCRIPTION_MAX_LENGTH = 100;
export const TASKLIST_STEP_DESCRIPTION_MAX_LENGTH = 100;

export class Node{
    id : string = "";
    name? : string;
    components : Array<string> = [];

    Transform : {
        position : Array<number>;
        rotation : Array<number>;
        scale : Array<number>;
    };

    ItemProperties? : {
        isSelectable : boolean;
        isMovable : boolean;
        isWishlistable : boolean;
        useInAR : boolean;
    };

    GltfModel? : {
        id : string;
    };

    MediaTexture? : {
        id : string;
        isSlideshow : boolean;
        index : number;
        slideshowSeconds : number;

        jsonName : string; //${componentName}_${nodeId}_runtime.json`;
    };

    ModelAlternative? : {
        modelList : Array<string>;

        //action
        actionIndexes : Array<number>;
        actionValues : Array<number>;
        triggerActionIndexes : Array<number>;
        triggerActionValues : Array<number>;
    };

    ChangeMaterial? : {
        updatedMeshIndexes : Array<number>;
        updatedMatIndexes : Array<number>;

        //action
        actionIndexes : Array<number>;
        actionValues : Array<number>;
        triggerActionIndexes : Array<number>;
        triggerActionValues : Array<number>;
    };


    Animation? : {
        //action
        actionIndexes : Array<number>;
        actionValues : Array<number>;
        triggerActionIndexes : Array<number>;
        triggerActionValues : Array<number>;
    };

    LightMaps? : {
        lightMapsData : Array<number>;
    };

    VideoTexture? : {
        index : number;

        jsonName : string; //${componentName}_${nodeId}_runtime.json`;
    };

    Rotation? : {
        speed : number;
        isClockwise : boolean;
        count : number;
    };

    Text2D? : {
        text : string;
        font : string;
        color : string;
        size : number;
        bold : boolean;
        italic : boolean;
        underlined : boolean;
        strikethrough : boolean;
        textAlignment : number;
    };

    Warp?: {
        type: string;
        data: string;
        hide: boolean;
        privateHost: string;
        publicHost: string;
        hidePrivateHost: boolean;
        hidePublicHost: boolean;
    }

    CallToAction? : {
        callToActionJson : string; //${componentName}_${nodeId}_runtime.json`;
    };
}

export class Space{
    id : string = "";
    name : string = "";
    modelCount : number = 0; // ScenePropertiesComponent
    version : number = 0; 
    
    lightmapsJson : string = ""; //${componentName}_${nodeId}_runtime.json`;
    introSplashJson : string = ""; //${componentName}_${nodeId}_runtime.json`;
    actionSettingsJson : string = ""; //${componentName}_${nodeId}_runtime.json`;
    pointsOfInterestJson : string = ""; //${componentName}_${nodeId}_runtime.json`;

    nodes : Array<Node> = [];

    spawnPoint : {
        position : Array<number>;
        rotation : Array<number>;
        scale : Array<number>;
    }

    skybox : {
        activeSkyboxIndex : number;
        jsonName : string; //${componentName}_${nodeId}_runtime.json`;

        //action
        actionIndexes : Array<number>;
        actionValues : Array<number>;
        triggerActionIndexes : Array<number>;
        triggerActionValues : Array<number>;
    };

    cameraProperties : {
        minZ: number;
        maxZ: number;
        lowerRadiusLimit: number;
        upperRadiusLimit: number;
        panningSensibility: number;
        inertia: number;
        panningInertia: number;
        wheelPrecision: number;
        pinchPrecision: number;
        fov: number;
        betaLimitMode: number;
    }

    postProcessProperties : {
        fxaaEnabled : boolean;
        antiAliasingSamples : number;

        bloomEnabled : boolean;
        bloomWeight: number;
        bloomKernel : number;
        bloomScale : number;
        bloomThreshold : number;

        toneMappingEnabled : boolean;

        screenSpaceReflectionStrength : number;
        screenSpaceReflectionSample : number;

        screenSpaceAmbientOcclusionEnabled : boolean;
        screenSpaceAmbientOcclusionTotalStrength : number;

        vignetteEnabled : boolean;
        vignetteWeight : number;
        vignetteRounded : boolean;
        vignetteColorR : number;
        vignetteColorG : number;
        vignetteColorB : number;
        vignetteColorA : number;

        contrast : number;
        exposure : number;
    };       
}